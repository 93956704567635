import React, { useState, useEffect, Fragment, forwardRef, useContext } from 'react';
import { Container, Row, Col, Card, DropdownButton, Table, Form, Button } from 'react-bootstrap';
import { FiMoreHorizontal, FiList, FiBarChart2, FiSearch } from 'react-icons/fi';
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { authHeader } from '../../../_helpers/auth-header';
import pt from 'date-fns/locale/pt-BR';
// import MaskedInput from 'react-text-mask';
import { api } from '../../../_services';
import { convertCurrencyBr } from '../../../utils/global';
import NoResult from '../../../pages/Errors/noResult';
import PageError from '../../Errors/pageError';
import UserContext from '../../../hooks/UserContext';
import * as moment from 'moment';
import 'moment/locale/pt-br';
import './styles.css';

const Vendas = () => {

    const { user, perfil } = useContext(UserContext);
    const [loading, setLoading]   = useState(true); 
    const [error, setError]       = useState(false);
    const [periodo, setPeriodo]   = useState(1);
    const [vendedor, setVendedor] = useState('');
    const [ano, setAno] = useState(new Date().getFullYear());
    const [dia, setDia] = useState(new Date().getTime());
    const [vendedores, setVendedores] = useState([]);
    const [dailySales, setDailySales] = useState([]);
    const [annualSales, setAnnualSales] = useState([]);
    const [annualSalesResult, setAnnualSalesResult] = useState([]);    
    const [annualSalesQtd, setAnnualSalesQtd] = useState(0);
    const [annualSalesTotal, setAnnualSalesTotal] = useState(0);
    
    const [arrOperadoras, setArrOperadoras] = useState([]);
    const [arrOperadorasQtd, setArrOperadorasQtd] = useState([]);
    const [arrOperadorasBackground, setArrOperadorasBackground] = useState([]);
    const [renderVendas, setRenderVendas] = useState(false);
    const [renderVendasAnual, setRenderVendasAnual] = useState(false);

    // Breadcrumb 
    const lista = ['Home', 'Relatórios', 'Vendas' ];
    
    const ExampleCustomInput = forwardRef(
        ({ value, onClick }, ref) => (
            <Button type="button" className="custom-btn padrao btn btn-secondary btn-sm btn-block" onClick={onClick} ref={ref} >
                {value}
            </Button>
        )
    );

    const periodos = [
        { value: 1, label: 'Diário'},
        { value: 3, label: 'Anual'}
    ];
    
    const years = () => {

        const initialYear = 2019;
        const ObjectYears = [];
        for (let index = initialYear; index < moment().year() + 4; index++) {
            
            ObjectYears.push({ value: index, label: index.toString()});
        }
        
        return ObjectYears;

    } 

    const loadingVendasPorOperadora = async() => {
        
        setDailySales([]);
        setAnnualSales([]);
        setLoading(true);

        await api.get('getVendas/', {
            params: {
                periodo: periodo,
                dia: moment(new Date(dia)).subtract(Math.abs(moment().utcOffset()), 'minutes').toDate().toISOString().slice(0, 10),
                ano: ano,
                vendedor: vendedor
            },
            headers: authHeader()
        }).then((response) => {
            
            const arrayOperadoras = [];
            const arrayOperadorasQtd = [];
            const arrayOperadorasBackground = [];
            
            if(periodo === 1) {
                
                const result = response.data.reduce((memo, value) => {
                    (memo[value.descricao] = memo[value.descricao] || []).push(value);
                    return memo;
                }, []);
                
                Object.entries(result).map((e, index) => arrayOperadoras.push(e[0]));
                Object.entries(result).map((e, index) => arrayOperadorasQtd.push(e[1].length));
                Object.entries(result).map((e, index) => arrayOperadorasBackground.push(`rgba(89, 109, 152, 1)`));
                // response.data.map(e => arrayOperadoras.push(e.descricao));
                // response.data.map(f => arrayOperadorasQtd.push(f.quantidade));
                // response.data.map(g => arrayOperadorasBackground.push(`rgba(${Math.random() * 256 >> 0}, ${Math.random() * 256 >> 0}, ${Math.random() * 256 >> 0}, .8)`));
                
                setDailySales(Object.entries(result));
                setArrOperadoras(arrayOperadoras);
                setArrOperadorasQtd(arrayOperadorasQtd);
                setArrOperadorasBackground(arrayOperadorasBackground);
                setRenderVendas(true);
                
            } else {
                
                const result = response.data.reduce((memo, value) => {
                    (memo[value.mes] = memo[value.mes] || []).push(value);
                    return memo;
                }, []);
                
                if(result.length > 0) {
                    setAnnualSalesQtd(result.map(e => e.map(f => f.quantidade)).map(e => e.reduce((a, b) => parseFloat(a) + parseFloat(b))).map(e => !e ? 0 : parseInt(e)).reduce((a, b) => parseFloat(a) + parseFloat(b))); 
                    setAnnualSalesTotal(result.map(e => e.map(f => f.valor)).map(e => e.reduce((a, b) => parseFloat(a) + parseFloat(b))).map(e => !e ? 0 : parseInt(e)).reduce((a, b) => parseFloat(a) + parseFloat(b))); 
                    setAnnualSales(Object.assign([...Array(12)], result.map(e => e.map(f => f.quantidade)).map(e => e.reduce((a, b) => parseFloat(a) + parseFloat(b)))));
                    setAnnualSalesResult(result);
                }
                setRenderVendasAnual(true);
                
            }
        }).catch(errors => {
            setError(true);
        }).finally(e => {
            setLoading(false);
        });

    }

    const showDetaitl = (elems) => {
        //console.log(elems);
        
    }

    const loadVendedores = async (perfil, user) => {
        
        await api.get(`getAllVendedores/`, { params: { page: 1, t: '' }, headers: authHeader() }).then(response => {
            setVendedores(response.data.vendedores.map(e => ({ value: e.uuid, label: e.nome})));
            if(response.data.vendedores.length === 1) {
                setVendedor(response.data.vendedores.map(e => e.uuid));
            }            
        }).catch(errors => {
            setError(true);
        }).finally(e => {
            setLoading(false);
        });

    }
    
    const VendasPorOperadora = () => {
        
        if(renderVendas){

            const dataDaily = {
                labels: arrOperadoras,
                datasets: [
                    {
                        label: 'Quantidade',
                        data: arrOperadorasQtd,
                        backgroundColor: arrOperadorasBackground,
                        borderWidth: 0,
                    },
                ],
            }

            const optionsDaily = {
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                    enable: false
                },
                "hover": {
                    "animationDuration": 0
                },
                "animation": {
                    "duration": 1,
                    "onComplete": function() {
                        var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                
                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                
                        this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });
                        });
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            max: Math.max(...arrOperadorasQtd) + 10,
                            display: false,
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true   
                        }
                    }]
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        fontColor: '#000'
                    }
                },
            }
            
            const total = (dailySales && dailySales.length > 0) ? dailySales.map(e => e[1].map(f => f.quantidade)).map(e => e.reduce((a, b) => parseFloat(a) + parseFloat(b))).reduce((a, b) => parseFloat(a) + parseFloat(b)) : 0;

            const valor = (dailySales && dailySales.length > 0) ? dailySales.map(e => e[1].map(f => f.valor)).map(e => e.reduce((a, b) => parseFloat(a) + parseFloat(b))).reduce((a, b) => parseFloat(a) + parseFloat(b)) : 0;
        
            return (
                <Fragment>
                    {
                    dailySales.length > 0 ?
                        <Row className="justify-content-md-center">
                            <Col lg="8">
                                <h5 className="title-chart"><FiBarChart2 />Relatório Sintético - Por Operadora</h5>
                                <Bar width={100} height={30} data={dataDaily} options={optionsDaily} onElementsClick={showDetaitl} />
                            </Col>
                            <Col lg="4">
                                <h5 className="title-chart"><FiBarChart2 />Total</h5>
                                <Row className="justify-content-md-center detalhe-geral">
                                    <Col lg="12">
                                        <small className="detalhe-geral-title">Quantidade de planos vendidos</small><br></br>
                                        <h4>{total}</h4>
                                    </Col>
                                    <Col lg="12">
                                        <small className="detalhe-geral-title">Valor total dos planos vendidos (R$)</small><br></br>
                                        <h4>{convertCurrencyBr(valor)}</h4>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="12">
                                <hr></hr><br></br>
                                <h5 className="title-chart"><FiList />Relatório Analítico - Por Operadora</h5>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Vendedor</th>
                                            <th>Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                        dailySales.map((e, index) => 
                                            <Fragment key={index}>
                                                <tr>
                                                    <td colSpan={3}><b>{e[0]}</b></td>
                                                </tr>
                                                {
                                                    e[1].map((f, index) => 
                                                        <tr key={index}>
                                                            <td style={{backgroundColor: '#FFF'}}>{f.nome}</td>
                                                            <td style={{width: '150px', fontWeight: 'bold', backgroundColor: '#FFF'}}>{convertCurrencyBr(f.valor)}</td>
                                                        </tr> 
                                                    )
                                                }
                                            </Fragment>   
                                        )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row> 
                    :
                    <NoResult />
                    }
                </Fragment>
            )
            
        }
        return <></>;
    }

    const VendasPorOperadoraAnual = () => {
        
        if(renderVendasAnual){

            const dataYear = {
                labels: Object.keys(Object.assign([...Array(12)], annualSales)).map(e => moment.monthsShort('-MMM-', e)),
                datasets: [
                    {
                        label: 'Quantidade',
                        data: Object.values(Object.assign([...Array(12)], annualSales)).map(e => !e ? 0 : parseInt(e)),
                        backgroundColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        ],
                        borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        
            const optionsYear = {
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                    enable: false
                },
                "hover": {
                    "animationDuration": 0
                },
                "animation": {
                    "duration": 1,
                    "onComplete": function() {
                        var chartInstance = this.chart,
                        ctx = chartInstance.ctx;
                
                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';
                
                        this.data.datasets.forEach(function(dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function(bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });
                        });
                    }
                },
                scales: {
                    yAxes: [{
                        display: false,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            max: Math.max(...dataYear.datasets[0].data) + 10,
                            display: false,
                            beginAtZero: true
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true   
                        }
                    }]
                },
                legend: {
                    display: false,
                    position: 'top',
                    labels: {
                        fontColor: '#000'
                    }
                },
            }
        
            const total = annualSalesQtd;

            const valor = annualSalesTotal;
            
            return (
                <Fragment>
                    {/* {moment.monthsShort('-MMM-', e)} */}
                    {
                    annualSales.length > 0 ?
                        <Fragment>
                            <Row className="justify-content-md-center">
                                <Col lg="8">
                                    <h5 className="title-chart"><FiBarChart2 />Relatório Sintético</h5>
                                    <Bar width={100} height={30} data={dataYear} options={optionsYear} onElementsClick={showDetaitl} />
                                </Col>
                                <Col lg="4">
                                    <h5 className="title-chart"><FiBarChart2 />Total</h5>
                                    <Row className="justify-content-md-center detalhe-geral">
                                        <Col lg="12">
                                            <small className="detalhe-geral-title">Quantidade de planos vendidos</small><br></br>
                                            <h4>{total}</h4>
                                        </Col>
                                        <Col lg="12">
                                            <small className="detalhe-geral-title">Valor total dos planos vendidos (R$)</small><br></br>
                                            <h4>{convertCurrencyBr(valor)}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12">
                                    <hr></hr><br></br>
                                    <h5 className="title-chart"><FiList />Relatório Analítico</h5>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Operadora</th>
                                                <th>Vendedor</th>
                                                <th>Quantidade</th>
                                                <th>Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {annualSalesResult.map((e, index) => {
                                                return (
                                                <Fragment key={index}>
                                                <tr>
                                                    <td colSpan={4}>{moment.months('-MMM-', index)}</td>
                                                </tr>
                                                
                                                { e.map((f, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td>{f.descricao}</td>
                                                            <td>{f.nome}</td>
                                                            <td>{f.quantidade}</td>
                                                            <td>{convertCurrencyBr(f.valor)}</td>
                                                        </tr>
                                                    )
                                                }) }
                                                </Fragment>
                                                )
                                            })}
                                            
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row> 
                        </Fragment>
                    :
                    <NoResult />
                    }
                </Fragment>
            )
            
        }
        return <></>;
    }

    const limparFiltros = () => {
        setDia(new Date().getTime());
        setVendedor('');
        setAno(new Date().getFullYear());
        setRenderVendas(false);
        setRenderVendasAnual(false);
    }

    useEffect(() => {
        setLoading(false);
        loadVendedores(perfil, user);
    }, [perfil, user]);

    return (
        (error) ? <PageError /> :
        <main>
            <section id="section-vendas-chart">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    disabled={true}
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>      
                            <Row>
                                <Col xs={12} md={3}>
                                    <Form.Group controlId="select-periodo">
                                        <Form.Label>
                                            Periodo
                                        </Form.Label>                        
                                        <Select 
                                            className={`select-default ${periodo === 0 ? 'is-invalid' : ''}`}
                                            options={periodos} 
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={ e => { limparFiltros(); setPeriodo(e.value) }}
                                            value={periodo > 0 ? periodos.find(obj => parseInt(obj.value) === parseInt(periodo)) : ''} 
                                            name="periodo" 
                                            placeholder=""
                                            noOptionsMessage={() => 'Sem resultados!'}
                                            styles={{
                                                singleValue: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                input: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                placeholder: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                menu: (provided, state) => {
                                                    const borderRadius = 0;
                                                    const fontSize = 12;
                                                    return { ...provided, borderRadius, fontSize };
                                                },
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">Selecione um período!</Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                
                                {
                                periodo === 1 ?
                                    <Col xs={12} md={3}>
                                        <Form.Label>
                                            Dia
                                        </Form.Label>    
                                        <Form.Group controlId="select-dia">
                                            <DatePicker
                                                customInput={ <ExampleCustomInput /> }
                                                selected={dia}
                                                name="dia"
                                                onChange={date => setDia(date) }
                                                locale={pt}
                                                dateFormat="P"
                                                withPortal
                                                todayButton="Hoje"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                />
                                        </Form.Group>
                                    </Col>
                                : periodo === 3 ? 
                                    <Col xs={12} md={3}>
                                        <Form.Group controlId="select-ano">
                                            <Form.Label>
                                                Ano
                                            </Form.Label>                        
                                            <Select 
                                                className={`select-default`}
                                                options={years()} 
                                                isClearable={false}
                                                isSearchable={false}
                                                onChange={ e => { limparFiltros(); setAno(e.value) }}
                                                value={ano > 0 ? years().find(obj => parseInt(obj.value) === parseInt(ano)) : ''} 
                                                name="ano" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                : null 
                                }
                                <Col xs={12} md={3}>
                                    <Form.Group controlId="select-vendedor">
                                        <Form.Label>
                                            Vendedor
                                        </Form.Label>                        
                                        <Select 
                                            className={`select-default`}
                                            options={vendedores} 
                                            isClearable={vendedores.length === 1 ? false : true}
                                            isSearchable={vendedores.length === 1 ? false : true}
                                            onChange={ e => e === null ? setVendedor('') : setVendedor(e.value) }
                                            value={vendedor ? vendedores.find(obj => obj.value.toString() === vendedor.toString()) : ''} 
                                            name="vendedor" 
                                            placeholder="Todos os vendedores"
                                            noOptionsMessage={() => 'Sem resultados!'}
                                            styles={{
                                                singleValue: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                input: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                placeholder: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                menu: (provided, state) => {
                                                    const borderRadius = 0;
                                                    const fontSize = 12;
                                                    return { ...provided, borderRadius, fontSize };
                                                },
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <br></br>    
                                    <button className="btn btn-send" type="button" onClick={() => loadingVendasPorOperadora()}><FiSearch /> Gerar</button>
                                </Col>
                            </Row>
                            {
                            periodo === 1 ? 
                                <VendasPorOperadora />
                            : periodo === 3 ?
                                <VendasPorOperadoraAnual />
                            : 'None'
                            }
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>    
    );
}

export default Vendas;