import React from 'react';
import videobg from './../assets/main.mp4';
import { HiCursorClick } from "react-icons/hi";

const Main = () => {

    return (

        <main id="main-container">
            
            <div className="overlay"></div>
            
            <video autoPlay={true} muted="muted" loop="loop">
                
                <source src={`${videobg}`} type="video/mp4" />

            </video>

            <div className="container">

                <div className="title">
                    CS Seguros
                </div>

                <div className="info">
                    Corretor, que tal informatizar os seus dados?<br></br><br></br>

                    Mantenha todas as propostas de clientes e tabelas em um só lugar. Faça simulações online para os seus clientes. Tenha controle de suas vendas. Organize suas metas. Administre as suas comissões e fale diretamente com seu cliente através da integração CS Seguros/WhatsApp.<br></br><br></br>

                    Você é corretor de seguro saúde? Então vale a pena conhecer o CS Seguros!<br></br><br></br>

                    Teste gratuitamente por 10 dias!
                </div>

                <div className="button">
                    <a href="https://evolutiosistemas.com/#contact">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <HiCursorClick />Clique aqui!
                    </a>
                </div>

            </div>

        </main>

    );

}

export default Main;