import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Modal, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { FiSave, FiEdit, FiTrash, FiSearch, FiDelete, FiMoreHorizontal } from 'react-icons/fi';
import { FaCaretRight, FaCaretLeft, FaStepBackward, FaStepForward, FaTimes } from 'react-icons/fa';
import Pagination from "react-js-pagination";
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import './styles.css';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { getCurrentDateEn} from '../../../utils/currentDate';
import axios from 'axios';
import NoResult from '../../Errors/noResult';
import PageError from '../../Errors/pageError';

const Metas = () => {
    
    const isLoaded                                  = useRef(false);
    const [txtChanged        , setTxtChanged]       = useState('');
    const [selectChanged     , setSelectChanged]    = useState('');
    const [oldTxtChanged     , setOldTxtChanged]    = useState('');
    const [hasResult         , setHasResult]        = useState(false);
    const [loadingSearch     , setLoadingSearch]    = useState(false);
    const [metas             , setMetas]            = useState([]);
    const [vendedores        , setVendedores]       = useState([]);
    const [operadoras        , setOperadoras]       = useState([]);
    const [administradoras   , setAdministradoras]  = useState([]);
    const [loading           , setLoading]          = useState(true); 
    const [error             , setError]            = useState(false);
    const [labelBotao        , setLabelBotao]       = useState('Salvar');
    const [total             , setTotal]            = useState(0);
    const [page              , setPage]             = useState(1);
    const [enableValidation  , setEnableValidation] = useState(false);
    const [id                , setId]               = useState(0); 
    const [fk_vendedor       , setFkVendedor]       = useState(0);
    const [fk_operadora      , setFkOperadora]      = useState(0);
    const [fk_administradora , setFkAdministradora] = useState(0);
    const [meta              , setMeta]             = useState(0);
    const [data_inicio       , setData_inicio]      = useState(getCurrentDateEn());
    const [data_fim          , setData_fim]         = useState(getCurrentDateEn());
    const mountedRef                                = useRef(true);

    const [modalShow, setModalShow] = useState(false);

    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Metas' ];

    function escapeRegexCharacters(str)
    {
        return str.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&');
    }

    function onTextChanged(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        if(oldTxtChanged !== valor) {
            setTxtChanged(valor);
            setOldTxtChanged(valor);
            setLoadingSearch(true);
            loadMetas(1, valor, selectChanged);
        }                    
    }

    function onSelectChanged(e)
    {
        if(e !== null) {
            const valorSelect = e.value;
            setSelectChanged(valorSelect);
            setLoadingSearch(true);
            loadMetas(1, txtChanged, valorSelect);
        }else {
            setLoadingSearch(true);
            setSelectChanged('');
            loadMetas(1, txtChanged, '');            
        }
    }
    const loadInfo = async () => {
        
        let vendedores = "getAllVendedores/";
        let administradoras = "getAllAdministradoras/";
        let operadoras = "getAllOperadoras/";

        const requestVendedores = await api.get(vendedores,{ params: { page: 1, t: '' }, headers: authHeader() });
        const requestAdministradoras = await api.get(administradoras,{ params: { page: 1, t: '' }, headers: authHeader() });
        const requestOperadoras = await api.get(operadoras,{ params: { page: 1, t: '', s: '' }, headers: authHeader() });
        
        axios.all([requestVendedores, requestAdministradoras, requestOperadoras]).then(axios.spread((...responses) => {
            
            if(mountedRef.current) {
                const requestVendedores = responses[0];
                const requestAdministradoras = responses[1];
                const requestOperadoras = responses[2];
                setVendedores(requestVendedores.data.vendedores.map(e => ({ value: e.id, label: e.nome})));
                setAdministradoras(requestAdministradoras.data.administradoras.map(e => ({ value: e.id, label: e.descricao})));
                setOperadoras(requestOperadoras.data.operadoras.map(e => ({ value: e.id, label: e.descricao})));
                isLoaded.current = true;
            }

        })).catch(errors => {

            setError(true);

        });

    }

    const loadMetas = async (pagina = 1, txtChanged = '', selectChanged = '') => {
        
        await api.get('metas/', {
            params: {
                page : pagina,
                t    : txtChanged,
                o    : selectChanged
            },
            headers: authHeader()
        }).then((response) => {

            if(mountedRef.current) {
                setHasResult(true);
                setMetas(response.data.metas);
                setTotal(parseInt(response.data.XCountRegister));
                setPage(pagina);
                if((!!txtChanged === false && !!selectChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                    setHasResult(false);
                }
            }

        }).catch(errors => {
            setError(true);
        }).finally(e => {
            
            if(mountedRef.current) {

                setLoading(false);
                setLoadingSearch(false);
            }

        });
          
    }

    const cadastrarInfo = async (props, info) => {
        
        setModalShow(false);

        setLoading(true);
        
        let data = JSON.stringify({
            id: props.id,
            meta: props.meta,
            fk_operadora: props.fk_operadora,
            fk_vendedor: props.fk_vendedor,
            fk_administradora: props.fk_administradora,
            data_inicio: props.data_inicio,
            data_fim: props.data_fim
        });
        
        const requisicao = (props.id > 0) ? api.put : api.post;

        const url = (props.id > 0) ? `/metas/${props.id}` : `/metas`;

        await requisicao(url, data, {headers: authHeader()})
        .then(response => {

            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    setHasResult(true);
                    const resultado = response.data.metas[0];
                    if(props.id > 0) {
                        const updateMetas = metas.filter((item) => item.id !== props.id);
                        setMetas([...updateMetas, { id: resultado.id,
                                                    administradora: resultado.administradora,
                                                    meta: resultado.meta,
                                                    operadora: resultado.operadora,
                                                    vendedor:resultado.vendedor,
                                                    data_fim: resultado.data_fim,
                                                    data_inicio: resultado.data_inicio,
                                                    fk_administradora: resultado.fk_administradora,
                                                    fk_operadora: resultado.fk_operadora,
                                                    fk_vendedor: resultado.fk_vendedor} ]);
                        Swal.fire(
                            'Sucesso!',
                            'Meta alterada com sucesso!',
                            'success'
                        );
                        limparInfo();
                    } else {
                        setMetas([...metas, {id: resultado.id,
                                            administradora: resultado.administradora,
                                            meta: resultado.meta,
                                            operadora: resultado.operadora,
                                            vendedor:resultado.vendedor,
                                            data_fim: resultado.data_fim,
                                            data_inicio: resultado.data_inicio,
                                            fk_administradora: resultado.fk_administradora,
                                            fk_operadora: resultado.fk_operadora,
                                            fk_vendedor: resultado.fk_vendedor                                            
                                }]);
                        setPage(1);
                        setModalShow(false);
                        Swal.fire(
                            'Sucesso!',
                            'Meta cadastrada com sucesso!',
                            'success'
                        );
                        //info.resetForm();
                        setTotal(parseInt(response.data.XCountRegister));
                    }                    
                    setTxtChanged('');
                }

            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {

                setLabelBotao('Salvar');
                setLoading(false);

            }

        });
            
    }

    const limparInfo = () => {
        setEnableValidation(false);
        setLabelBotao('Salvar');
        setId(0);
        setFkVendedor(0);
        setFkOperadora(0);
        setFkAdministradora(0);
        setMeta(0);
        setData_inicio(getCurrentDateEn());
        setData_fim(getCurrentDateEn());
    }

    const carregarMetas = (props) => {
        limparInfo();
        setLabelBotao('Alterar');
        setModalShow(true);
        setId(props.id);
        setFkVendedor(props.fk_vendedor);
        setFkOperadora(props.fk_operadora);
        setFkAdministradora(props.fk_administradora);
        setMeta(props.meta);
        setData_inicio(props.data_inicio);
        setData_fim(props.data_fim);
    }

    const excluirMetas = (props) => {
        
        limparInfo();
        Swal.fire({
            title: `Deseja excluir a meta do vendedor: ${props.vendedor}?`,
            text: `Meta do período de ${props.data_inicio} até ${props.data_fim}!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {

                removerMeta(props);
               
            }
        });

    }

    const removerMeta = async (props) => {
        
        setLoading(true);
        
        await api.delete(`/metas/${props.id}`, {headers: authHeader()})
        .then(response => {
            
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }
                    Swal.fire(
                        'Atenção!',
                        `${resultadoTexto}`,
                        'warning'
                    );
                    return false;                 
                }
                else
                {
                    Swal.fire(
                        'Sucesso!',
                        'Meta removida com sucesso!',
                        'success'
                    );
                    setHasResult(true);
                    setMetas(response.data.metas);
                    setTotal(parseInt(response.data.XCountRegister));
                    setTxtChanged('');
                    setPage(1);
                    clearFilters();
                    if((!!txtChanged === false && !!selectChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                        setHasResult(false);
                    }
                }

            }
        
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }

        });
        
    }

    const clearFilters = () => {
        setTxtChanged('');
        setSelectChanged('');
    }

    const exibirModal = () => {
        limparInfo();
        setEnableValidation(false);
        setModalShow(true);
    }

    useEffect(() => {
        
        loadMetas();
        if(!isLoaded.current){
            loadInfo();
        }  
        return () => { mountedRef.current = false }

    }, []);

    return (
        (error) ? <PageError /> :
        <main>
            <section id="metas">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Item eventKey="1" onClick={exibirModal}>Adicionar</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body> 
                        <Modal
                            animation={false}
                            show={modalShow}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={()=> false}
                            >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                {labelBotao === 'Alterar' ? 'Alterar' : 'Adicionar'} Meta
                                </Modal.Title>
                            </Modal.Header>
                            <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize={true} 
                            initialValues={{
                                id: id,
                                fk_vendedor: fk_vendedor,
                                fk_operadora: fk_operadora,
                                fk_administradora: fk_administradora,
                                meta: meta,
                                data_inicio: data_inicio,
                                data_fim: data_fim,
                            }}
                            validationSchema={ () => { 
                                setEnableValidation(true);
                                return Yup.object({
                                    fk_vendedor: Yup.number()
                                                .moreThan(0, 'Escolha um vendedor')
                                                .required('Campo deve ser preenchido!'),  
                                    fk_operadora: Yup.number()
                                                .moreThan(0, 'Escolha uma operadora')
                                                .required('Campo deve ser preenchido!'), 
                                    fk_administradora: Yup.number()
                                                .moreThan(0, 'Escolha uma administradora')
                                                .required('Campo deve ser preenchido!'), 
                                    meta: Yup.number()
                                                .moreThan(0, 'Valor deve ser maior do que zero')
                                                .required('Campo deve ser preenchido!'), 
                                    data_inicio: Yup.date()
                                                .typeError('Data Inválida!')
                                                .required('Campo deve ser preenchido!')            
                                                .min(new Date('2000-01-01'), 'A data mínima permitida é 01/01/2000')
                                                .max(data_fim, "A Data Início não pode ser maior do que a Data Fim"),
                                    data_fim: Yup.date()
                                                .typeError('Data Inválida!')
                                                .required('Campo deve ser preenchido!')            
                                                .min(Yup.ref('data_inicio'), "Data Fim não pode ser menor do que a Data Início")
                                                .max(new Date('2200-01-01'), 'A data máxima permitida é 01/01/2200'),                                                
                                    
                                })}
                            }
                            onSubmit={cadastrarInfo}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Carregando Loading={loading} />
                                <Form.Control 
                                    autoComplete="off" 
                                    readOnly={true}
                                    name="id" 
                                    type="hidden" 
                                    onChange={handleChange} 
                                    value={values.id}
                                    placeholder=""
                                />

                                <Row>
                                    <Col xs={12} md={4}>
                                        <Form.Group controlId="select-vendedor">
                                            <Form.Label>
                                                Vendedor* 
                                            </Form.Label>                        
                                            <Select 
                                                className={`select-default ${!!errors.fk_vendedor ? 'is-invalid' : ''}`}
                                                options={vendedores} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("fk_vendedor", e.value) }
                                                value={values.fk_vendedor > 0 ? vendedores.find(obj => obj.value === values.fk_vendedor) : ''} 
                                                name="fk_vendedor" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fk_vendedor}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group controlId="select-operadora">
                                            <Form.Label>Operadora*</Form.Label>                        
                                            <Select 
                                                className={`select-default ${!!errors.fk_operadora ? 'is-invalid' : ''}`}
                                                options={operadoras} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("fk_operadora", e.value) }
                                                value={values.fk_operadora > 0 ? operadoras.find(obj => obj.value === values.fk_operadora) : ''} 
                                                name="idOperadora" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fk_operadora}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4}>
                                        <Form.Group controlId="select-administradora">
                                            <Form.Label>Administradora*</Form.Label>                        
                                            <Select 
                                                className={`select-default ${!!errors.fk_administradora ? 'is-invalid' : ''}`}
                                                options={administradoras} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("fk_administradora", e.value) }
                                                value={values.fk_administradora > 0 ? administradoras.find(obj => obj.value === values.fk_administradora) : ''} 
                                                name="fk_administradora" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fk_administradora}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center">
                                    <Col lg="4">
                                    <Form.Label>Meta*</Form.Label>
                                        <Form.Group controlId="field-meta">
                                            <Form.Control 
                                                autoComplete="off" 
                                                name="meta" 
                                                type="text" 
                                                onChange={handleChange} 
                                                value={values.meta}
                                                isInvalid={!!errors.meta}
                                                placeholder=""
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.meta}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4">
                                        <Form.Group controlId="field-data_inicio">
                                            <Form.Label>Data Início*</Form.Label>
                                            <Form.Control 
                                                autoComplete="off" 
                                                name="data_inicio" 
                                                type="date" 
                                                onChange={handleChange} 
                                                value={values.data_inicio}
                                                isInvalid={!!errors.data_inicio}
                                                placeholder="Data"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.data_inicio}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col lg="4">
                                        <Form.Group controlId="field-data_fim">
                                            <Form.Label>Data Fim*</Form.Label>
                                            <Form.Control 
                                                autoComplete="off" 
                                                name="data_fim" 
                                                type="date" 
                                                onChange={handleChange} 
                                                value={values.data_fim}
                                                isInvalid={!!errors.data_fim}
                                                placeholder=""
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.data_fim}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" 
                                        size="sm" 
                                        type="submit">
                                   <FiSave /> {labelBotao}
                                </Button>
                                <Button variant="primary" 
                                        size="sm" 
                                        onClick={() => {
                                            resetForm();
                                            limparInfo();
                                        }} 
                                        type="reset">
                                    <FiDelete /> Limpar
                                </Button>
                                <Button variant="secondary" 
                                        className="padrao-cancelar"
                                        size="sm" 
                                        onClick={() => setModalShow(false)}>
                                    <FaTimes /> Fechar
                                </Button>
                            </Modal.Footer>
                            </Form>
                            )}
                        </Formik>
                        </Modal>
                        {
                            (hasResult) ?
                        
                            <Fragment>
                                <Table responsive hover size="md">
                                <thead>
                                    <tr>
                                        <th style={{minWidth: '185px'}}>
                                            <Select 
                                                className="basic-single"
                                                options={operadoras} 
                                                isClearable={true}
                                                isSearchable={true}
                                                placeholder="Operadora"
                                                value={parseInt(selectChanged) > 0 ? operadoras.find(obj => obj.value === parseInt(selectChanged)) : ''} 
                                                menuPortalTarget={document.body}
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                onChange={onSelectChanged}
                                                styles={{
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                        </th>
                                        <th style={{minWidth: '270px'}} className="field-search">
                                        <Form.Group controlId="field-search">
                                        {loadingSearch ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        /> : <FiSearch />}
                                        <Form.Control 
                                            autoComplete="off" 
                                            type="text"
                                            placeholder="Vendedor"
                                            aria-label="Vendedor"
                                            maxLength={20}
                                            value={txtChanged}
                                            onChange={(e) => {
                                                setTxtChanged(e.target.value.trim());
                                                if(e.target.value === '') {
                                                    onTextChanged(e);
                                                }
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === 'Enter') {
                                                    onTextChanged(e);
                                                }
                                            }}
                                        />
                                        </Form.Group>
                                        </th>
                                        <th style={{minWidth: '185px'}}>Período</th>
                                        <th style={{minWidth: '185px'}}>Administradora</th>
                                        <th style={{minWidth: '100px'}}>Meta</th>
                                        <th style={{minWidth: '15px'}}></th>
                                        <th style={{minWidth: '15px'}}>
                                        
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(metas.length === 0) ? <><tr><td colSpan={7} style={{textAlign: 'center'}}>Nenhum resultado encontrado!</td></tr></> : <>
                                    {
                                        metas.sort((a,b) => b.id - a.id)
                                            .slice(0, 10)
                                            .map((props) => {
                                            return (
                                                <tr key={props.id}>
                                                    <td>{props.operadora}</td>
                                                    <td>{props.vendedor}</td>
                                                    <td>{props.data_inicio} / {props.data_fim}</td>
                                                    <td>{props.administradora}</td>
                                                    <td>{props.meta}</td>
                                                    <td style={{width: '15px'}} >
                                                        <button className="botao" onClick={() => carregarMetas(props)} >
                                                            <FiEdit style={{color:'#638c5e', cursor: 'pointer'}} />
                                                        </button>
                                                    </td>
                                                    <td style={{width: '15px'}}>
                                                        <button className="botao" onClick={() => excluirMetas(props)}>
                                                            <FiTrash style={{color:'#c94646', cursor: 'pointer'}} />                                            
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })                                
                                    }
                                    </>}
                                </tbody>
                            </Table>
                                {(metas.length === 0) ? <></> :
                                    <>
                                    <Pagination
                                    nextPageText={<FaCaretRight size={19} />}
                                    prevPageText={<FaCaretLeft size={19}  />}
                                    lastPageText={<FaStepForward />}
                                    firstPageText={<FaStepBackward />}
                                    activePage={page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={5}
                                    onChange={(ev) => loadMetas(ev, txtChanged, selectChanged)}
                                    />
                                    
                                    </>
                                }         
                            </Fragment>
                            : 
                            !hasResult && !loading ? <NoResult /> : 'Loading...'
                        }
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main> 
    )
}

export default Metas;