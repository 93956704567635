import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Modal, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { FiSave, FiEdit, FiTrash, FiSearch, FiDelete, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { FaCaretRight, FaCaretLeft, FaStepBackward, FaStepForward, FaTimes } from 'react-icons/fa';
import Pagination from "react-js-pagination";
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import './styles.css';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import NoResult from '../../Errors/noResult';
import PageError from '../../Errors/pageError';

const Vendedores = () => {

    const [vendedores       , setVendedores]       = useState([]);
    const [loading          , setLoading]          = useState(true); 
    const [error            , setError]            = useState(false);
    const [labelBotao       , setLabelBotao]       = useState('Salvar');
    const [txtChanged       , setTxtChanged]       = useState('');
    const [oldTxtChanged    , setOldTxtChanged]    = useState('');
    const [hasResult        , setHasResult]        = useState(false);
    const [loadingSearch    , setLoadingSearch]    = useState(false);
    const [total            , setTotal]            = useState(0);
    const [page             , setPage]             = useState(1);
    const [id               , setId]               = useState(0); 
    const [uuid             , setUuid]             = useState(0); 
    const [nome             , setNome]             = useState('');
    const [password         , setPassword]         = useState('');
    const [perfil           , setPerfil]           = useState(2);
    const [enableValidation , setEnableValidation] = useState(false);
    const [modalShow        , setModalShow]        = useState(false);
    const mountedRef                               = useRef(true);
    
    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Vendedores' ];

    function escapeRegexCharacters(str)
    {
        return str.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&');
    }

    function onTextChanged(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        if(oldTxtChanged !== valor) {
            setTxtChanged(valor);
            setOldTxtChanged(valor);
            setLoadingSearch(true);
            loadVendedores(1, valor);
        }                    
    }

    const loadVendedores = async (pagina = 1, txtChanged = '') => {
        
        await api.get('vendedores/', {
            params: {
                page : pagina,
                t    : txtChanged
            },
            headers: authHeader()
        }).then((response) => {
            
            if(mountedRef.current) {
                setHasResult(true);
                setVendedores(response.data.vendedores);
                setTotal(parseInt(response.data.XCountRegister));
                setPage(pagina);
                if((!!txtChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                    setHasResult(false);
                }
            }

        }).catch(errors => {
            
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                setLoading(false);
                setLoadingSearch(false);
            }

        });
            
    }

    const cadastrarInfo = async (props, info) => {

        setModalShow(false);

        setLoading(true);
        
        let data = JSON.stringify({
            nome: props.nome,
            password: props.password,
            perfil: props.perfil,
            uuid: uuidValidate(props.uuid) ? props.uuid : uuidv4()
        });
        
        const requisicao = (props.id > 0) ? api.put : api.post;
        const url = (props.id > 0) ? `/vendedores/${props.id}` : `/vendedores`;
        
        await requisicao(url, data, {headers: authHeader()})
        .then(response => {
            
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    setHasResult(true);
                    const resultado = response.data.vendedores[0];
                    if(props.id > 0) {
                        const update = vendedores.filter((item) => item.id !== props.id);
                        setVendedores([...update, {id: resultado.id, nome: resultado.nome, perfil: resultado.perfil, uuid: resultado.uuid} ]);
                        Swal.fire(
                            'Sucesso!',
                            'Vendedor alterado com sucesso!',
                            'success'
                        );
                        limparInfo();
                    } else {
                        setVendedores([...vendedores, {id: resultado.id, nome: resultado.nome, perfil: resultado.perfil, uuid: resultado.uuid} ]);
                        setPage(1);
                        Swal.fire(
                            'Sucesso!',
                            'Vendedor cadastrado com sucesso!',
                            'success'
                        );
                        
                        setTotal(parseInt(response.data.XCountRegister));
                    }                    
                    
                }

            }
            
        }).catch(error => {

            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                
                setLoading(false);
            }

        });
        
    }

    const limparInfo = () => {
        setEnableValidation(false);
        setNome('');
        setPassword('');
        setPerfil(2);
        setId(0);
        setUuid(0);
        setLabelBotao('Salvar');
    }

    const carregarVendedor = (props) => {
        setModalShow(true);
        setNome(props.nome);
        setId(props.id);
        setUuid(props.uuid);
        setPerfil(props.perfil);
        setLabelBotao('Alterar');
        window.scrollTo(0, 0);
    }

    const excluirVendedor = (props) => {
        limparInfo();
        Swal.fire({
            title: `Deseja excluir o vendedor ${props.nome}?`,
            text: `Está operação não poderá ser desfeita!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {

                removerVendedor(props);
               
            }
        });
    }

    const removerVendedor = async (props) => {
        
        setLoading(true);
        
        await api.delete(`/vendedores/${props.id}`, {headers: authHeader()})
        .then(response => {
            
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }
                    Swal.fire(
                        'Atenção!',
                        `${resultadoTexto}`,
                        'warning'
                    );
                    return false;                 
                }
                else
                {
                    Swal.fire(
                        'Sucesso!',
                        'Operadora removida com sucesso!',
                        'success'
                    );
                    setHasResult(true);
                    setVendedores(response.data.vendedores);
                    setTotal(parseInt(response.data.XCountRegister));
                    setTxtChanged('');
                    setPage(1);
                    clearFilters();
                    if((!!txtChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                        setHasResult(false);
                    }
                }

            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {

                setLoading(false);
            }

        });
        
    }

    const clearFilters = () => {
        setTxtChanged('');
    } 

    const exibirModal = () => {
        limparInfo();
        setEnableValidation(false);
        setModalShow(true);
    }

    useEffect(() => {

        loadVendedores();
        return () => { mountedRef.current = false }

    }, []);

    return (
        (error) ? <PageError /> :
        <main>
            <section id="vendedor">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Item eventKey="1" onClick={exibirModal}><FiPlusCircle />&nbsp;&nbsp;Adicionar</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>      
                        <Modal
                            animation={false}
                            show={modalShow}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={()=> false}
                            >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                {labelBotao === 'Alterar' ? 'Alterar' : 'Adicionar'} Vendedor
                                </Modal.Title>
                            </Modal.Header>  
                        <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize={true} 
                            initialValues={{
                                uuid: uuid,
                                id: id,
                                nome: nome,
                                password: password,
                                perfil: perfil
                            }}
                            validationSchema={() => {
                                setEnableValidation(true);    
                                return Yup.object({
                                nome: Yup.string()
                                        .required('Campo deve ser preenchido!')
                                        .matches(
                                        /^[A-Za-z0-9']+$/,
                                        "Não deve conter caracteres especiais"
                                        )
                            })}}
                            onSubmit={cadastrarInfo}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                <Form.Control 
                                    autoComplete="off" 
                                    readOnly={true}
                                    name="uuid" 
                                    type="hidden" 
                                    onChange={handleChange} 
                                    value={values.uuid}
                                    placeholder=""
                                />
                                <Form.Control 
                                    autoComplete="off" 
                                    readOnly={true}
                                    name="id" 
                                    type="hidden" 
                                    onChange={handleChange} 
                                    value={values.id}
                                    placeholder=""
                                />
                                <Form.Label>Nome do Vendedor*</Form.Label>
                                <Form.Group controlId="field-operadora">
                                    <Form.Control 
                                        autoComplete="off" 
                                        name="nome" 
                                        type="text" 
                                        onChange={handleChange} 
                                        value={values.nome}
                                        isInvalid={!!errors.nome}
                                        placeholder=""
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Label>
                                    Senha
                                    {labelBotao === 'Salvar' ? 
                                    <small>&nbsp;(Caso não preencha a senha, será definido como padrão 12345)</small>
                                    : 
                                    <small>&nbsp;(Para refedinir a senha do vendedor, informar a nova senha abaixo!)</small>
                                    }
                                </Form.Label>
                                <Form.Group controlId="field-password">
                                    <Form.Control 
                                        autoComplete="off" 
                                        name="password" 
                                        type="text" 
                                        onChange={handleChange} 
                                        value={values.password}
                                        placeholder=""
                                    />
                                </Form.Group>

                                <Row>
                                    <Col xs={12} md={12}>
                                        <Form.Label>Perfil</Form.Label>
                                        <Form.Group>
                                            <Form.Check
                                                type="radio"
                                                label="Admin (Ter acesso a todos os módulos do sistema!)"
                                                value="1"
                                                name="perfil"
                                                id="perfil-admin"
                                                checked={(parseInt(values.perfil) === 1)}
                                                onChange={() => setFieldValue('perfil', 1)}
                                                />
                                                <Form.Check
                                                type="radio"
                                                label="Vendedor (Acesso apenas a cadastro das propostas, consultas das propostas, tabela de preços, simulador e relatório de vendas (apenas do vendedor))"
                                                value="2"
                                                name="perfil"
                                                id="perfil-vendedor"
                                                checked={(parseInt(values.perfil) === 2)}
                                                onChange={() => setFieldValue('perfil', 2)}
                                                />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" size="sm" type="submit">
                                        <FiSave /> {labelBotao}
                                    </Button>
                                    <Button variant="primary" 
                                            size="sm" 
                                            onClick={() => {
                                                resetForm();
                                                limparInfo();
                                            }} 
                                            type="reset">
                                        <FiDelete /> Limpar
                                    </Button>
                                    <Button variant="secondary" 
                                            className="padrao-cancelar"
                                            size="sm" 
                                            onClick={() => setModalShow(false)}>
                                        <FaTimes /> Fechar
                                    </Button>
                                </Modal.Footer>
                            </Form>
                            )}
                        </Formik>    
                        </Modal>       
                        {
                        (hasResult)
                        ?
                            <Fragment>
                                <Table responsive hover size="md">
                                <thead>
                                    <tr>
                                        <th>
                                        <Form.Group controlId="field-search">
                                        {loadingSearch ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        /> : <FiSearch />}
                                        <Form.Control 
                                            autoComplete="off" 
                                            type="text"
                                            placeholder="Vendedor"
                                            aria-label="Vendedor"
                                            maxLength={20}
                                            value={txtChanged}
                                            onChange={(e) => {
                                                setTxtChanged(e.target.value.trim());
                                                if(e.target.value === '') {
                                                    onTextChanged(e);
                                                }
                                            }}
                                            onKeyPress={(e) => {
                                                if(e.key === 'Enter') {
                                                    onTextChanged(e);
                                                }
                                            }}
                                        />
                                        </Form.Group>
                                        </th>
                                        <th style={{width:"15px"}}></th>
                                        <th style={{width:"15px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(vendedores.length === 0) ? <><tr><td colSpan={4} style={{textAlign: 'center'}}>Nenhum resultado encontrado!</td></tr></> : <>
                                    {
                                        vendedores.sort((a,b) =>  +(a.nome.toLowerCase() > b.nome.toLowerCase()) || +(a.nome.toLowerCase() === b.nome.toLowerCase()) - 1).slice(0, 10).map((props) => {
                                            return (
                                            <tr key={props.id}>
                                                <td>{props.nome}</td>
                                                <td>
                                                    <button className="botao" onClick={() => carregarVendedor(props)}>
                                                        <FiEdit style={{color:'#638c5e', cursor: 'pointer'}} />
                                                    </button>
                                                </td>
                                                <td>
                                                    <button className="botao" onClick={() => excluirVendedor(props)}>
                                                        <FiTrash style={{color:'#c94646', cursor: 'pointer'}} />                                            
                                                    </button>
                                                </td>
                                            </tr>
                                            )
                                        })
                                    }
                                    </>}
                                </tbody>
                                
                                </Table>
                                {(vendedores.length === 0) ? <></> :
                                    <>
                                    <Pagination
                                    nextPageText={<FaCaretRight size={19} />}
                                    prevPageText={<FaCaretLeft size={19}  />}
                                    lastPageText={<FaStepForward />}
                                    firstPageText={<FaStepBackward />}
                                    activePage={page}
                                    itemsCountPerPage={10}
                                    totalItemsCount={total}
                                    pageRangeDisplayed={5}
                                    onChange={(ev) => loadVendedores(ev, txtChanged)}
                                    />
                                    <hr/><br />
                                    </>
                                }    
                            </Fragment>
                        :
                            !hasResult && !loading ? <NoResult /> : 'Loading...'
                        }                 
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>        
    )
}

export default Vendedores;