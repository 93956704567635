import React from "react";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 0,
        margin: 0
      },
      title: {
        textAlign: 'center',
      },
      author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
      },
      subtitle: {
        fontSize: 18,
        margin: 12,
      },
      text: {
        margin: 12,
        fontSize: 11,
        textAlign: 'left',
      },
      header: {
        fontSize: 11,
        marginBottom: 40,
        textAlign: 'center',
        color: 'grey',
      },
      pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
      },
      table: { 
        display: "flex",
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomWidth: 0, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderTopWidth: 0,
      }, 
      tableRow: { 
        margin: "auto", 
        flexDirection: "row",
      }, 
      tableColHeader: { 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderBottomWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderTopWidth: 0,
        padding: "8px"
      },   
      tableCol: { 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        wordWrap: "break-all",
        borderBottomWidth: 0, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderTopWidth: 0,
        padding: "8px",
        textAlign: "left"
      }, 
      tableCellHeader: {
        fontSize: 11,
        fontWeight: 500
      },  
      tableCell: { 
        display: "flex",
        flexWrap: "wrap",
        fontSize: 9 
      }
});

const ExportPDF = (info) => {
    return (
        <Document>
        <Page size="A4" style={styles.body} orientation="landscape">
            <Text style={styles.header} fixed>
            SUA LOGO
            </Text>
            <Text style={styles.title}>Propostas</Text>
            <Text style={styles.author}>Listagem</Text>
            <View style={styles.table}> 
                <View style={styles.tableRow} fixed> 
                    <View style={styles.tableColHeader}> 
                        <Text style={[styles.tableCellHeader, { width: '50px' }]}>Venda</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={[styles.tableCellHeader, { width: '140px' }]}>Operadora</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={[styles.tableCellHeader, { width: '140px' }]}>Vendedor</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={[styles.tableCellHeader, { width: '280px' }]}>Nome</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={[styles.tableCellHeader, { width: '80px' }]}>Valor</Text> 
                    </View> 
                </View>

                {
                info.dados.sort((a,b) =>  (b.data_venda - a.data_venda)).map((props) => {
                    return (
                        
                        <View style={styles.tableRow} key={props.id}> 
                            <View style={styles.tableCol}> 
                                <Text style={[styles.tableCell, { width: '50px' }]}>{props.data_venda}</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={[styles.tableCell, { width: '140px' }]}>{props.operadora}</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={[styles.tableCell, { width: '140px' }]}>{props.vendedor}</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={[styles.tableCell, { width: '280px' }]}>{props.nome}</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={[styles.tableCell, { width: '80px' }]}>{props.valor_proposta}</Text> 
                            </View> 
                        </View> 

                    )
                })
                }
            </View>
            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => ( `${pageNumber} / ${totalPages}` )} fixed />
        </Page>
</Document>

    )
}

export default ExportPDF;