import React, { useState, useEffect, Fragment, useContext, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Card, Form, Dropdown, DropdownButton, Tabs, Tab, Button, Alert } from 'react-bootstrap';
import { FiMoreHorizontal, FiEdit, FiUser, FiUsers, FiDollarSign, FiLayers, FiSave, FiBook, FiPlusCircle, FiPlus } from 'react-icons/fi';
import { AiOutlineDelete } from 'react-icons/ai';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import CurrencyInput from '../../../_components/CurrencyInput';
import axios from 'axios';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import { convertCurrencyEn, convertCurrencyBr } from '../../../utils/global';
import MaskedInput from 'react-text-mask';
import Select from 'react-select';
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt-BR';
import add from '../../../assets/add-membros.svg';
import * as moment from 'moment';
import './styles.css';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import UserContext from '../../../hooks/UserContext';

const Proposta = (props) => {
    
    let history = useHistory();
    
    let idContract = props.idContract;
    
    const { user                 , perfil }                  = useContext(UserContext);
    const [loading               , setLoading]               = useState(true); 
    const [error                 , setError]                 = useState(false);
    const [tipo                  , setTipo]                  = useState(props.pfpj);
    const [id                    , setId]                    = useState(0);
    const [adesao                , setAdesao]                = useState(1);
    const [idVendedor            , setIdVendedor]            = useState('');
    const [uuid                  , setUuid]                  = useState(idContract); 
    const [numeroProposta        , setNumeroProposta]        = useState('0');
    const [quantidadeVidas       , setQuantidadeVidas]       = useState();
    const [idOperadora           , setIdOperadora]           = useState(0);
    const [idAdministradora      , setIdAdministradora]      = useState(0);
    const [idClassificacao       , setIdClassificacao]       = useState(0);
    const [acomodacao            , setAcomodacao]            = useState(0);
    const [valorProposta         , setValorProposta]         = useState();
    const [descPerc              , setDescPerc]              = useState('');
    const [descReal              , setDescReal]              = useState('');
    const [valorFinal            , setValorFinal]            = useState('');
    const [formaPagamento        , setFormaPagamento]        = useState('');
    const [numeroCartao          , setNumeroCartao]          = useState('');
    const [cobertura             , setCobertura]             = useState(0);
    const [dataVenda             , setDataVenda]             = useState('');
    const [vigencia              , setVigencia]              = useState('');
    const [dataVcto              , setDataVcto]              = useState('');
    const [cpfcnpj               , setCpfcnpj]               = useState('');
    const [nome                  , setNome]                  = useState('');
    const [razaoSocial           , setRazaoSocial]           = useState('');    
    const [dataNascimento        , setDataNascimento]        = useState('');
    const [genero                , setGenero]                = useState(0);
    const [estadoCivil           , setEstadoCivil]           = useState(0);
    const [rg                    , setRg]                    = useState('');
    const [cep                   , setCep]                   = useState('');
    const [endereco              , setEndereco]              = useState('');
    const [complemento           , setComplemento]           = useState('');
    const [referencia            , setReferencia]            = useState('');
    const [bairro                , setBairro]                = useState('');
    const [cidade                , setCidade]                = useState('');
    const [uf                    , setUf]                    = useState('');
    const [ddd                   , setDdd]                   = useState('');
    const [telefone              , setTelefone]              = useState('');
    const [dddCelular            , setDddCelular]            = useState('');
    const [celular               , setCelular]               = useState('');
    const [email                 , setEmail]                 = useState('');
    const [valorTitular          , setValorTitular]          = useState('');
    const [observacao            , setObservacao]            = useState('');
    const [valorComissao1        , setValorComissao1]        = useState('');
    const [valorComissao2        , setValorComissao2]        = useState('');
    const [valorComissao3        , setValorComissao3]        = useState('');
    const [valorComissao4        , setValorComissao4]        = useState('');
    const [valorComissao5        , setValorComissao5]        = useState('');
    const [valorComissao6        , setValorComissao6]        = useState('');
    const [valorComissao7        , setValorComissao7]        = useState('');
    const [valorComissao8        , setValorComissao8]        = useState('');
    const [valorComissao9        , setValorComissao9]        = useState('');
    const [valorComissao10       , setValorComissao10]       = useState('');
    const [valorComissao11       , setValorComissao11]       = useState('');
    const [valorComissao12       , setValorComissao12]       = useState('');
    const [valorComissaoVendedor , setValorComissaoVendedor] = useState('');
    const [tipoPendencia         , setTipoPendencia]         = useState('');
    const [obsPendencia          , setObsPendencia]          = useState('');
    const [enableValidation      , setEnableValidation]      = useState(false);
    const [vendedores            , setVendedores]            = useState([]);
    const [administradoras       , setAdministradoras]       = useState([]);
    const [operadoras            , setOperadoras]            = useState([]);
    const [classificacoes        , setClassificacoes]        = useState([]);
    const [msgErrors             , setMsgErrors]             = useState();
    const [shareholders          , setShareholders]          = useState([]); 
    const mountedRef                                         = useRef(true);
   
    // Breadcrumb 
    const lista = props.lista;

    const generoList = [
        { value: '1', label: 'Masculino'},
        { value: '2', label: 'Feminino'}
    ];

    const formaPagamentoList = [
        { value: '1', label: 'Cartão de Crédito'},
        { value: '2', label: 'Cartão de Débito'},
        { value: '3', label: 'Boleto'},
        { value: '4', label: 'Dinheiro'},
        { value: '5', label: 'Cheque'},
    ];
        
    const estadoCivilList = [
        { value: '1', label: 'Solteiro(a)'},
        { value: '2', label: 'Casado(a)'},
        { value: '3', label: 'Divorciado(a)'},
        { value: '4', label: 'Viúvo(a)'}
    ];

    const coberturaList = [
        { value: '1', label: 'Total'},
        { value: '2', label: 'Reduzida'},
        { value: '3', label: 'Comprada'},
    ];

    const acomodacaoList = [
        { value: '1', label: 'Apartamento'},
        { value: '2', label: 'Enfermaria'},
        { value: '3', label: 'Ambulatório'}
    ];

    const parentescosList = [
        { value: '1', label: 'Cônjuge'},
        { value: '2', label: 'Companheiro(a)'},
        { value: '3', label: 'Filho(a)'},
        { value: '4', label: 'Pai'},
        { value: '5', label: 'Mãe'},
    ];

    const cadastrarInfo = async (props) => {
        
        setLoading(true);
        
        let data = JSON.stringify({
            'proposta' : {
                pk_proposta : props.id,
                pf_pj : props.tipo,
                adesao : props.adesao,
                fk_vendedor : props.fk_vendedor,
                fk_operadora : props.fk_operadora,
                fk_administradora : props.fk_administradora,
                fk_classificacao : props.fk_classificacao,
                numero_proposta : props.numeroProposta,
                acomodacao : props.acomodacao,
                quantidade_vidas : props.quantidadeVidas,
                valor_proposta : convertCurrencyEn(props.valorProposta),
                desconto : convertCurrencyEn(props.descPerc),
                desconto_real : convertCurrencyEn(props.descReal),
                valor_final : convertCurrencyEn(valorFinal),
                data_venda : props.dataVenda,
                data_vencimento : props.dataVencimento,
                vigencia : props.vigencia,
                forma_pagamento : props.formaPagamento,
                numero_cartao : props.numeroCartao,
                cobertura_carencia : props.cobertura,
                cpf_cnpj : props.cpfcnpj,
                nome : props.nome,
                razao_social : (!props.razaoSocial) ? props.nome : props.razaoSocial,
                data : (!props.dataNascimento) ? `` : props.dataNascimento,
                genero : (!props.genero) ? `` : props.genero,
                estado_civil : (!props.estadoCivil) ? `` : props.estadoCivil,
                rg : (!props.rg) ? `` : props.rg,
                cep : props.cep,
                endereco : props.endereco,
                complemento : props.complemento,
                referencia : props.referencia,
                bairro : props.bairro,
                cidade : props.cidade,
                uf : props.uf,
                ddd1 : props.ddd,
                telefone1 : props.telefone,
                ddd2 : props.dddCelular,
                telefone2 : props.celular,
                email : props.email,
                valor : convertCurrencyEn(props.valorTitular),
                obs_responsavel : props.observacao,
                pendencias : props.tipoPendencia,
                obs : props.obsPendencia,
                status: '1',
                uuid: uuidValidate(props.uuid) ? props.uuid : uuidv4()
            },
            'comissoes' : {
                comissao1 : convertCurrencyEn(props.valorComissao1),
                comissao2 : convertCurrencyEn(props.valorComissao2),
                comissao3 : convertCurrencyEn(props.valorComissao3),
                comissao4 : convertCurrencyEn(props.valorComissao4),
                comissao5 : convertCurrencyEn(props.valorComissao5),
                comissao6 : convertCurrencyEn(props.valorComissao6),
                comissao7 : convertCurrencyEn(props.valorComissao7),
                comissao8 : convertCurrencyEn(props.valorComissao8),
                comissao9 : convertCurrencyEn(props.valorComissao9),
                comissao10 : convertCurrencyEn(props.valorComissao10),
                comissao11 : convertCurrencyEn(props.valorComissao11),
                comissao12 : convertCurrencyEn(props.valorComissao12),
                comissao_vendedor : convertCurrencyEn(props.valorComissaoVendedor)
            },
            'dependentes' : shareholders                
        });
        
        const requisicao = uuidValidate(props.uuid) ? api.put : api.post;
        const url = uuidValidate(props.uuid) ? `/proposta/${props.uuid}` : `/proposta`;

        await requisicao(url, data, {headers: authHeader()})
        .then(response => {

            if(mountedRef.current) {

                if(response.data.error !== undefined) {   
                    setLoading(false);
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object) { const texto = Object.values(response.data.error); texto.map((e)=> ( resultadoTexto += e+'<br />' )); }
                    else { resultadoTexto = response.data.error; }
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else {
                    const url = (parseInt(props.tipo) === 1) ? `detalhe-proposta-pessoa-fisica` : `detalhe-proposta-pessoa-juridica`;
                    history.push(`/${url}/`+response.data);
                }

            }

        }).catch(error => {

            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {
            
                setLoading(false);
            
            }

        });
        
    }

    const loadProposta = async (id, props) => {
            
        await api.get(`proposta/${id}`, { params: { }, headers: authHeader() }).then(response => {
            
            if(mountedRef.current) {

                if(parseInt(response.data.proposta[0].pf_pj) !== parseInt(props.pfpj))
                {
                    throw new Error();
                }
                
                setId(response.data.proposta[0].pk_proposta);
                setUuid(response.data.proposta[0].uuidProposta);
                setTipo(response.data.proposta[0].pf_pj);
                setAdesao(parseInt(response.data.proposta[0].adesao));
                setIdVendedor(response.data.proposta[0].fk_vendedor);
                setNumeroProposta(response.data.proposta[0].numero_proposta);
                setQuantidadeVidas(parseInt(response.data.proposta[0].quantidade_vidas));
                setIdOperadora(response.data.proposta[0].fk_operadora);
                setIdAdministradora(response.data.proposta[0].fk_administradora);
                setIdClassificacao(response.data.proposta[0].fk_classificacao);
                setAcomodacao(response.data.proposta[0].idAcomodacao);
                setValorProposta(response.data.proposta[0].valor_proposta);
                setDescPerc(response.data.proposta[0].desconto_perc);
                setDescReal(response.data.proposta[0].desconto_real);
                setValorFinal(response.data.proposta[0].valor_final);
                setFormaPagamento(response.data.proposta[0].idFormaPagamento);
                setNumeroCartao(response.data.proposta[0].numero_cartao);
                setCobertura(response.data.proposta[0].idCobertura);
                setDataVenda(
                    moment(response.data.proposta[0].data_venda, "DD/MM/YYYY").format("DD/MM/YYYY") !== 'Data inválida' ? 
                    new Date(moment(response.data.proposta[0].data_venda, "DD/MM/YYYY").format("YYYY-MM-DD")+' 00:00:00') : null
                );
                setVigencia(
                    moment(response.data.proposta[0].vigencia, "DD/MM/YYYY").format("DD/MM/YYYY") !== 'Data inválida' ? 
                    new Date(moment(response.data.proposta[0].vigencia, "DD/MM/YYYY").format("YYYY-MM-DD")+' 00:00:00') : null
                );
                setDataVcto(
                    moment(response.data.proposta[0].data_vencimento, "DD/MM/YYYY").format("DD/MM/YYYY") !== 'Data inválida' ? 
                    new Date(moment(response.data.proposta[0].data_vencimento, "DD/MM/YYYY").format("YYYY-MM-DD")+' 00:00:00') : null
                );
                setCpfcnpj(response.data.proposta[0].cpf_cnpj);
                setNome(response.data.proposta[0].nome);
                setRazaoSocial(response.data.proposta[0].razao_social);
                setDataNascimento(
                    moment(response.data.proposta[0].data_nascimento, "DD/MM/YYYY").format("DD/MM/YYYY") !== 'Data inválida' ? 
                    new Date(moment(response.data.proposta[0].data_nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")+' 00:00:00') : null
                );
                setGenero(response.data.proposta[0].idGenero);
                setEstadoCivil(response.data.proposta[0].idEstadoCivil);
                setRg(response.data.proposta[0].rg);
                setCep(response.data.proposta[0].cep);
                setEndereco(response.data.proposta[0].endereco);
                setComplemento(response.data.proposta[0].complemento);
                setReferencia(response.data.proposta[0].referencia);
                setBairro(response.data.proposta[0].bairro);
                setCidade(response.data.proposta[0].cidade);
                setUf(response.data.proposta[0].uf);
                setDdd(response.data.proposta[0].ddd1);
                setTelefone(response.data.proposta[0].telefone1);
                setDddCelular(response.data.proposta[0].ddd2);
                setCelular(response.data.proposta[0].telefone2);
                setEmail(response.data.proposta[0].email);
                setValorTitular(response.data.proposta[0].valor_titular);
                setObservacao(response.data.proposta[0].obs_responsavel);
                setValorComissao1(response.data.comissao[0].valor);
                setValorComissao2(response.data.comissao[1].valor);
                setValorComissao3(response.data.comissao[2].valor);
                setValorComissao4(response.data.comissao[3].valor);
                setValorComissao5(response.data.comissao[4].valor);
                setValorComissao6(response.data.comissao[5].valor);
                setValorComissao7(response.data.comissao[6].valor);
                setValorComissao8(response.data.comissao[7].valor);
                setValorComissao9(response.data.comissao[8].valor);
                setValorComissao10(response.data.comissao[9].valor);
                setValorComissao11(response.data.comissao[10].valor);
                setValorComissao12(response.data.comissao[11].valor);
                setValorComissaoVendedor(response.data.comissao[12].valor);
                setTipoPendencia(response.data.proposta[0].pendencias);
                setObsPendencia(response.data.proposta[0].obs);
                setShareholders(response.data.dependente.map(e => {
                        return( {
                                cpfDependente: e.cpf,
                                nomeDependente: e.nome,
                                dataNascDependente: moment(e.data_nascimento, "DD/MM/YYYY").format("DD/MM/YYYY") !== 'Data inválida' ? new Date(moment(e.data_nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")+' 00:00:00') : null,
                                generoDependente: e.idGenero,
                                estadoCivilDependente: e.idEstadoCivil,
                                rgDependente: e.rg,
                                parentescoDependente: e.idParentesco,
                                valorDependente: e.valor_responsavel,
                                obsDependente: e.obs_responsavel
                            }
                        )
                    }
                        
                ));  

            }
            
        }).catch(erros => {
    
            setError(true);
    
        });
    
    }

    const loadInfo = async (perfil, user) => {
        
        let vendedores = "getAllVendedores/";
        let administradoras = "getAllAdministradoras/";
        let operadoras = "getAllOperadoras/";
        let classificacoes = "getAllClassificacoes/";

        const requestVendedores = await api.get(vendedores,{ params: { page: 1, t: '' }, headers: authHeader() });
        const requestAdministradoras = await api.get(administradoras,{ params: { page: 1, t: '' }, headers: authHeader() });
        const requestOperadoras = await api.get(operadoras,{ params: { page: 1, t: '', s : '' }, headers: authHeader() });
        const requestClassificacoes = await api.get(classificacoes,{ params: { page: 1, t: '', s: '' }, headers: authHeader() });
        
        axios.all([requestVendedores, requestAdministradoras, requestOperadoras, requestClassificacoes]).then(axios.spread((...responses) => {
            
            if(mountedRef.current) {
                const requestVendedores = responses[0];
                const requestAdministradoras = responses[1];
                const requestOperadoras = responses[2];
                const requestClassificacoes = responses[3];
                setVendedores(requestVendedores.data.vendedores.map(e => ({ value: e.id, label: e.nome})));
                if(requestVendedores.data.vendedores.length === 1) {
                    setIdVendedor(requestVendedores.data.vendedores.map(e => e.id));
                }
                setAdministradoras(requestAdministradoras.data.administradoras.map(e => ({ value: e.id, label: e.descricao})));
                setOperadoras(requestOperadoras.data.operadoras.map(e => ({ value: e.id, label: e.descricao})));
                setClassificacoes(requestClassificacoes.data.classificacoes.map(e => ({ value: e.id, label: e.descricao})));
            }

        })).catch(errors => {
            
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {

                setLoading(false);

            }

        });
    
    }

    const handleShareholderChange = (idx, tipo) => (evt) => {
        
        const newShareholders = shareholders.map((shareholder, sidx) => {
            if (idx !== sidx) return shareholder;
            if (tipo === 'cpfDependente') return { ...shareholder, cpfDependente: evt.target.value };
            if (tipo === 'dataNascDependente') return { ...shareholder, dataNascDependente: evt };
            if (tipo === 'estadoCivilDependente') return { ...shareholder, estadoCivilDependente: evt };
            if (tipo === 'generoDependente') return { ...shareholder, generoDependente: evt }
            if (tipo === 'nomeDependente') return { ...shareholder, nomeDependente: evt.target.value };
            if (tipo === 'obsDependente') return { ...shareholder, obsDependente: evt.target.value };
            if (tipo === 'parentescoDependente') return { ...shareholder, parentescoDependente: evt };
            if (tipo === 'rgDependente') return { ...shareholder, rgDependente: evt.target.value };
            if (tipo === 'tipo') return { ...shareholder, tipo: evt };
            if (tipo === 'valorDependente') return { ...shareholder, valorDependente: evt.target.value };
            return true;
        });
        setShareholders(newShareholders);
    };

    const handleAddShareholder = () => {
        setShareholders(shareholders.concat([{ 
            cpfDependente: "",
            nomeDependente: "",
            dataNascDependente: "",
            generoDependente: "",
            estadoCivilDependente: "",
            rgDependente: "",
            parentescoDependente: "",
            valorDependente: "",
            obsDependente: ""
        }]));
    };

    const handleRemoveShareholder = (idx) => () => {
        setShareholders(shareholders.filter((s, sidx) => idx !== sidx));
    };

    const calcularValorTotal = () => {
    
        var valorFinal = 0;
        var valor = convertCurrencyEn(document.querySelector('[name="valorProposta"]').value);
        var descPerc = convertCurrencyEn(document.querySelector('[name="descPerc"]').value);
        var descReal = convertCurrencyEn(document.querySelector('[name="descReal"]').value);
        valorFinal = valor - ((valor * descPerc)/100 + descReal);
        setValorFinal(convertCurrencyBr(valorFinal));
        return false;
    }

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
          keyEvent.preventDefault();
        }
    }

    const AlertModal = (props) => {
        if(props.msgErrors !== undefined) {
            if(Object.keys(props.msgErrors).length > 0) {    
            return  (
                    <Alert variant="danger">
                        <Alert.Heading style={{fontSize: '14px', marginTop: '9px', fontWeight: 'bold'}}>Campos obrigatórios</Alert.Heading>
                        <hr />
                        <div style={{fontSize: '11px'}}>
                            {
                                Object.values(msgErrors).map((e, index) => {
                                    return (
                                        <div key={index}>
                                        {e}<br></br>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Alert>
                )
            }
        }
        return <></>
    }

    const ShowLinkDetail = () => {
        
        if(uuidValidate(uuid)) {
            return (
                <>
                <Dropdown.Item as={Link} to={`/proposta-pessoa-fisica`} eventKey="2">
                    <FiPlusCircle />&nbsp;&nbsp;Novo
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={(props.pfpj === 1) ? `/detalhe-proposta-pessoa-fisica/${uuid}` : `/detalhe-proposta-pessoa-juridica/${uuid}`} eventKey="3">
                    <FiBook />&nbsp;&nbsp;Detalhe
                </Dropdown.Item>
                </>
            )
        }else{
            return null;
        }
        
    }

    useEffect(() => {

        loadInfo(perfil, user);
        if(uuidValidate(uuid)) {
            loadProposta(uuid, props);
        }
        return () => { mountedRef.current = false }

    }, [uuid, props, perfil, user]);

    return (
        (error) ? <></> :
        <main>
            <section id="proposta-pessoa-pfpj">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    disabled={uuidValidate(uuid) ? false : true}
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <ShowLinkDetail />
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>
                            {(idContract > 0) ? 
                                <h2 style={{textAlign: 'center', marginTop: '11px'}}>
                                    {nome}
                                </h2>
                            : 
                            '' 
                            }
                            <Formik
                                validateOnChange={enableValidation}
                                validateOnBlur={enableValidation}
                                enableReinitialize={true} 
                                initialValues={{
                                    id: id,
                                    uuid: uuid,
                                    tipo: tipo,
                                    adesao: adesao,
                                    fk_vendedor: idVendedor,
                                    numeroProposta: numeroProposta,
                                    quantidadeVidas: quantidadeVidas,
                                    fk_operadora: idOperadora,
                                    fk_administradora: idAdministradora,
                                    fk_classificacao: idClassificacao,
                                    acomodacao: acomodacao,
                                    valorProposta: valorProposta,
                                    descPerc: descPerc,
                                    descReal: descReal,
                                    dataVenda: dataVenda,
                                    vigencia: vigencia,
                                    dataVencimento: dataVcto,
                                    formaPagamento: formaPagamento,
                                    numeroCartao: numeroCartao,
                                    cobertura: cobertura,
                                    cpfcnpj: cpfcnpj,
                                    nome: nome,
                                    razaoSocial: razaoSocial,
                                    dataNascimento: dataNascimento,
                                    genero: genero,
                                    estadoCivil: estadoCivil,
                                    rg: rg,
                                    cep: cep,
                                    endereco: endereco,
                                    complemento: complemento,
                                    referencia: referencia,
                                    bairro: bairro,
                                    cidade: cidade,
                                    uf: uf,
                                    ddd: ddd,
                                    telefone: telefone,
                                    dddCelular: dddCelular,
                                    celular: celular,
                                    email: email,
                                    valorTitular: valorTitular,
                                    observacao: observacao,
                                    valorComissao1: valorComissao1,
                                    valorComissao2: valorComissao2,
                                    valorComissao3: valorComissao3,
                                    valorComissao4: valorComissao4,
                                    valorComissao5: valorComissao5,
                                    valorComissao6: valorComissao6,
                                    valorComissao7: valorComissao7,
                                    valorComissao8: valorComissao8,
                                    valorComissao9: valorComissao9,
                                    valorComissao10: valorComissao10,
                                    valorComissao11: valorComissao11,
                                    valorComissao12: valorComissao12,
                                    valorComissaoVendedor: valorComissaoVendedor,
                                    tipoPendencia: tipoPendencia,
                                    obsPendencia: obsPendencia
                                }}
                                validationSchema={() => {
                                    setEnableValidation(true);    
                                    return Yup.object({
                                        fk_vendedor: Yup.number()
                                                    .typeError("Informe apenas números!")
                                                    .moreThan(0, 'Selecione um vendedor!')
                                                    .required('Selecione um vendedor!'),  
                                        quantidadeVidas: Yup.number()
                                                    .typeError("Informe apenas números!")
                                                    .moreThan(0, 'Informe a quantidade de vidas!')
                                                    .required('Quantidade de vidas é obrigatório!'),  
                                        fk_operadora: Yup.number()
                                                    .typeError("Informe apenas números!")   
                                                    .moreThan(0, 'Selecione uma operadora!')
                                                    .required('Selecione uma operadora!'),
                                        dataVenda: Yup.string()
                                                    .required('Data da venda é obrigatório!').nullable(),
                                        cpfcnpj: Yup.string()
                                                    .matches(/(^\d{3}.\d{3}.\d{3}-\d{2}$)|(^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$)/, `${(props.pfpj === 1) ? `CPF` : `CNPJ`} com formato inválido!`)
                                                    .required(`${(props.pfpj === 1) ? `CPF` : `CNPJ`} é obrigatório!`),
                                        nome: Yup.string()
                                                    .required(`Nome ${(props.pfpj === 1) ? `do responsável` : `Fantasia`} é obrigatório!`).nullable(),
                                        cep: Yup.string()
                                                    .matches(/[0-9]{2}.[0-9]{3}-[\d]{3}/g, 'CEP com formato inválido!'),
                                        ddd: Yup.string()
                                                    .matches(/([0-9]{2})/g, 'DDD inválido!'),
                                        dddCelular: Yup.string()
                                                    .matches(/([0-9]{2})/g, 'DDD inválido!'),
                                        telefone: Yup.string()
                                                    .matches(/\d{4}-\d{4}/g, 'Telefone inválido!'),
                                        celular: Yup.string()
                                                    .matches(/\d{5}-\d{4}/g, 'Celular inválido!'),
                                    })
                                }}
                                onSubmit={cadastrarInfo}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    resetForm,
                                    setFieldValue,
                                    validateForm,
                                    isSubmitting,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                <Form noValidate onSubmit={handleSubmit} onKeyDown={onKeyDown}>      
                                    <AlertModal msgErrors={msgErrors} />                    
                                    <Tabs onSelect={(key, e) => (e) ? e.currentTarget.blur() : null } transition={false} defaultActiveKey="proposta" id="dados-proposta">
                                        <Tab eventKey="proposta" title={<Fragment><FiEdit />Proposta</Fragment>}>
                                            <div className="tab-body">
                                            <Form.Control 
                                                autoComplete="off" 
                                                readOnly={true}
                                                name="uuid" 
                                                type="hidden" 
                                                value={values.uuid}
                                                placeholder=""
                                            />
                                            <Form.Control 
                                                    autoComplete="off" 
                                                    readOnly={true}
                                                    name="tipo" 
                                                    type="hidden" 
                                                    onBlur={handleChange} 
                                                    defaultValue={values.tipo}
                                                    placeholder=""
                                                />
                                                <Form.Control 
                                                    autoComplete="off" 
                                                    readOnly={true}
                                                    name="id" 
                                                    type="hidden" 
                                                    onBlur={handleChange} 
                                                    defaultValue={values.id}
                                                    placeholder=""
                                                />
                                                <Row>
                                                    <Col xs={12} md={12}>
                                                        <Form.Group>
                                                            <Form.Check
                                                                type="radio"
                                                                label="Com Adesão"
                                                                value="1"
                                                                name="adesao"
                                                                id="com-adesao"
                                                                checked={(values.adesao === 1)}
                                                                onChange={() => setFieldValue('adesao', 1)}
                                                                />
                                                                <Form.Check
                                                                type="radio"
                                                                label="Sem Adesão"
                                                                value="2"
                                                                name="adesao"
                                                                id="sem-adesao"
                                                                checked={(values.adesao === 2)}
                                                                onChange={() => setFieldValue('adesao', 2)}
                                                                />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Nº da proposta*</Form.Label>
                                                        <Form.Group controlId="field-numero-proposta">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="numeroProposta" 
                                                                type="text" 
                                                                onBlur={handleChange}
                                                                defaultValue={values.numeroProposta}
                                                                placeholder="0"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-vendedor">
                                                            <Form.Label>
                                                                Vendedor*
                                                            </Form.Label>  
                                                           
                                                                <Select 
                                                                    className={`select-default ${!!errors.fk_vendedor ? 'is-invalid' : ''}`}
                                                                    options={vendedores} 
                                                                    isClearable={vendedores.length === 1 ? false : true}
                                                                    isSearchable={vendedores.length === 1 ? false : true}
                                                                    onChange={ e => e === null ? setFieldValue("fk_vendedor", '') : setFieldValue("fk_vendedor", e.value) }
                                                                    value={values.fk_vendedor > 0 ? vendedores.find(obj => parseInt(obj.value) === parseInt(values.fk_vendedor)) : ''} 
                                                                    name="fk_vendedor" 
                                                                    placeholder=""
                                                                    noOptionsMessage={() => 'Sem resultados!'}
                                                                    styles={{
                                                                        singleValue: (provided, state) => {
                                                                            const paddingLeft = 7;
                                                                            return { ...provided, paddingLeft };
                                                                        },
                                                                        input: (provided, state) => {
                                                                            const paddingLeft = 7;
                                                                            return { ...provided, paddingLeft };
                                                                        },
                                                                        placeholder: (provided, state) => {
                                                                            const paddingLeft = 7;
                                                                            return { ...provided, paddingLeft };
                                                                        },
                                                                        menu: (provided, state) => {
                                                                            const borderRadius = 0;
                                                                            const fontSize = 12;
                                                                            return { ...provided, borderRadius, fontSize };
                                                                        },
                                                                    }}
                                                                />
                                                            
                                                            <Form.Control.Feedback type="invalid">{errors.fk_vendedor}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Quantidade de Vidas* </Form.Label>
                                                        <Form.Group controlId="field-quantidade-vidas">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="quantidadeVidas" 
                                                                type="text" 
                                                                onChange={handleChange} 
                                                                defaultValue={values.quantidadeVidas}
                                                                isInvalid={!!errors.quantidadeVidas}
                                                                placeholder=""
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.quantidadeVidas}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-operadora">
                                                            <Form.Label>
                                                                Operadora*
                                                            </Form.Label>      
                                                            <Select 
                                                                className={`select-default ${!!errors.fk_operadora ? 'is-invalid' : ''}`}
                                                                options={operadoras} 
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={ e => e === null ? setFieldValue("fk_operadora", '') : setFieldValue("fk_operadora", e.value) }
                                                                value={values.fk_operadora > 0 ? operadoras.find(obj => obj.value === values.fk_operadora) : ''} 
                                                                name="idOperadora" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.fk_operadora}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-administradora">
                                                            <Form.Label>
                                                                Administradora
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={administradoras} 
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={ e => e === null ? setFieldValue("fk_administradora", '') : setFieldValue("fk_administradora", e.value) }
                                                                value={values.fk_administradora > 0 ? administradoras.find(obj => obj.value === values.fk_administradora) : ''} 
                                                                name="fk_administradora" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-classificacao">
                                                            <Form.Label>
                                                                Classificação 
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={classificacoes} 
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                onChange={ e => e === null ? setFieldValue("fk_classificacao", '') : setFieldValue("fk_classificacao", e.value) }
                                                                value={values.fk_classificacao > 0 ? classificacoes.find(obj => obj.value === values.fk_classificacao) : ''} 
                                                                name="fk_classificacao" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-acomodacao">
                                                            <Form.Label>
                                                                Acomodação 
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={acomodacaoList} 
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                onChange={ e => e === null ? setFieldValue("acomodacao", '') : setFieldValue("acomodacao", e.value) }
                                                                value={values.acomodacao > 0 ? acomodacaoList.find(obj => obj.value === values.acomodacao) : ''} 
                                                                name="acomodacao" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Data da Venda*
                                                        </Form.Label>    
                                                        <Form.Group controlId="data-venda">
                                                            <DatePicker
                                                                customInput={
                                                                    <MaskedInput
                                                                    type="text"
                                                                    className={`form-control ${!!errors.dataVenda ? 'is-invalid' : ''}`}
                                                                    autoComplete="off" 
                                                                    placeholder="00/00/0000"
                                                                    guide={false}
                                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                    />
                                                                }
                                                                autoComplete="off" 
                                                                selected={values.dataVenda}
                                                                name="dataVenda"
                                                                onChange={date => setFieldValue("dataVenda", date)}
                                                                locale={pt}
                                                                dateFormat="P"
                                                                />
                                                            <Form.Control.Feedback type="invalid" style={{ 'display': !!errors.dataVenda ? 'block' : 'none' }} >{errors.dataVenda}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Vigência do Plano 
                                                        </Form.Label>    
                                                        <Form.Group controlId="vigencia-plano">
                                                            <DatePicker
                                                                customInput={
                                                                    <MaskedInput
                                                                    type="text"
                                                                    autoComplete="off" 
                                                                    placeholder="00/00/0000"
                                                                    guide={false}
                                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                    />
                                                                }
                                                                selected={values.vigencia}
                                                                name="vigencia"
                                                                autoComplete="off" 
                                                                className="form-control"
                                                                onChange={date => setFieldValue("vigencia", date)}
                                                                locale={pt}
                                                                dateFormat="P"
                                                                />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Data de Vencimento
                                                        </Form.Label>    
                                                        <Form.Group controlId="data-vencimento">
                                                            <DatePicker
                                                                customInput={
                                                                    <MaskedInput
                                                                    type="text"
                                                                    autoComplete="off" 
                                                                    placeholder="00/00/0000"
                                                                    guide={false}
                                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                    />
                                                                }
                                                                selected={values.dataVencimento}
                                                                name="dataVencimento"
                                                                autoComplete="off" 
                                                                className="form-control"
                                                                onChange={date => setFieldValue("dataVencimento", date)}
                                                                locale={pt}
                                                                dateFormat="P"
                                                                />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-forma">
                                                            <Form.Label>
                                                                Forma de Pagamento
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={formaPagamentoList} 
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                onChange={ e => e === null ? setFieldValue("formaPagamento", '') : setFieldValue("formaPagamento", e.value) }
                                                                value={values.formaPagamento > 0 ? formaPagamentoList.find(obj => obj.value === values.formaPagamento) : ''} 
                                                                name="formaPagamento" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Nº do Cartão</Form.Label>
                                                        <Form.Group controlId="numero-cartao">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="numeroCartao" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.numeroCartao}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-cobertura-carencia">
                                                            <Form.Label>
                                                                Cobertura de Carência
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={coberturaList} 
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                onChange={ e => e === null ? setFieldValue("cobertura", '') : setFieldValue("cobertura", e.value) }
                                                                value={values.cobertura > 0 ? coberturaList.find(obj => obj.value === values.cobertura) : ''} 
                                                                name="cobertura" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Valor da Proposta
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-proposta"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorProposta"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onFocus={() => values.valorProposta !== 0 && values.valorProposta !== ''  ? setFieldValue('valorProposta', values.valorProposta) : setFieldValue('valorProposta', '')}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        calcularValorTotal();
                                                                    }}
                                                                    placeholder="0,00"
                                                                    value={values.valorProposta} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Desconto (%)
                                                        </Form.Label>
                                                        <Form.Group controlId="desconto-perc"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="descPerc"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onFocus={() => values.descPerc !== 0 && values.descPerc !== ''  ? setFieldValue('descPerc', values.descPerc) : setFieldValue('descPerc', '')}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        calcularValorTotal();
                                                                    }}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.descPerc} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Desconto (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="desconto-real"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="descReal"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onFocus={() => values.descReal !== 0 && values.descReal !== ''  ? setFieldValue('descReal', values.descReal) : setFieldValue('descReal', '')}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        calcularValorTotal();
                                                                    }}
                                                                    placeholder="0,00"
                                                                    value={values.descReal} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Valor Final (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-final"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorFinal"
                                                                    type="text" 
                                                                    guide={false}
                                                                    readOnly={true}
                                                                    placeholder="0,00"
                                                                    value={valorFinal} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab>
                                        
                                        <Tab eventKey="informacoes" title={<Fragment><FiUser />Informações</Fragment>}>
                                            <div className="tab-body">
                                                <Row> 
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>{(props.pfpj === 1) ? `CPF` : `CNPJ`}*</Form.Label> 
                                                        <Form.Group controlId="field-cpfcnpj">
                                                            <MaskedInput
                                                                mask={
                                                                    (props.pfpj === 1) ? [ /[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-' , /\d/, /\d/]
                                                                                       : [ /[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/' , /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                                                                }
                                                                className={`form-control ${!!errors.cpfcnpj ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                autoComplete="off" 
                                                                name="cpfcnpj" 
                                                                placeholder={(props.pfpj === 1) ? `000.000.000-00` : `00.000.000/0000-00`}
                                                                guide={false}
                                                                onChange={handleChange} 
                                                                defaultValue={values.cpfcnpj}
                                                                />
                                                            <Form.Control.Feedback type="invalid">{errors.cpfcnpj}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={9}>
                                                        <Form.Label>{(props.pfpj === 1) ? `Nome` : `Nome Fantasia`}*</Form.Label>
                                                        <Form.Group controlId="field-nome">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="nome" 
                                                                type="text" 
                                                                onChange={handleChange} 
                                                                defaultValue={values.nome}
                                                                isInvalid={!!errors.nome}
                                                                placeholder=""
                                                            />
                                                            <Form.Control.Feedback type="invalid">{errors.nome}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    
                                                    {
                                                    (props.pfpj === 1) ?
                                                    <>                                             
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Data de Nascimento
                                                        </Form.Label>    
                                                        <Form.Group controlId="data-nascimento">
                                                            <DatePicker
                                                                customInput={
                                                                    <MaskedInput
                                                                    type="text"
                                                                    autoComplete="off" 
                                                                    placeholder="00/00/0000"
                                                                    guide={false}
                                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                    />
                                                                }
                                                                selected={values.dataNascimento}
                                                                name="dataNascimento"
                                                                className="form-control"
                                                                autoComplete="off" 
                                                                onChange={date => setFieldValue("dataNascimento", date)}
                                                                locale={pt}
                                                                dateFormat="P"
                                                                />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="genero">
                                                            <Form.Label>
                                                                Gênero
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={generoList} 
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                onChange={ e => e === null ? setFieldValue("genero", '') : setFieldValue("genero", e.value) }
                                                                value={values.genero > 0 ? generoList.find(obj => obj.value === values.genero) : ''} 
                                                                name="genero" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Group controlId="select-estado-civil">
                                                            <Form.Label>
                                                                Estado Civil
                                                            </Form.Label>                        
                                                            <Select 
                                                                className={`select-default`}
                                                                options={estadoCivilList} 
                                                                isClearable={true}
                                                                isSearchable={false}
                                                                onChange={ e => e === null ? setFieldValue("estadoCivil", '') : setFieldValue("estadoCivil", e.value) }
                                                                value={values.estadoCivil > 0 ? estadoCivilList.find(obj => obj.value === values.estadoCivil) : ''} 
                                                                name="estadoCivil" 
                                                                placeholder=""
                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                styles={{
                                                                    singleValue: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    input: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    placeholder: (provided, state) => {
                                                                        const paddingLeft = 7;
                                                                        return { ...provided, paddingLeft };
                                                                    },
                                                                    menu: (provided, state) => {
                                                                        const borderRadius = 0;
                                                                        const fontSize = 12;
                                                                        return { ...provided, borderRadius, fontSize };
                                                                    },
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>RG</Form.Label>
                                                        <Form.Group controlId="field-rg">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="rg" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.rg}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    </>
                                                    : 
                                                    
                                                    <Col xs={12} md={12}>
                                                        <Form.Label>
                                                            Razão Social
                                                        </Form.Label>    
                                                        <Form.Group controlId="razao-social">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="razaoSocial" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.razaoSocial}
                                                                isInvalid={!!errors.razaoSocial}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    
                                                    }

                                                    <Col xs={12} md={3}>
                                                        <Form.Label>CEP</Form.Label>
                                                        <Form.Group controlId="field-cep">
                                                            <MaskedInput
                                                                mask={[ /[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                                className={`form-control ${!!errors.cep ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                autoComplete="off" 
                                                                name="cep" 
                                                                placeholder="00.000-000"
                                                                guide={false}
                                                                onBlur={handleChange} 
                                                                defaultValue={values.cep}
                                                                />
                                                            <Form.Control.Feedback type="invalid">{errors.cep}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Form.Label>Endereço</Form.Label>
                                                        <Form.Group controlId="field-endereco">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="endereco" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.endereco}
                                                                placeholder="Endereço da casa, nº 99"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Complemento</Form.Label>
                                                        <Form.Group controlId="field-complemento">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="complemento" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.complemento}
                                                                placeholder="Apto 9999"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Ponto de Referência</Form.Label>
                                                        <Form.Group controlId="field-referencia">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="referencia" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.referencia}
                                                                placeholder="Próximo ao mercado"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Bairro</Form.Label>
                                                        <Form.Group controlId="field-bairro">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="bairro" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.bairro}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Cidade</Form.Label>
                                                        <Form.Group controlId="field-cidade">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="cidade" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.cidade}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>UF</Form.Label>
                                                        <Form.Group controlId="field-uf">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="uf" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.uf}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>DDD</Form.Label>
                                                        <Form.Group controlId="field-ddd">
                                                            <MaskedInput
                                                                mask={[ '(', /[0-9]/, /\d/, ')']}
                                                                className={`form-control ${!!errors.ddd ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                autoComplete="off" 
                                                                name="ddd" 
                                                                placeholder="(00)"
                                                                guide={false}
                                                                onBlur={handleChange} 
                                                                defaultValue={values.ddd}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.ddd}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Telefone</Form.Label>
                                                        <Form.Group controlId="field-telefone">
                                                            <MaskedInput
                                                                mask={[ /[0-9]/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/, ]}
                                                                className={`form-control ${!!errors.telefone ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                autoComplete="off" 
                                                                name="telefone" 
                                                                placeholder="9999-9999"
                                                                guide={false}
                                                                onBlur={handleChange} 
                                                                defaultValue={values.telefone}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.telefone}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>DDD</Form.Label>
                                                        <Form.Group controlId="field-ddd-celular">
                                                            <MaskedInput
                                                                mask={[ '(', /[0-9]/, /\d/, ')']}
                                                                className={`form-control ${!!errors.dddCelular ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                autoComplete="off" 
                                                                name="dddCelular" 
                                                                placeholder="(00)"
                                                                guide={false}
                                                                onBlur={handleChange} 
                                                                defaultValue={values.dddCelular}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.dddCelular}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Celular</Form.Label>
                                                        <Form.Group controlId="field-celular">
                                                            <MaskedInput
                                                                mask={[ /[0-9]/, /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/, ]}
                                                                className={`form-control ${!!errors.celular ? 'is-invalid' : ''}`}
                                                                type="text"
                                                                autoComplete="off" 
                                                                name="celular" 
                                                                placeholder="99999-9999"
                                                                guide={false}
                                                                onBlur={handleChange} 
                                                                defaultValue={values.celular}
                                                                />
                                                                <Form.Control.Feedback type="invalid">{errors.celular}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={12}>
                                                        <Form.Label>E-mail</Form.Label>
                                                        <Form.Group controlId="field-email">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="email" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.email}
                                                                placeholder="seuemail@provedor.com.br"
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Valor (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-titular"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorTitular"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorTitular} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={9}>
                                                        <Form.Label>Observação</Form.Label>
                                                        <Form.Group controlId="field-observacao">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="observacao" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.observacao}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab>
                                        {(shareholders.length > 0) ?
                                        <Fragment>
                                        <style> 
                                            {"\
                                                #proposta-pessoa-pfpj .dependentes:before {\
                                                    background-color: #596d98;\
                                                }\
                                            "}
                                        </style>
                                        </Fragment>
                                        : '' }
                                        <Tab eventKey="dependentes" title={<Fragment><FiUsers />{(props.pfpj === 1) ? `Dependentes` : `Funcionários`}</Fragment>}>
                                            <div className="tab-body">
                                                <div className="dependentes">
                                                    {(shareholders.length > 0) ?
                                                        shareholders.map((shareholder, idx) => {
                                                        return (
                                                            <div className={`dependente`} id={`ref-dependentes-${idx}`} key={idx}>
                                                                <h4 style={{display: 'flex', justifyContent: 'space-between'}}>
                                                                    {(props.pfpj === 1) ? `Dependente` : `Funcionário`}{(shareholder.nomeDependente === '') ? '' : ` - ${shareholder.nomeDependente}`} 
                                                                    <Button variant="link" className="btn-excluir-dependentes" onClick={handleRemoveShareholder(idx)}>
                                                                        <AiOutlineDelete color="black" />
                                                                    </Button>
                                                                </h4>
                                                                <Row> 
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Label>CPF</Form.Label>
                                                                        <Form.Group controlId={`field-cpf-dependente`+idx}>
                                                                            <MaskedInput
                                                                                mask={[ /[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-' , /\d/, /\d/]}
                                                                                className={`form-control`}
                                                                                type="text"
                                                                                autoComplete="off" 
                                                                                placeholder="000.000.000-00"
                                                                                guide={false}
                                                                                value={shareholder.cpfDependente}
                                                                                onChange={handleShareholderChange(idx, 'cpfDependente')} 
                                                                                />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={9}>
                                                                        <Form.Label>Nome</Form.Label>
                                                                        <Form.Group controlId={`field-nome-dependente`+idx}>
                                                                            <Form.Control 
                                                                                autoComplete="off" 
                                                                                type="text" 
                                                                                value={shareholder.nomeDependente}
                                                                                onChange={handleShareholderChange(idx, 'nomeDependente')} 
                                                                                placeholder=""
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Label>Data de Nascimento</Form.Label>
                                                                        <Form.Group controlId={`field-data-nascimento-dependente`+idx}>
                                                                            <DatePicker
                                                                                customInput={
                                                                                    <MaskedInput
                                                                                    type="text"
                                                                                    autoComplete="off" 
                                                                                    placeholder="00/00/0000"
                                                                                    guide={false}
                                                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                                                    />
                                                                                }
                                                                                selected={shareholder.dataNascDependente}
                                                                                onChange={handleShareholderChange(idx, 'dataNascDependente')} 
                                                                                className="form-control"
                                                                                autoComplete="off" 
                                                                                locale={pt}
                                                                                dateFormat="P"
                                                                                />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Group controlId={`select-genero-dependente`+idx}>
                                                                            <Form.Label>
                                                                                Gênero 
                                                                            </Form.Label>                        
                                                                            <Select 
                                                                                className={`select-default`}
                                                                                options={generoList} 
                                                                                isClearable={false}
                                                                                isSearchable={false}
                                                                                onChange={handleShareholderChange(idx, 'generoDependente')} 
                                                                                value={shareholder.generoDependente.value > 0 ? generoList.find(obj => obj.value === shareholder.generoDependente.value) : ''} 
                                                                                placeholder=""
                                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                                styles={{
                                                                                    singleValue: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    input: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    placeholder: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    menu: (provided, state) => {
                                                                                        const borderRadius = 0;
                                                                                        const fontSize = 12;
                                                                                        return { ...provided, borderRadius, fontSize };
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Group controlId={`select-estado-civil-dependente`+idx}>
                                                                            <Form.Label>
                                                                                Estado Civil 
                                                                            </Form.Label>                        
                                                                            <Select 
                                                                                className={`select-default`}
                                                                                options={estadoCivilList} 
                                                                                isClearable={false}
                                                                                isSearchable={false}
                                                                                onChange={handleShareholderChange(idx, 'estadoCivilDependente')} 
                                                                                value={shareholder.estadoCivilDependente.value > 0 ? estadoCivilList.find(obj => obj.value === shareholder.estadoCivilDependente.value) : ''} 
                                                                                placeholder=""
                                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                                styles={{
                                                                                    singleValue: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    input: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    placeholder: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    menu: (provided, state) => {
                                                                                        const borderRadius = 0;
                                                                                        const fontSize = 12;
                                                                                        return { ...provided, borderRadius, fontSize };
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Label>RG</Form.Label>
                                                                        <Form.Group controlId={`field-rg-dependente`+idx}>
                                                                            <Form.Control 
                                                                                autoComplete="off" 
                                                                                type="text" 
                                                                                value={shareholder.rgDependente}
                                                                                onChange={handleShareholderChange(idx, 'rgDependente')} 
                                                                                placeholder=""
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Group controlId={`select-parentesco-dependente`+idx}>
                                                                            <Form.Label>
                                                                                Parentesco 
                                                                            </Form.Label>                        
                                                                            <Select 
                                                                                className={`select-default`}
                                                                                options={parentescosList} 
                                                                                isClearable={false}
                                                                                isSearchable={false}
                                                                                onChange={handleShareholderChange(idx, 'parentescoDependente')} 
                                                                                value={shareholder.parentescoDependente.value > 0 ? parentescosList.find(obj => obj.value === shareholder.parentescoDependente.value) : ''} 
                                                                                placeholder={``}
                                                                                noOptionsMessage={() => 'Sem resultados!'}
                                                                                styles={{
                                                                                    singleValue: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    input: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    placeholder: (provided, state) => {
                                                                                        const paddingLeft = 7;
                                                                                        return { ...provided, paddingLeft };
                                                                                    },
                                                                                    menu: (provided, state) => {
                                                                                        const borderRadius = 0;
                                                                                        const fontSize = 12;
                                                                                        return { ...provided, borderRadius, fontSize };
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Label>
                                                                            Valor (R$)
                                                                        </Form.Label>
                                                                        <Form.Group controlId={`valor-dependente`+idx}> 
                                                                            <CurrencyInput 
                                                                                    autoComplete="off" 
                                                                                    className={`form-control`}
                                                                                    value={shareholder.valorDependente}
                                                                                    onChange={handleShareholderChange(idx, 'valorDependente')} 
                                                                                    type="text" 
                                                                                    guide={false}
                                                                                    placeholder="0,00"
                                                                            />
                                                                        </Form.Group> 
                                                                    </Col>
                                                                    <Col xs={12} md={3}>
                                                                        <Form.Label>Observação</Form.Label>
                                                                        <Form.Group controlId={`field-obs-dependente`+idx}>
                                                                            <Form.Control 
                                                                                autoComplete="off" 
                                                                                value={shareholder.obsDependente}
                                                                                onChange={handleShareholderChange(idx, 'obsDependente')} 
                                                                                type="text" 
                                                                                placeholder=""
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    {/* <Col xs={12} md={3} style={{display: "table", alignSelf: "center"}}>
                                                                        <Button variant="danger" block className="btn-excluir-dependentes" onClick={handleRemoveShareholder(idx)}>Remover</Button>
                                                                    </Col> */}
                                                                </Row>
                                                                
                                                            </div>
                                                        )
                                                    })
                                                    : 
                                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                        <Button className="btn-add-dependente" onClick={handleAddShareholder}>Adicionar {(props.pfpj === 1) ? `Dependente` : `Funcionário`}</Button> 
                                                        <br></br>
                                                        <img src={add} alt="Home Background" style={{width: '25%'}} />
                                                    </div>}
                                                </div>
                                                {(shareholders.length > 0) ? <Button className="btn-add-dependente-plus" onClick={handleAddShareholder}><FiPlus /></Button> : ''}
                                            </div>
                                        </Tab>
                                        
                                        <Tab eventKey="comissao" title={<Fragment><FiDollarSign />Comissão</Fragment>}>
                                            <div className="tab-body">
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 1 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao1"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao1"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao1} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 2 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao2"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao2"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao2} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 1 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao3"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao3"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao3} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 4 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao4"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao4"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao4} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 5 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao5"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao5"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao5} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 6 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao6"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao6"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao6} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 7 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao7"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao7"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao7} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 8 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao8"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao8"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao8} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 9 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao9"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao9"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao9} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 10 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao10"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao10"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao10} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 11 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao11"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao11"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao11} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão 12 (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao12"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissao12"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissao12} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>
                                                            Comissão Vendedor (R$)
                                                        </Form.Label>
                                                        <Form.Group controlId="valor-comissao-vendedor"> 
                                                            <CurrencyInput 
                                                                    autoComplete="off" 
                                                                    className={`form-control`}
                                                                    name="valorComissaoVendedor"
                                                                    type="text" 
                                                                    guide={false}
                                                                    onBlur={handleChange}
                                                                    placeholder="0,00"
                                                                    defaultValue={values.valorComissaoVendedor} 
                                                            />
                                                        </Form.Group> 
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab>
                                        
                                        <Tab eventKey="pendencias" title={<Fragment><FiLayers />Pendências</Fragment>}>
                                            <div className="tab-body">
                                                <Row>
                                                    <Col xs={12} md={3}>
                                                        <Form.Label>Tipo</Form.Label>
                                                        <Form.Group controlId="field-tipo-pendencia">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="tipoPendencia" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.tipoPendencia}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={12} md={9}>
                                                        <Form.Label>Observação</Form.Label>
                                                        <Form.Group controlId="field-obs-pendencia">
                                                            <Form.Control 
                                                                autoComplete="off" 
                                                                name="obsPendencia" 
                                                                type="text" 
                                                                onBlur={handleChange} 
                                                                defaultValue={values.obsPendencia}
                                                                placeholder=""
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab>
                                    </Tabs> 
                                    <div>
                                        <hr></hr>
                                        <Button variant="secondary" size="sm" type="submit" onClick={() => validateForm().then((e) => setMsgErrors(e)) }>
                                            <FiSave /> {(id > 0) ? 'Editar' : 'Salvar'}
                                        </Button>
                                    </div>
                                </Form>                 
                            )}
                            </Formik>
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>    
    );
}

export default Proposta;