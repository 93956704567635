import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Dropdown, DropdownButton } from 'react-bootstrap';
import { FiMoreHorizontal} from 'react-icons/fi';
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import './styles.css';

const Metas = () => {

    const [loading, setLoading] = useState(true); 
    const [error, setError]     = useState(false);

    // Breadcrumb 
    const lista = ['Home', 'Relatórios', 'Metas' ];

    useEffect(() => {
        setLoading(false);
        setError(false);
    }, []);

    return (
        (error) ? <><div>Teste</div></> :
        <main>
            <section id="page">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Item eventKey="1">Ajuda</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        <hr />
                        <Card.Body>      
                           Page                  
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>    
    );
}

export default Metas;