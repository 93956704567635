import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { FaHome, FaEdit, FaList, FaChartPie } from 'react-icons/fa';
import { FiGrid, FiUsers, FiTrendingUp, FiLayers, FiArrowLeft, FiUser } from 'react-icons/fi';
import { authenticationService } from '../../_services';
import './styles.css';
import UserContext from '../../hooks/UserContext';

const Header = () => {

    const history = useHistory();
    const [toogleOpen, setToogleOpen] = useState(false);
    const doLogout = () => {
        authenticationService.logout();
        history.push('/');
    }

    const { user, perfil } = useContext(UserContext);
    
    return (
        <header>
            <div className="cabecalho">
                    <div className="userLoggedIn">
                        <FiUser />{user}
                    </div>
                    <div className="optionsUser">
                        <Link to="/" onClick={() => {
                                doLogout();
                                return;
                        }}>
                        </Link>
                    
                        <Link to="/" onClick={() => {
                                doLogout();
                                return;
                        }}>
                            <FiArrowLeft />
                        </Link>
                    </div>
            </div>
            <nav id="menu">
	           <label htmlFor="tm" id="toggle-menu"> 
                    <div id="nav-icon" className={ toogleOpen ? "open" : "" }>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </label>

                <input type="checkbox" id="tm" onClick={() => { setToogleOpen(!toogleOpen); }} />
                <ul className="main-menu clearfix">
                    
                    <li><Link to="/home"><FaHome />Home</Link></li>

                    {
                    perfil === "Admin" ? 
                    <li>
								
                        <div className="item-menu">
                            <FaEdit />Cadastro
                            <span className="drop-icon">▾</span>
                            <label title="Toggle Drop-down" className="drop-icon" htmlFor="menuCCadastros">▾</label>
                        </div>
                    
                        <input type="checkbox" id="menuCCadastros" />
                    
                        <ul className="sub-menu">
                            <li><Link to="/operadoras">Operadoras</Link></li>
                            <li><Link to="/classificacao">Classificação</Link></li>
                            <li><Link to="/administradora">Administradoras</Link></li>
                            <li><Link to="/valor-saude">Valor Saúde</Link></li>
                            <li><Link to="/valor-odonto">Valor Odonto</Link></li>
                            <li><Link to="/vendedores">Vendedores</Link></li>
                            <li><Link to="/metas">Metas</Link></li>
                            <li><Link to="/comissao">Comissões</Link></li>
                        </ul>
                    
                    </li>
                    : null
                    }

                    <li>
								
                        <div className="item-menu">
                            <FiUsers />Proposta
                            <span className="drop-icon">▾</span>
                            <label title="Toggle Drop-down" className="drop-icon" htmlFor="menuProposta">▾</label>
                        </div>
                    
                        <input type="checkbox" id="menuProposta" />
                    
                        <ul className="sub-menu">
                            <li><Link to="/proposta-pessoa-fisica">Pessoa Física</Link></li>
                            <li><Link to="/proposta-pessoa-juridica">Pessoa Jurídica</Link></li>
                        </ul>
                    
                    </li>

                    <li>
								
                        <div className="item-menu">
                            <FiGrid />Tabelas
                            <span className="drop-icon">▾</span>
                            <label title="Toggle Drop-down" className="drop-icon" htmlFor="menuTabela">▾</label>
                        </div>
                    
                        <input type="checkbox" id="menuTabela" />
                    
                        <ul className="sub-menu">
                            <li><Link to="/tabela-valor-saude">Plano de Saúde</Link></li>                            
                        </ul>
                    
                    </li>

                    <li><Link to="/simulador"><FiLayers />Simulador</Link></li>                            

                    {perfil === "Admin" ?
                    <li><Link to="/consultar-metas"><FiTrendingUp />Metas</Link></li>  
                    : null }

                    <li>
								
                        <div className="item-menu">
                            <FaList />Consultar
                            <span className="drop-icon">▾</span>
                            <label title="Toggle Drop-down" className="drop-icon" htmlFor="menuConsultar">▾</label>
                        </div>
                    
                        <input type="checkbox" id="menuConsultar" />
                    
                        <ul className="sub-menu">
                            <li><Link to="/propostas">Propostas</Link></li>
                        </ul>
                    
                    </li>

                    <li>
								
                        <div className="item-menu">
                            <FaChartPie />Relatórios
                            <span className="drop-icon">▾</span>
                            <label title="Toggle Drop-down" className="drop-icon" htmlFor="menuRelatorio">▾</label>
                        </div>
                    
                        <input type="checkbox" id="menuRelatorio" />
                    
                        <ul className="sub-menu">
                            <li><Link to="/relatorio-de-vendas">Vendas</Link></li>
                        </ul>

                    </li>

                </ul>

            </nav>

        </header>
    );
}

export default Header;