import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { FiUser, FiLock } from 'react-icons/fi';
import { authenticationService } from '../../_services';
import { FiLogIn } from 'react-icons/fi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import jwt from 'jsonwebtoken';
import './styles.css';
import { api } from '../../_services';
import Cookies from 'universal-cookie';
import UserContext from '../../hooks/UserContext';

const Login = (props) => {

    const cookies = new Cookies();
    const { setUser, setPerfil, setUuidz } = useContext(UserContext);
    const history = useHistory();
    
    const doLogout = () => {
        authenticationService.logout();
        history.push('/');
    }

    const [errorServer, setErrorServer] = useState('');
    const [send, setSend] = useState(false);
    const [enableValidation, setEnableValidation] = useState(false);
    const base = window.location.pathname.split('/')[1];
    
    if (authenticationService.currentTokenValue) {
    
        if(authenticationService.currentTokenValue !== null && authenticationService.currentTokenValue !== '') {
            
            const currentToken = authenticationService.currentTokenValue.substring(1,authenticationService.currentTokenValue.length - 1);
                    
            try {
                
                if(jwt.decode(currentToken) === null || !jwt.decode(currentToken)) {
                    
                    const validarToken = async () => {
                        await api.get("validarToken/",  { 
                            headers: { 
                                'Authorization': `Bearer ${currentToken}`,
                                'CONTEXT': base  
                            }
                        })
                        .then(response => {
                            if(!response.data)
                            {
                                doLogout();
                                return;
                            }
                        });
                    }

                    validarToken();

                }else {
                    
                    if (authenticationService.currentTokenValue !== '') {
                        history.push('/home');
                    }
                    
                }
            
            }catch(err) {
                doLogout();
                return;
            }                    

        }
        
    }

    return(
        <div className="login-container">
        <Container fluid>
            <div className="box-login">
                <div className="box-credenciais">

                    <div className="box-title">
                        <span className="box-subtitle">
                            Login
                        </span>
                    </div>

                    <div className="box">
                        <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize 
                            initialValues={{
                                login: '',
                                pass: ''
                            }}
                            validationSchema={ () => {
                                setErrorServer('');
                                setEnableValidation(true);
                                return Yup.object({
                                    login: Yup.string().required('Login é obrigatório!'),
                                    pass: Yup.string().required('Senha é obrigatório!')                                
                                })
                            }}
                            onSubmit={(values, { setStatus, setSubmitting, resetForm }) => {
                                setSend(true);
                                setStatus();
                                setErrorServer('');
                                authenticationService.login(values).then(
                                    res => {
                                        
                                        if(res !== '' && res !== undefined)
                                        {
                                            setUser(res.user);
                                            setPerfil(res.perfil);
                                            setUuidz(res.uuid);
                                            cookies.set('temporalyToken', res.token, { sameSite: 'strict', path: '/' , expires: new Date(new Date().getTime() + 86400000)});
                                            const { from } = props.location.state || { from: { pathname: "/home" } };
                                            props.history.push(from);
                                        }                                        
                                    },
                                    error => {
                                        if(localStorage.getItem('NETWORK-ERROR') !== null)
                                        {
                                            setErrorServer(localStorage.getItem('NETWORK-ERROR'));
                                            localStorage.removeItem('NETWORK-ERROR');
                                        }
                                        else
                                        {
                                            setErrorServer(error);
                                            setSubmitting(false);
                                            setStatus(error);
                                        } 
                                        resetForm({});                   
                                        setSend(false);
                                    }
                                )
                            }}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group controlId="field-login">
                                    <FiUser />
                                    <Form.Control 
                                        autoComplete="off" 
                                        name="login" 
                                        type="text" 
                                        onChange={handleChange} 
                                        value={values.login}
                                        isInvalid={!!errors.login}
                                        placeholder="Login" 
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.login}</Form.Control.Feedback>
                                    
                                </Form.Group>
                                <Form.Group controlId="field-pass">
                                    <FiLock />
                                    <Form.Control 
                                        autoComplete="off" 
                                        name="pass" 
                                        type="password" 
                                        onChange={handleChange} 
                                        value={values.pass}
                                        isInvalid={!!errors.pass}
                                        placeholder="Senha" 
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.pass}</Form.Control.Feedback>
                                </Form.Group>
                                <Button className="button btn btn-block btn-primary btn-acessar" type="submit" disabled={ (send) ? true : false }>
                                    {
                                    (send) ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{marginRight: "5px", marginBottom: "2px"}} /> 
                                           : <FiLogIn size={16} style={{marginRight: "5px", marginBottom: "2px"}} />
                                    }
                                    Acessar&nbsp;&nbsp;
                                </Button>
                                <div className="error-validacao">{errorServer}</div>
                            </Form>
                            )}
                        </Formik>
                        <div className="version">CS Seguros<br></br><small><b>Versão 0.7</b></small></div>
                    </div>
                </div>
            </div>
        </Container>
        </div>
    )
}

export default Login;