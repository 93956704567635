import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Spinner, Modal, Dropdown, DropdownButton, Toast } from 'react-bootstrap';
import { FiSave, FiEdit, FiTrash, FiSearch, FiDelete, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { FaCaretRight, FaCaretLeft, FaStepBackward, FaStepForward, FaTimes, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Swal from "sweetalert2";
import Carregando from '../../../_components/Carregando';
import Pagination from "react-js-pagination";
import Select from 'react-select';
import Arvore from '../../../_components/Arvore';
import ExportPDF from './exportPDF';
import { saveAs } from "file-saver";
import { pdf } from '@react-pdf/renderer';
import './styles.css';
import NoResult from '../../Errors/noResult';
import PageError from '../../Errors/pageError';

const Classificacao = () => {

    const [txtChanged       , setTxtChanged]       = useState('');
    const [selectChanged    , setSelectChanged]    = useState('');
    const [oldTxtChanged    , setOldTxtChanged]    = useState('');
    const [hasResult        , setHasResult]        = useState(false);
    const [loadingSearch    , setLoadingSearch]    = useState(false);
    const [classif          , setClassif]          = useState([]);
    const [operadoras       , setOperadoras]       = useState([]);
    const [total            , setTotal]            = useState(0);
    const [page             , setPage]             = useState(1);
    const [loading          , setLoading]          = useState(true);
    const [error            , setError]            = useState(false);
    const [id               , setId]               = useState(0);
    const [descOperadora    , setDescOperadora]    = useState('');
    const [idOperadora      , setIdOperadora]      = useState(0);
    const [descricao        , setDescricao]        = useState('');
    const [CCOenf           , setCCOenf]           = useState(0);
    const [CCOenfComParto   , setCCOenfComParto]   = useState(0);
    const [CCOenfSemParto   , setCCOenfSemParto]   = useState(0);
    const [CCOenfComDental  , setCCOenfComDental]  = useState(0);
    const [CCOenfSemDental  , setCCOenfSemDental]  = useState(0);
    const [CCOapto          , setCCOapto]          = useState(0);
    const [CCOaptoComParto  , setCCOaptoComParto]  = useState(0);
    const [CCOaptoSemParto  , setCCOaptoSemParto]  = useState(0);
    const [CCOaptoComDental , setCCOaptoComDental] = useState(0);
    const [CCOaptoSemDental , setCCOaptoSemDental] = useState(0);
    const [SCOenf           , setSCOenf]           = useState(0);
    const [SCOenfComParto   , setSCOenfComParto]   = useState(0);
    const [SCOenfSemParto   , setSCOenfSemParto]   = useState(0);
    const [SCOenfComDental  , setSCOenfComDental]  = useState(0);
    const [SCOenfSemDental  , setSCOenfSemDental]  = useState(0);
    const [SCOapto          , setSCOapto]          = useState(0);
    const [SCOaptoComParto  , setSCOaptoComParto]  = useState(0);
    const [SCOaptoSemParto  , setSCOaptoSemParto]  = useState(0);
    const [SCOaptoComDental , setSCOaptoComDental] = useState(0);
    const [SCOaptoSemDental , setSCOaptoSemDental] = useState(0);
    const [ambulatorial     , setambulatorial]     = useState(0);
    const [labelBotao       , setLabelBotao]       = useState('Salvar');
    const [isPdf            , setIsPdf]            = useState(false);
    const [isXLS            , setIsXLS]            = useState(false);    
    const [enableValidation , setEnableValidation] = useState(false);
    const [modalShow        , setModalShow]        = useState(false);
    const mountedRef                               = useRef(true);

    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Classificação' ];

    function escapeRegexCharacters(str)
    {
        return str.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&');
    }

    function onTextChanged(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        if(oldTxtChanged !== valor) {
            setTxtChanged(valor);
            setOldTxtChanged(valor);
            setLoadingSearch(true);
            loadClassificacoes(1, valor, selectChanged);
        }                    
    }

    function onSelectChanged(e)
    {
        if(e !== null) {
            const valorSelect = e.value;
            setSelectChanged(valorSelect);
            setLoadingSearch(true);
            loadClassificacoes(1, txtChanged, valorSelect);
        }else {
            setLoadingSearch(true);
            setSelectChanged('');
            loadClassificacoes(1, txtChanged, '');            
        }
    }


    const loadOperadoras = async () => {
        
        await api.get('getAllOperadoras/', {
            params: {
                page : 1,
                t    : '',
                s    : ''
            },
            headers: authHeader()
        }).then( async (response) => {

            setOperadoras(response.data.operadoras.map(e => ({ value: e.id, label: e.descricao})));

        }).catch(erros => {

            setError(true);

        });           

    }

    const loadClassificacoes = async (pagina = 1, txtChanged = '', selectChanged = '') => {
        
        await api.get('classificacao/', {
            params: {
                page : pagina,
                t    : txtChanged,
                s    : selectChanged
            },
            headers: authHeader()
        }).then((response) => {

            if(mountedRef.current) {
                setHasResult(true);
                setClassif(response.data.classificacao);
                setTotal(parseInt(response.data.XCountRegister));
                setPage(pagina);
                if((!!txtChanged === false && !!selectChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                    setHasResult(false);
                }
            }

        }).catch(errors => {
            
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                setLoading(false);
                setLoadingSearch(false);
            }

        }); 

    }

    const carregarClassificacao = (props) => {
        setId(props.id); 
        setDescOperadora(props.descOperadora);
        setIdOperadora(props.idOperadora);
        setDescricao(props.descricao);
        setCCOenf(props.CCOenf);
        setCCOenfComParto(props.CCOenfComParto);
        setCCOenfSemParto(props.CCOenfSemParto);
        setCCOenfComDental(props.CCOenfComDental);
        setCCOenfSemDental(props.CCOenfSemDental);
        setCCOapto(props.CCOapto);
        setCCOaptoComParto(props.CCOaptoComParto);
        setCCOaptoSemParto(props.CCOaptoSemParto);
        setCCOaptoComDental(props.CCOaptoComDental);
        setCCOaptoSemDental(props.CCOaptoSemDental);
        setSCOenf(props.SCOenf);
        setSCOenfComParto(props.SCOenfComParto);
        setSCOenfSemParto(props.SCOenfSemParto);
        setSCOenfComDental(props.SCOenfComDental);
        setSCOenfSemDental(props.SCOenfSemDental);
        setSCOapto(props.SCOapto);
        setSCOaptoComParto(props.SCOaptoComParto);
        setSCOaptoSemParto(props.SCOaptoSemParto);
        setSCOaptoComDental(props.SCOaptoComDental);
        setSCOaptoSemDental(props.SCOaptoSemDental);
        setambulatorial(props.ambulatorial);
        setLabelBotao('Alterar');
        setModalShow(true);
        window.scrollTo(0, 0);
    }

    const cadastrarInfo = async (props, info)  => {
        
        setModalShow(false);
        
        setLoading(true);
    
        let data = JSON.stringify({
            fk_operadora     : props.idOperadora,
            descricao        : props.descricao,
            ccpe_enfermaria  : props.CCOenf,
            ccpe_com_parto   : props.CCOenfComParto,
            ccpe_sem_parto   : props.CCOenfSemParto,
            ccpe_com_dental  : props.CCOenfComDental,
            ccpe_sem_dental  : props.CCOenfSemDental,
            ccpa_apartamento : props.CCOapto,
            ccpa_com_parto   : props.CCOaptoComParto,
            ccpa_sem_parto   : props.CCOaptoSemParto,
            ccpa_com_dental  : props.CCOaptoComDental,
            ccpa_sem_dental  : props.CCOaptoSemDental,
            scpe_enfermaria  : props.SCOenf,
            scpe_com_parto   : props.SCOenfComParto,
            scpe_sem_parto   : props.SCOenfSemParto,
            scpe_com_dental  : props.SCOenfComDental,
            scpe_sem_dental  : props.SCOenfSemDental,
            scpa_apartamento : props.SCOapto,
            scpa_com_parto   : props.SCOaptoComParto,
            scpa_sem_parto   : props.SCOaptoSemParto,
            scpa_com_dental  : props.SCOaptoComDental,
            scpa_sem_dental  : props.SCOaptoSemDental,
            ambulatorial     : props.ambulatorial
        });
        
        const requisicao = (props.id > 0) ? api.put : api.post;
        
        const url = (props.id > 0) ? `/classificacao/${props.id}` : `/classificacao`;
        
        await requisicao(url, data, {headers: authHeader()})
        .then(response => {

            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    setHasResult(true);

                    const resultado = response.data.classificacao[0];
                    
                    if(props.id > 0) {
                        const updateClassificacao = classif.filter((item) => item.id !== props.id);
                        
                        setClassif([...updateClassificacao, {id               :  resultado.id, 
                                                            idOperadora       :  resultado.idOperadora,
                                                            descOperadora     :  resultado.descOperadora, 
                                                            descricao         :  resultado.descricao,
                                                            CCOenf            :  resultado.CCOenf,
                                                            CCOenfComParto    :  resultado.CCOenfComParto,
                                                            CCOenfSemParto    :  resultado.CCOenfSemParto,
                                                            CCOenfComDental   :  resultado.CCOenfComDental,
                                                            CCOenfSemDental   :  resultado.CCOenfSemDental,
                                                            CCOapto           :  resultado.CCOapto,
                                                            CCOaptoComParto   :  resultado.CCOaptoComParto,
                                                            CCOaptoSemParto   :  resultado.CCOaptoSemParto,
                                                            CCOaptoComDental  :  resultado.CCOaptoComDental,
                                                            CCOaptoSemDental  :  resultado.CCOaptoSemDental,
                                                            SCOenf            :  resultado.SCOenf,
                                                            SCOenfComParto    :  resultado.SCOenfComParto,
                                                            SCOenfSemParto    :  resultado.SCOenfSemParto,
                                                            SCOenfComDental   :  resultado.SCOenfComDental,
                                                            SCOenfSemDental   :  resultado.SCOenfSemDental,
                                                            SCOapto           :  resultado.SCOapto,
                                                            SCOaptoComParto   :  resultado.SCOaptoComParto, 
                                                            SCOaptoSemParto   :  resultado.SCOaptoSemParto,
                                                            SCOaptoComDental  :  resultado.SCOaptoComDental,
                                                            SCOaptoSemDental  :  resultado.SCOaptoSemDental,
                                                            ambulatorial      :  resultado.ambulatorial} ]);
                        Swal.fire(
                            'Sucesso!',
                            'Operadora alterada com sucesso!',
                            'success'
                        );
                        
                    } else {
                        setClassif([...classif, {id                :  resultado.id, 
                                                idOperadora       :  resultado.idOperadora,
                                                descOperadora     :  resultado.descOperadora, 
                                                descricao         :  resultado.descricao,
                                                CCOenf            :  resultado.CCOenf,
                                                CCOenfComParto    :  resultado.CCOenfComParto,
                                                CCOenfSemParto    :  resultado.CCOenfSemParto,
                                                CCOenfComDental   :  resultado.CCOenfComDental,
                                                CCOenfSemDental   :  resultado.CCOenfSemDental,
                                                CCOapto           :  resultado.CCOapto,
                                                CCOaptoComParto   :  resultado.CCOaptoComParto,
                                                CCOaptoSemParto   :  resultado.CCOaptoSemParto,
                                                CCOaptoComDental  :  resultado.CCOaptoComDental,
                                                CCOaptoSemDental  :  resultado.CCOaptoSemDental,
                                                SCOenf            :  resultado.SCOenf,
                                                SCOenfComParto    :  resultado.SCOenfComParto,
                                                SCOenfSemParto    :  resultado.SCOenfSemParto,
                                                SCOenfComDental   :  resultado.SCOenfComDental,
                                                SCOenfSemDental   :  resultado.SCOenfSemDental,
                                                SCOapto           :  resultado.SCOapto,
                                                SCOaptoComParto   :  resultado.SCOaptoComParto, 
                                                SCOaptoSemParto   :  resultado.SCOaptoSemParto,
                                                SCOaptoComDental  :  resultado.SCOaptoComDental,
                                                SCOaptoSemDental  :  resultado.SCOaptoSemDental,
                                                ambulatorial      :  resultado.ambulatorial} ]);
                        
                        Swal.fire(
                            'Sucesso!',
                            'Classificação cadastrada com sucesso!',
                            'success'
                        );
                        setPage(1);
                        
                        setTotal(parseInt(response.data.XCountRegister));  
                        
                    }

                    limparInfo();
                                    
                }
                
            }

        }).catch(error => {

            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {
        
            if(mountedRef.current) {
                setLoading(false);
            }
        
        });

    }
    
    const removerClassificacao = async (props) => {
        
        setLoading(true);
        
        await api.delete(`/classificacao/${props.id}`, {headers: authHeader()})
        .then(response => {
            
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }
                    Swal.fire(
                        'Atenção!',
                        `${resultadoTexto}`,
                        'warning'
                    );
                    return false;                 
                }
                else
                {
                    Swal.fire(
                        'Sucesso!',
                        'Classificação removida com sucesso!',
                        'success'
                    );
                    setHasResult(true);
                    setClassif(response.data.classificacao);
                    setTotal(parseInt(response.data.XCountRegister));
                    setTxtChanged('');
                    setPage(1);
                    clearFilters();
                    if((!!txtChanged === false && !!selectChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                        setHasResult(false);
                    }
                }
            
            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {
                setLoading(false);
            }

        });
        
    }

    const excluirClassificacao = (props) => {
        
        limparInfo();
        
        Swal.fire({
            title: `Deseja excluir a classificação ${props.descricao} atrelada a operadora ${props.descOperadora}?`,
            text: `Está operação não poderá ser desfeita!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {

                removerClassificacao(props);
               
            }
        });

    }

    const limparInfo = () => {
        
        var checked = document.querySelectorAll('.form-check-input');
        for (let index = 0; index < checked.length; index++) { checked[index].removeAttribute('checked'); }
        setEnableValidation(false);
        setId(0);
        setDescOperadora('0');
        setIdOperadora(0);
        setDescricao('');
        setCCOenf(0);
        setCCOenfComParto(0);
        setCCOenfSemParto(0);
        setCCOenfComDental(0);
        setCCOenfSemDental(0);
        setCCOapto(0);
        setCCOaptoComParto(0);
        setCCOaptoSemParto(0);
        setCCOaptoComDental(0);
        setCCOaptoSemDental(0);
        setSCOenf(0);
        setSCOenfComParto(0);
        setSCOenfSemParto(0);
        setSCOenfComDental(0);
        setSCOenfSemDental(0);
        setSCOapto(0);
        setSCOaptoComParto(0);
        setSCOaptoSemParto(0);
        setSCOaptoComDental(0);
        setSCOaptoSemDental(0);
        setambulatorial(0);
        setLabelBotao('Salvar');

    }

    const clearFilters = () => {
        setTxtChanged('');
        setSelectChanged('');
    }

    const carregarPDF = async () => {
        
        setIsPdf(true);
        
        await api.get('getAllClassificacoes/', { 
            params: {
                page : 1,
                t    : txtChanged,
                s    : selectChanged
            },
            headers: authHeader()
        }).then( async (response) => {
        
            if(mountedRef.current) {   
                const blob = await pdf(<ExportPDF dados={response.data.classificacoes} />).toBlob();
                saveAs(blob, "Listagem das Classificações.pdf");
            }
        
        }).catch(error => {
        
            setError(true);
        
        }).finally(e => {
        
            if(mountedRef.current) {      
                setIsPdf(false);
            }
        
        });
        
    }

    const carregarXLS = async () => {
        
        setIsXLS(true);
        
        await api.get('getAllClassificacoes/', { 
            params: {
                page : 1,
                t    : txtChanged,
                s    : selectChanged
            },
            headers: authHeader()
        }).then( async (response) => {
        
            if(mountedRef.current) {
                const data = response.data.classificacoes.map((row) => ({
                    operadora: row.descOperadora,
                    descricao: row.descricao
                }));
                const csvData = objectToCSV(data);
                download(csvData);
            }

        }).catch(errors => {
        
            setError(true);
        
        }).finally(e => {
        
            if(mountedRef.current) {
                setIsXLS(false);        
            }  
        
        });

    }

    const objectToCSV = (data) => {
        const csvRows = [];
        const headers =  Object.keys(data[0]);
        csvRows.push(headers.join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    }

    const download = (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Listagem das Classificações.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const exibirModal = () => {
        limparInfo();
        setEnableValidation(false);
        setModalShow(true);
    }

    useEffect(() => {

        loadOperadoras();
        loadClassificacoes();
        return () => { mountedRef.current = false }

    }, []);

    return(
        (error) ? <PageError /> :
        <main>
            <section id="classificacao">
            <Container fluid>
                <div className="toast-group">
                    <Toast style={{display: isPdf ? 'block' : 'none'}} >
                            <Toast.Header>
                                <strong className="mr-auto">Aguarde!</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> Exportando PDF
                            </Toast.Body>
                        </Toast> 
                        <Toast style={{display: isXLS ? 'block' : 'none'}}>
                        <Toast.Header>
                            <strong className="mr-auto">Aguarde!</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            /> Exportando CSV
                        </Toast.Body>
                    </Toast>                
                </div>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Item eventKey="1" onClick={exibirModal}><FiPlusCircle />&nbsp;&nbsp;Adicionar</Dropdown.Item>
                                    {
                                    (operadoras.length === 0) ? <></> :
                                    <>
                                        <Dropdown.Item eventKey="2" onClick={carregarPDF}><FaFilePdf style={{color:'red'}} />&nbsp;&nbsp;Exportar PDF</Dropdown.Item>
                                        <Dropdown.Item eventKey="3" onClick={carregarXLS}><FaFileExcel style={{color:'green'}} />&nbsp;&nbsp;Exportar CSV</Dropdown.Item>
                                    </>
                                    }
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>
                        <Modal
                            animation={false}
                            show={modalShow}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={()=> false}
                            >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                {labelBotao === 'Alterar' ? 'Alterar' : 'Adicionar'} Classificação
                                </Modal.Title>
                            </Modal.Header>  
                            <Formik
                                validateOnChange={enableValidation}
                                validateOnBlur={enableValidation}
                                enableReinitialize={true} 
                                initialValues={{
                                    id               : id,
                                    descOperadora    : descOperadora,
                                    idOperadora      : idOperadora,
                                    descricao        : descricao,
                                    CCOenf           : CCOenf, 
                                    CCOenfComParto   : CCOenfComParto,
                                    CCOenfSemParto   : CCOenfSemParto,
                                    CCOenfComDental  : CCOenfComDental,
                                    CCOenfSemDental  : CCOenfSemDental,
                                    CCOapto          : CCOapto,
                                    CCOaptoComParto  : CCOaptoComParto,
                                    CCOaptoSemParto  : CCOaptoSemParto,
                                    CCOaptoComDental : CCOaptoComDental,
                                    CCOaptoSemDental : CCOaptoSemDental,
                                    SCOenf           : SCOenf,
                                    SCOenfComParto   : SCOenfComParto,
                                    SCOenfSemParto   : SCOenfSemParto,
                                    SCOenfComDental  : SCOenfComDental,
                                    SCOenfSemDental  : SCOenfSemDental,
                                    SCOapto          : SCOapto,
                                    SCOaptoComParto  : SCOaptoComParto,
                                    SCOaptoSemParto  : SCOaptoSemParto,
                                    SCOaptoComDental : SCOaptoComDental,
                                    SCOaptoSemDental : SCOaptoSemDental,
                                    ambulatorial     : ambulatorial,
                                }}
                                validationSchema={ () => {
                                    setEnableValidation(true);  
                                    return Yup.object({
                                    idOperadora: Yup.number()
                                            .positive('Campo deve ser preenchido!')
                                            .required('Campo deve ser preenchido!'),   
                                    descricao: Yup.string()
                                            .required('Campo deve ser preenchido!')
                                            .matches(
                                            /^[A-Za-zÀ-ÿ0-9 ()_']+$/,
                                            "Não deve conter caracteres especiais"
                                            ),                                
                                })}}
                                onSubmit={cadastrarInfo}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    resetForm,
                                    setFieldValue,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Modal.Body>
                                    <Form.Control 
                                        autoComplete="off" 
                                        readOnly={true}
                                        name="id" 
                                        type="hidden" 
                                        value={values.id}                                        
                                    />
                                    <Form.Group controlId="select-operadora">
                                        <Form.Label>Operadora*</Form.Label>                        
                                        <Select 
                                            className={`select-default ${!!errors.idOperadora ? 'is-invalid' : ''}`}
                                            options={operadoras} 
                                            isClearable={false}
                                            isSearchable={true}
                                            onChange={ e => setFieldValue("idOperadora", e.value) }
                                            value={values.idOperadora > 0 ? operadoras.find(obj => obj.value === values.idOperadora) : ''} 
                                            name="idOperadora" 
                                            placeholder=""
                                            noOptionsMessage={() => 'Sem resultados!'}
                                            styles={{
                                                singleValue: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                input: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                placeholder: (provided, state) => {
                                                    const paddingLeft = 7;
                                                    return { ...provided, paddingLeft };
                                                },
                                                menu: (provided, state) => {
                                                    const borderRadius = 0;
                                                    const fontSize = 12;
                                                    return { ...provided, borderRadius, fontSize };
                                                },
                                            }}
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.idOperadora}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Label>Descrição*</Form.Label>
                                    <Form.Group controlId="field-classificacao">
                                        <Form.Control 
                                            autoComplete="off" 
                                            name="descricao" 
                                            type="text" 
                                            onChange={handleChange} 
                                            value={values.descricao}
                                            isInvalid={!!errors.descricao}
                                            placeholder=""
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.descricao}</Form.Control.Feedback>
                                    </Form.Group>
                                    <Row>
                                        <Col lg="3">
                                            <Form.Group>
                                                <Form.Label>Com Co-participação</Form.Label>
                                                <Form.Check
                                                    type='checkbox'
                                                    name="CCOenf"
                                                    onChange={(ev) => {(ev.target.checked) ? setFieldValue('CCOenf', '1') : setFieldValue('CCOenf', '0')}}
                                                    value={values.CCOenf}
                                                    checked={parseInt(values.CCOenf.toString()) === 1 ? true : false} 
                                                    label='Enfermaria'
                                                    id='CCOenf'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="3">
                                            <Form.Group>  
                                                <Form.Label>Com Co-participação</Form.Label>
                                                <Form.Check
                                                    type='checkbox'
                                                    name="CCOapto"
                                                    onChange={(ev) => {(ev.target.checked) ? setFieldValue('CCOapto', '1') : setFieldValue('CCOapto', '0')}}
                                                    value={values.CCOapto}
                                                    checked={parseInt(values.CCOapto.toString()) === 1 ? true : false} 
                                                    label='Apartamento'
                                                    id='cco-apto'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="3">
                                            <Form.Group>
                                                <Form.Label>Sem Co-participação</Form.Label>
                                                <Form.Check
                                                    type='checkbox'
                                                    name="SCOenf"
                                                    onChange={(ev) => {(ev.target.checked) ? setFieldValue('SCOenf', '1') : setFieldValue('SCOenf', '0')}}
                                                    value={values.SCOenf}
                                                    checked={parseInt(values.SCOenf.toString()) === 1 ? true : false}  
                                                    label='Enfermaria'
                                                    id='sco-enf'
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg="3">
                                            <Form.Group>  
                                                <Form.Label>Sem Co-participação</Form.Label>
                                                <Form.Check
                                                    type='checkbox'
                                                    name="SCOapto"
                                                    onChange={(ev) => {(ev.target.checked) ? setFieldValue('SCOapto', '1') : setFieldValue('SCOapto', '0')}}
                                                    value={values.SCOapto}
                                                    checked={parseInt(values.SCOapto.toString()) === 1 ? true : false}
                                                    label='Apartamento'
                                                    id='sco-apto'
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <Form.Group>
                                                <Form.Label>Ambulatorial</Form.Label>
                                                <Form.Check
                                                    type='checkbox'
                                                    name="ambulatorial"
                                                    onChange={(ev) => {(ev.target.checked) ? setFieldValue('ambulatorial', '1') : setFieldValue('ambulatorial', '0')}}
                                                    value={values.ambulatorial}
                                                    checked={parseInt(values.ambulatorial.toString()) === 1 ? true : false}
                                                    label='Ambulatorial'
                                                    id='ambulatorial'
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" size="sm" type="submit">
                                            <FiSave /> {labelBotao}
                                        </Button>
                                        <Button variant="primary" 
                                                size="sm" 
                                                onClick={() => {
                                                    resetForm();
                                                    limparInfo();
                                                }} 
                                                type="reset">
                                            <FiDelete /> Limpar
                                        </Button>
                                        <Button variant="secondary" 
                                                className="padrao-cancelar"
                                                size="sm" 
                                                onClick={() => setModalShow(false)}>
                                            <FaTimes /> Fechar
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                                )}
                            </Formik>    
                            </Modal>   
                            {
                            (hasResult) 
                            ?
                                <Fragment>
                                    <Table responsive hover size="md">
                                    <thead>
                                        <tr>
                                            <th style={{width:"200px"}}>
                                                <Select 
                                                    className="basic-single"
                                                    options={operadoras} 
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Operadora"
                                                    value={parseInt(selectChanged) > 0 ? operadoras.find(obj => obj.value === parseInt(selectChanged)) : ''} 
                                                    menuPortalTarget={document.body}
                                                    noOptionsMessage={() => 'Sem resultados!'}
                                                    onChange={onSelectChanged}
                                                    styles={{
                                                        menu: (provided, state) => {
                                                            const borderRadius = 0;
                                                            const fontSize = 12;
                                                            return { ...provided, borderRadius, fontSize };
                                                        },
                                                    }}
                                                />
                                            </th>
                                            <th>
                                            <Form.Group controlId="field-search">
                                            {loadingSearch ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        /> : <FiSearch />}
                                            <Form.Control 
                                                autoComplete="off" 
                                                type="text"
                                                placeholder="Classificação"
                                                aria-label="Classificação"
                                                maxLength={20}
                                                value={txtChanged}
                                                onChange={(e) => {
                                                    setTxtChanged(e.target.value.trim());
                                                    if(e.target.value === '') {
                                                        onTextChanged(e);
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if(e.key === 'Enter') {
                                                        onTextChanged(e);
                                                    }
                                                }}
                                            />
                                            </Form.Group>
                                            </th>
                                            <th style={{width:"15px"}}></th>
                                            <th style={{width:"15px"}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {(classif.length === 0) ? <><tr><td colSpan={4} style={{textAlign: 'center'}}>Nenhum resultado encontrado!</td></tr></> : <>
                                    {
                                            classif.sort((a,b) =>  +(a.descricao.toLowerCase() > b.descricao.toLowerCase()) || +(a.descricao.toLowerCase() === b.descricao.toLowerCase()) - 1).slice(0, 10).map((props) => {
                                                return (
                                                <tr key={props.id}>
                                                    <td>{props.descOperadora}</td>
                                                <td>{props.descricao}</td>
                                                    <td>
                                                        <button className="botao" onClick={() => carregarClassificacao(props)}>
                                                            <FiEdit style={{color:'#638c5e', cursor: 'pointer'}} />
                                                        </button>
                                                    </td> 
                                                    <td>
                                                        <button className="botao" onClick={() => excluirClassificacao(props)}>
                                                            <FiTrash style={{color:'#c94646', cursor: 'pointer'}} />                                            
                                                        </button>
                                                    </td>
                                                </tr>
                                                )
                                            })
                                        }
                                        </>}
                                    </tbody>
                                    
                                    </Table>
                                    {
                                        (classif.length === 0) ? <></> :
                                        <>
                                        <Pagination
                                        nextPageText={<FaCaretRight size={19} />}
                                        prevPageText={<FaCaretLeft size={19}  />}
                                        lastPageText={<FaStepForward />}
                                        firstPageText={<FaStepBackward />}
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={5}
                                        onChange={(ev) => loadClassificacoes(ev, txtChanged, selectChanged)}
                                        />
                                        </>
                                    } 
                                </Fragment>
                            :
                                !hasResult && !loading ? <NoResult /> : 'Loading...'
                            }
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    );
}

export default Classificacao;