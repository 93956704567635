import React from 'react';
import { useParams } from "react-router-dom";
import Proposta from '../_components/proposta';


const PropostaPessoaFisica = () => {
    
    // Breadcrumb 
    const lista = ['Home', 'Proposta', 'Pessoa Física'];

    let { idContract } = useParams();

    return (
        <>
            <Proposta lista={lista} idContract={idContract} pfpj={1} />
        </>  
    );
}

export default PropostaPessoaFisica;