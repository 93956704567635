import React from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import './styles.css';

const Arvore = (props) =>
{

    const total = props.lista.length;

    const estiloArvore = {
        color:"#4a4a4a",
        fontWeight:400,
        fontStyle: "italic",
        fontSize:".8em"
    };

    return(

        <Breadcrumb>
        {        
        props.lista.map((props, index) => { 
            if(index === total-1)
            {
                return (<Breadcrumb.Item key={index} style={estiloArvore} active><b>{props}</b></Breadcrumb.Item>);
            }
            return (<Breadcrumb.Item key={index} style={estiloArvore} active>{props}</Breadcrumb.Item>);
        })
        }
        </Breadcrumb>
    )
}

export default Arvore;