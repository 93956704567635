import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, DropdownButton, Table } from 'react-bootstrap';
import { FiSearch, FiMoreHorizontal, FiDelete } from 'react-icons/fi';
import Select from 'react-select';
import { api } from '../../_services';
import { authHeader } from '../../_helpers/auth-header';
import Arvore from '../../_components/Arvore';
import Carregando from '../../_components/Carregando';
import Spinner from 'react-bootstrap/Spinner';
import Swal from "sweetalert2";
import { convertCurrencyBr } from '../../utils/global';
import './styles.css';
import NoResult from '../Errors/noResult';

const Simulador = () => {

    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(false);
    const [operadoras, setOperadoras] = useState([]);
    const [showLoadingClassificacao, setShowLoadingClassificacao] = useState(false);
    const [classificacao, setClassificacao] = useState([]);
    const [simulador, setSimulador] = useState([]);
    const [fk_operadora, setFkOperadora] = useState([]);
    const [fk_operadoraValidate, setFkOperadoraValidate] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);    
    const [fk_classificacao, setFkClassificacao] = useState([]);
    const [coParticipacao, setCoParticipacao] = useState(1);
    const [fe1, setFe1] = useState('');
    const [fe2, setFe2] = useState('');
    const [fe3, setFe3] = useState('');
    const [fe4, setFe4] = useState('');
    const [fe5, setFe5] = useState('');
    const [fe6, setFe6] = useState('');
    const [fe7, setFe7] = useState('');
    const [fe8, setFe8] = useState('');
    const [fe9, setFe9] = useState('');
    const [fe10, setFe10] = useState('');
    const mountedRef = useRef(true);

    const lista = ['Home', 'Simulador'];

    const faixa_etaria = [];
    const faixa_etaria_value = [];

    faixa_etaria.push({ key: '0_a_18_anos',  value: '0 a 18 anos' });
    faixa_etaria.push({ key: '19_a_23_anos', value: '19 a 23 anos' });
    faixa_etaria.push({ key: '24_a_28_anos', value: '24 a 28 anos' });
    faixa_etaria.push({ key: '29_a_33_anos', value: '29 a 33 anos' });
    faixa_etaria.push({ key: '34_a_38_anos', value: '34 a 38 anos' });
    faixa_etaria.push({ key: '39_a_43_anos', value: '39 a 43 anos' });
    faixa_etaria.push({ key: '44_a_48_anos', value: '44 a 48 anos' });
    faixa_etaria.push({ key: '49_a_53_anos', value: '49 a 53 anos' });
    faixa_etaria.push({ key: '54_a_58_anos', value: '54 a 58 anos' });
    faixa_etaria.push({ key: '59_anos_+',    value: '59 anos +' });
    
    faixa_etaria_value.push({ key: '0_a_18_anos',  value: fe1 });
    faixa_etaria_value.push({ key: '19_a_23_anos', value: fe2 });
    faixa_etaria_value.push({ key: '24_a_28_anos', value: fe3 });
    faixa_etaria_value.push({ key: '29_a_33_anos', value: fe4 });
    faixa_etaria_value.push({ key: '34_a_38_anos', value: fe5 });
    faixa_etaria_value.push({ key: '39_a_43_anos', value: fe6 });
    faixa_etaria_value.push({ key: '44_a_48_anos', value: fe7 });
    faixa_etaria_value.push({ key: '49_a_53_anos', value: fe8 });
    faixa_etaria_value.push({ key: '54_a_58_anos', value: fe9 });
    faixa_etaria_value.push({ key: '59_anos_+',    value: fe10 });

    const handleSubmit = async (e) => {
        
        setLoading(true);
        
        if(fk_operadora.length === 0) {
            setFkOperadoraValidate(true);
            setLoading(false);
            return false;
        }
       
        if((fe1  === '' || parseInt(fe1) === 0) &&
           (fe2  === '' || parseInt(fe2) === 0) &&
           (fe3  === '' || parseInt(fe3) === 0) &&
           (fe4  === '' || parseInt(fe4) === 0) &&
           (fe5  === '' || parseInt(fe5) === 0) &&
           (fe6  === '' || parseInt(fe6) === 0) &&
           (fe7  === '' || parseInt(fe7) === 0) &&
           (fe8  === '' || parseInt(fe8) === 0) &&
           (fe9  === '' || parseInt(fe9) === 0) &&
           (fe10 === '' || parseInt(fe10) === 0)) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                html: 'Insira algum valor na faixa etária!',
            });
            return false;
        }

        if(fe1 < 0 || fe2 < 0 || fe3 < 0 || fe4 < 0 || fe5 < 0 || fe6 < 0 || fe7 < 0 || fe8 < 0 || fe9 < 0 || fe10 < 0) {
            setLoading(false);
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                html: 'Insira valor maior do que zero!',
            });
            return false;
        }

        setIsFiltered(true);

        let dados = {
            participacao: coParticipacao,
            operadora: fk_operadora.map(e => e.value),
            classificacao: fk_classificacao.map(e => e.value) || [],
            fe1: fe1,
            fe2: fe2,
            fe3: fe3,
            fe4: fe4,
            fe5: fe5,
            fe6: fe6,
            fe7: fe7,
            fe8: fe8,
            fe9: fe9,
            fe10: fe10,
        };

        await api.get('simulador/', {
            params: dados,
            headers: authHeader()
        }).then((response) => {
            
            var simulador = response.data.reduce((memo, value) => {
                (memo[value.faixa_etaria] = memo[value.faixa_etaria] || []).push(value);
                return memo;
            }, []);
            
            setSimulador(simulador)

        }).catch(erro => {
            setError(true);
        }).finally(e => {
            setLoading(false);
        });

    }

    const limparInfo = () => {
        setFkOperadora([]);
        setFkClassificacao([]);
        setSimulador([]);
        setIsFiltered(false);
        setFkOperadoraValidate(false);
        setCoParticipacao(1);
        setFe1('');
        setFe2('');
        setFe3('');
        setFe4('');
        setFe5('');
        setFe6('');
        setFe7('');
        setFe8('');
        setFe9('');
        setFe10('');        
    }

    const loadOperadoras = async () => {

        await api.get("getAllOperadoras/", { params: { page: 1, t: '', s: '' }, headers: authHeader() })
        .then(responses => {
            if(mountedRef.current) {
                const requestOperadoras = responses;
                setOperadoras(requestOperadoras.data.operadoras);
            }
        }).catch(errors => {
            setError(true);
        }).finally(e => {
            setLoading(false);
        });

    }

    const loadClassificacao = async (props) => {
        
        if(props.length > 0) {
            setFkOperadoraValidate(false);
        }
        
        setFkOperadora(props);
        setFkClassificacao([]);
        setClassificacao([]);        
        setShowLoadingClassificacao(true);
        
        await api.get("getClassificacaoByOperadora/", { params: { operadora: props.map(e => e.value) }, headers: authHeader() })
        .then(response => {

            const arrClassificacao = [];
            
            const selectClassificacao = response.data.reduce((memo, value) => {
                (memo[value.operadoraDesc] = memo[value.operadoraDesc] || []).push(value);
                return memo;
            }, []);
            
            Object.entries(selectClassificacao).map((e, index) => {
                
                return arrClassificacao.push({
                    label: e[0],
                    options: e[1].map(x => {
                        return {
                            value : x.id,
                            label  : x.descricao
                        }
                    })
                });

            });
            
            setClassificacao(arrClassificacao);
            
        }).catch(errors => {
            setError(true);
        }).finally(e => {
            setShowLoadingClassificacao(false);
        });

    }

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '10px',
    };

    const groupStylesLabel = {
        fontSize: '12px',
        color: '#666'
    };
    
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    const formatGroupLabel = data => (
        <div style={groupStyles}>
          <span style={groupStylesLabel}>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    useEffect(() => {
        loadOperadoras();    
        return () => { mountedRef.current = false }  
    }, []);

    return (
        (error) ? <><div>Teste</div></> :
        <main>
            <section id="consultar-simulador">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                            <DropdownButton
                                disabled={true}
                                variant="link"
                                title={<FiMoreHorizontal color="596d98" />}
                                id="dropdown-menu-align-right"
                                >
                            </DropdownButton>
                        </div>
                        </div>
                        <Card.Body>    
                            <Form>
                                <Row>
                                    
                                    <Col xs={12} md={12}>
                                        <Form.Group>
                                            <Form.Check
                                                type="radio"
                                                label="Com Co-participação"
                                                value="1"
                                                name="adesao"
                                                id="com-co-participacao"
                                                checked={(coParticipacao === 1)}
                                                onChange={() => setCoParticipacao(1)}
                                                />
                                                <Form.Check
                                                type="radio"
                                                label="Sem Co-participação"
                                                value="2"
                                                name="adesao"
                                                id="sem-co-participacao"
                                                checked={(coParticipacao === 2)}
                                                onChange={() => setCoParticipacao(2)}
                                                />
                                        </Form.Group>
                                    </Col>

                                    <Col xs={12} md={12}>
                                        <Row>
                                            
                                            <Col xs={12} md={2}>
                                                <Form.Label>0 a 18 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-0-a-18-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe1`} 
                                                        type="number" 
                                                        onChange={(e) => setFe1(e.target.value)}
                                                        value={fe1}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>19 a 23 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-19-a-23-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe2`} 
                                                        type="number" 
                                                        onChange={(e) => setFe2(e.target.value)}
                                                        value={fe2}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>24 a 28 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-24-a-28-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe3`} 
                                                        type="number" 
                                                        onChange={(e) => setFe3(e.target.value)}
                                                        value={fe3}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>29 a 33 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-29-a-33-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe4`} 
                                                        type="number" 
                                                        onChange={(e) => setFe4(e.target.value)}
                                                        value={fe4}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>34 a 38 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-34-a-38-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe5`} 
                                                        type="number" 
                                                        onChange={(e) => setFe5(e.target.value)}
                                                        value={fe5}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>39 a 43 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-39-a-43-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe6`} 
                                                        type="number" 
                                                        onChange={(e) => setFe6(e.target.value)}
                                                        value={fe6}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>44 a 48 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-44-a-48-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe7`} 
                                                        type="number" 
                                                        onChange={(e) => setFe7(e.target.value)}
                                                        value={fe7}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>49 a 53 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-49-a-53-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe8`} 
                                                        type="number" 
                                                        onChange={(e) => setFe8(e.target.value)}
                                                        value={fe8}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>54 a 58 anos</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-54-a-58-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe9`} 
                                                        type="number" 
                                                        onChange={(e) => setFe9(e.target.value)}
                                                        value={fe9}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={2}>
                                                <Form.Label>59 anos +</Form.Label>
                                                <Form.Group controlId={`field-faixa-etaria-59-anos`}>
                                                    <Form.Control 
                                                        autoComplete="off" 
                                                        name={`fe10`} 
                                                        type="number" 
                                                        onChange={(e) => setFe10(e.target.value)}
                                                        value={fe10}
                                                        placeholder="0"
                                                    />
                                                </Form.Group>
                                            </Col>
                                        
                                        </Row>
                                    </Col>
                                    
                                
                                    <Col xs={12} md={12}>
                                        
                                        <Row>                                            
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="select-operadora">
                                                    <Form.Label>Operadora</Form.Label>
                                                    
                                                    <Select 
                                                        className={`select-default ${fk_operadoraValidate ? 'is-invalid' : ''}`}
                                                        options={operadoras.filter(e => e.tipo === "1").map(e => ({ value: e.id, label: e.descricao}))} 
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        isMulti
                                                        onChange={loadClassificacao}
                                                        value={fk_operadora}
                                                        name="fk_operadora" 
                                                        placeholder=""
                                                        noOptionsMessage={() => 'Sem resultados!'}
                                                        styles={{
                                                            singleValue: (provided, state) => {
                                                                const paddingLeft = 7;
                                                                return { ...provided, paddingLeft };
                                                            },
                                                            input: (provided, state) => {
                                                                const paddingLeft = 7;
                                                                return { ...provided, paddingLeft };
                                                            },
                                                            placeholder: (provided, state) => {
                                                                const paddingLeft = 7;
                                                                return { ...provided, paddingLeft };
                                                            },
                                                            menu: (provided, state) => {
                                                                const borderRadius = 0;
                                                                const fontSize = 12;
                                                                return { ...provided, borderRadius, fontSize };
                                                            },
                                                        }}
                                                    />

                                                    <Form.Control.Feedback type="invalid">Escolha pelo menos uma operadora!</Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group controlId="select-classificacao">
                                                    <Form.Label>
                                                        Classificação
                                                        {showLoadingClassificacao ? <Spinner animation="border" variant="dark" style={{marginLeft: '5px', width: '.8rem', height: '.8rem'}} />  : ''}
                                                    </Form.Label>    
                                                    <Select 
                                                        className={`select-default`}
                                                        isDisabled={(classificacao.length > 0) ? false : true}
                                                        options={classificacao} 
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        formatGroupLabel={formatGroupLabel}
                                                        isMulti
                                                        onChange={setFkClassificacao}
                                                        value={fk_classificacao}
                                                        name="fk_classificacao" 
                                                        placeholder=""
                                                        noOptionsMessage={() => 'Sem resultados!'}
                                                        styles={{
                                                            singleValue: (provided, state) => {
                                                                const paddingLeft = 7;
                                                                return { ...provided, paddingLeft };
                                                            },
                                                            input: (provided, state) => {
                                                                const paddingLeft = 7;
                                                                return { ...provided, paddingLeft };
                                                            },
                                                            placeholder: (provided, state) => {
                                                                const paddingLeft = 7;
                                                                return { ...provided, paddingLeft };
                                                            },
                                                            menu: (provided, state) => {
                                                                const borderRadius = 0;
                                                                const fontSize = 12;
                                                                return { ...provided, borderRadius, fontSize };
                                                            },
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>                                    
                                            
                                        </Row>        
                                    </Col>

                                </Row>
                                
                                <div className="btn-grupo" style={{margin: '10px 0'}}>
                                    <Button variant="secondary" size="sm" type="button" onClick={handleSubmit}>
                                        <FiSearch /> Gerar
                                    </Button>&nbsp;
                                    <Button variant="primary" 
                                            size="sm" 
                                            onClick={() => {
                                                limparInfo();
                                            }} 
                                            type="reset">
                                         <FiDelete /> Limpar
                                    </Button>
                                </div>
                            </Form> 
                        </Card.Body>
                        </Card>

                        {(isFiltered) ?
                            <Card style={{marginTop: '20px', padding: '0 25px 10px 25px'}}>
                                <Card.Body> 
                                    {
                                    (Object.entries(simulador).length === 0) ? <NoResult /> : 
                                    <Table responsive hover size="md">
                                        <tbody>
                                            {
                                                Object.entries(simulador).map((e, index) => (
                                                    <Fragment key={index}>
                                                        
                                                        <tr>
                                                            <td className="separateRow" colSpan={7}></td>
                                                        </tr>
                                                        <tr className="headerTable">
                                                            <td style={{fontSize: '22px', verticalAlign: 'middle'}}>{faixa_etaria.filter(y => y.key === e[0]).map(j => j.value)[0]}</td>
                                                            <td style={{widtd: '120px', textAlign: 'center'}}>Enf. Und</td>
                                                            <td style={{widtd: '120px', textAlign: 'center'}}>Enf. {faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0]} Vida(s)</td>
                                                            <td style={{widtd: '120px', textAlign: 'center'}}>Apto. Und</td>
                                                            <td style={{widtd: '120px', textAlign: 'center'}}>Apto. {faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0]} Vida(s)</td>
                                                            <td style={{widtd: '120px', textAlign: 'center'}}>Amb. Und</td>
                                                            <td style={{widtd: '120px', textAlign: 'center'}}>Amb. {faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0]} Vida(s)</td>
                                                        </tr>
                                                        {
                                                            Object.entries(e[1].reduce((memo, value) => { (memo[value.operadora] = memo[value.operadora] || []).push(value); return memo; }, [])).map((f, i) => (
                                                                <Fragment key={i}>
                                                                    <tr className="headerTableOperadora">
                                                                        <td colSpan={7}>{f[0]}</td>
                                                                    </tr>
                                                                    {
                                                                        f[1].map((g, k) => (
                                                                            <tr key={k+''+index}>
                                                                                <td>{g.classificacao}</td>
                                                                                <td style={{width: '120px', textAlign: 'right'}}>{(parseInt(coParticipacao) === 1) ? convertCurrencyBr(g.valor_com_coparticipacao_enfermaria) : convertCurrencyBr(g.valor_sem_coparticipacao_enfermaria)}</td>
                                                                                <td style={{width: '120px', textAlign: 'right'}}><b>{(parseInt(coParticipacao) === 1) ? convertCurrencyBr(g.valor_com_coparticipacao_enfermaria * faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0]) : convertCurrencyBr(g.valor_sem_coparticipacao_enfermaria * faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0])}</b></td>
                                                                                <td style={{width: '120px', textAlign: 'right'}}>{(parseInt(coParticipacao) === 1) ? convertCurrencyBr(g.valor_com_coparticipacao_apartamento) : convertCurrencyBr(g.valor_sem_coparticipacao_apartamento)}</td>
                                                                                <td style={{width: '120px', textAlign: 'right'}}><b>{(parseInt(coParticipacao) === 1) ? convertCurrencyBr(g.valor_com_coparticipacao_apartamento * faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0]) : convertCurrencyBr(g.valor_sem_coparticipacao_apartamento * faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0])}</b></td>
                                                                                <td style={{width: '120px', textAlign: 'right'}}>{convertCurrencyBr(g.valor_ambulatorial)}</td>
                                                                                <td style={{width: '120px', textAlign: 'right'}}><b>{convertCurrencyBr(g.valor_ambulatorial * faixa_etaria_value.filter(v => v.key === e[0]).map(j => j.value)[0])}</b></td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    
                                                                </Fragment>
                                                            ))
                                                        }
                                                    </Fragment>
                                                ))
                                            }
                                        </tbody>                            
                                    </Table>
                                    }
                                </Card.Body>
                            </Card>
                            :
                                ''
                            }
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    )
}

export default Simulador;