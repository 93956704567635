import React from "react";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        textAlign: 'center',
    },
    author: {
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 40,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'left',
    },
    header: {
        fontSize: 12,
        marginBottom: 40,
        textAlign: 'center',
        color: 'grey',
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomWidth: 0, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row",
    }, 
    tableColHeader: { 
        width: "50%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderBottomWidth: 1, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderTopWidth: 0,
        padding: "8px"
    },   
    tableCol: { 
        width: "50%", 
        borderStyle: "solid", 
        borderColor: '#bfbfbf',
        borderBottomWidth: 0, 
        borderLeftWidth: 0, 
        borderRightWidth: 0, 
        borderTopWidth: 0,
        padding: "8px",
        textAlign: "left"
    }, 
    tableCellHeader: {
        fontSize: 12,
        fontWeight: 500
    },  
    tableCell: { 
        fontSize: 10 
    }
});

const ExportPDF = (info) => {
    return (
    <Document>
        <Page size="A4" style={styles.body}>
        <Text style={styles.header} fixed>
            SUA LOGO
        </Text>
        <Text style={styles.title}>Classificações</Text>
        <Text style={styles.author}>Listagem</Text>
            <View style={styles.table}> 
                <View style={styles.tableRow} fixed> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>Operadora</Text> 
                    </View> 
                    <View style={styles.tableColHeader}> 
                        <Text style={styles.tableCellHeader}>Classificacao</Text> 
                    </View> 
                </View>

            {
                info.dados.sort((a,b) =>  +(a.descricao.toLowerCase() > b.descricao.toLowerCase()) || +(a.descricao.toLowerCase() === b.descricao.toLowerCase()) - 1).map((props) => {
                    return (
                    
                        <View style={styles.tableRow} key={props.id}> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>{props.descOperadora}</Text> 
                            </View> 
                            <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>{props.descricao}</Text> 
                            </View> 
                        </View> 

                    )
                })
            }

        </View>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />
        </Page>
    </Document>
    )
}

export default ExportPDF;