import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Modal, Dropdown, DropdownButton, Spinner } from 'react-bootstrap';
import { FiSave, FiEdit, FiTrash, FiSearch, FiDelete, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { FaCaretRight, FaCaretLeft, FaStepBackward, FaStepForward, FaTimes } from 'react-icons/fa';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Swal from "sweetalert2";
import Carregando from '../../../_components/Carregando';
import Pagination from "react-js-pagination";
import Select from 'react-select';
import Arvore from '../../../_components/Arvore';
import axios from 'axios';
import CurrencyInput from '../../../_components/CurrencyInput';
import './styles.css';
import NoResult from '../../Errors/noResult';

const Comissao = () => {

    const [txtChanged       , setTxtChanged]       = useState('');
    const [selectChanged    , setSelectChanged]    = useState('');
    const [oldTxtChanged    , setOldTxtChanged]    = useState('');
    const [hasResult        , setHasResult]        = useState(false);
    const [loadingSearch    , setLoadingSearch]    = useState(false);
    const [comissoes        , setComissoes]        = useState([]);
    const [loading          , setLoading]          = useState(true); 
    const [error            , setError]            = useState(false);
    const [labelBotao       , setLabelBotao]       = useState('Salvar');
    const [total            , setTotal]            = useState(0);
    const [page             , setPage]             = useState(1);
    const [id               , setId]               = useState(0); 
    const [operadoras       , setOperadoras]       = useState(0);
    const [classificacoes   , setClassificacoes]   = useState(0);
    const [idOperadora      , setIdOperadora]      = useState(0);
    const [idClassificacao  , setIdClassificacao]  = useState(0);
    const [comissao1        , setComissao1]        = useState('');
    const [comissao2        , setComissao2]        = useState('');
    const [comissao3        , setComissao3]        = useState('');
    const [comissao4        , setComissao4]        = useState('');
    const [comissao5        , setComissao5]        = useState('');
    const [comissao6        , setComissao6]        = useState('');
    const [comissao7        , setComissao7]        = useState('');
    const [comissao8        , setComissao8]        = useState('');
    const [comissao9        , setComissao9]        = useState('');
    const [comissao10       , setComissao10]       = useState('');
    const [comissao11       , setComissao11]       = useState('');
    const [comissao12       , setComissao12]       = useState('');
    const [enableValidation , setEnableValidation] = useState(false);
    const [modalShow        , setModalShow]        = useState(false);
    const mountedRef                               = useRef(true);    

    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Comissão' ];

    function escapeRegexCharacters(str)
    {
        return str.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&');
    }

    function onTextChanged(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        if(oldTxtChanged !== valor) {
            setTxtChanged(valor);
            setOldTxtChanged(valor);
            setLoadingSearch(true);
            loadComissoes(1, valor, selectChanged);
        }                    
    }

    function onSelectChanged(e)
    {
        if(e !== null) {
            const valorSelect = e.value;
            setSelectChanged(valorSelect);
            setLoadingSearch(true);
            loadComissoes(1, txtChanged, valorSelect);
        }else {
            setLoadingSearch(true);
            setSelectChanged('');
            loadComissoes(1, txtChanged, '');            
        }
    }

    const loadComissoes = async (pagina = 1, txtChanged = '', selectChanged = '') => {
        
        await api.get('comissao/', {
            params: {
                page : pagina,
                t    : txtChanged,
                o    : selectChanged
            },
            headers: authHeader()
        }).then((response) => {
            
            if(mountedRef.current) {
                setHasResult(true);
                setComissoes(response.data.comissao);
                setTotal(parseInt(response.data.XCountRegister));
                setPage(pagina);
                if((!!txtChanged === false && !!selectChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                    setHasResult(false);
                }
            }

        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
                setLoadingSearch(false);
            }

        });
         
    }

    const loadInfo = async () => {
       
        let classificacao = "getAllClassificacoes/";
        let operadoras = "getAllOperadoras/";

        const requestClassificacao = await api.get(classificacao,{ params: { page: 1, t: '', s: '' }, headers: authHeader() });
        const requestOperadoras = await api.get(operadoras,{ params: { page: 1, t: '', s: '' }, headers: authHeader() });
        
        axios.all([requestClassificacao, requestOperadoras]).then(axios.spread((...responses) => {
            
            if(mountedRef.current) {
                const requestClassificacao = responses[0];
                const requestOperadoras = responses[1];
                setClassificacoes(requestClassificacao.data.classificacoes.map(e => ({ value: e.id, label: e.descricao})));
                setOperadoras(requestOperadoras.data.operadoras.map(e => ({ value: e.id, label: e.descricao})));
            }

        })).catch(errors => {

            setError(true);

        });

    }
    
    const carregarComissao = (props) => {
        setModalShow(true);
        setId(props.id);
        setIdOperadora(props.fk_operadora);
        setIdClassificacao(props.fk_classificacao);
        setComissao1(props.comissao1 === '0,00' ? '' : props.comissao1);
        setComissao2(props.comissao2 === '0,00' ? '' : props.comissao2);
        setComissao3(props.comissao3 === '0,00' ? '' : props.comissao3);
        setComissao4(props.comissao4 === '0,00' ? '' : props.comissao4);
        setComissao5(props.comissao5 === '0,00' ? '' : props.comissao5);
        setComissao6(props.comissao6 === '0,00' ? '' : props.comissao6);
        setComissao7(props.comissao7 === '0,00' ? '' : props.comissao7);
        setComissao8(props.comissao8 === '0,00' ? '' : props.comissao8);
        setComissao9(props.comissao9 === '0,00' ? '' : props.comissao9);
        setComissao10(props.comissao10 === '0,00' ? '' : props.comissao10);
        setComissao11(props.comissao11 === '0,00' ? '' : props.comissao11);
        setComissao12(props.comissao12 === '0,00' ? '' : props.comissao12);
        setLabelBotao('Alterar');
    }

    const excluirComissao = (props) => {
        
        limparInfo();
        Swal.fire({
            title: `Deseja excluir a Comissão da Classificação ${props.classificacao}?`,
            text: `Está operação não poderá ser desfeita!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {
                
                removerComissao(props);

            }
        });

    }
    
    const limparInfo = () => {
        setEnableValidation(false);
        setIdOperadora(0);
        setIdClassificacao(0);
        setComissao1('');
        setComissao2('');
        setComissao3('');
        setComissao4('');
        setComissao5('');
        setComissao6('');
        setComissao7('');
        setComissao8('');
        setComissao9('');
        setComissao10('');
        setComissao11('');
        setComissao12('');
        setId(0);
        setLabelBotao('Salvar');
    }

    const exibirModal = () => {
        limparInfo();
        setEnableValidation(false);
        setModalShow(true);
    }

    const cadastrarInfo = async (props, info) => {
        
        setModalShow(false);
        
        setLoading(true);
        let data = JSON.stringify({
            fk_operadora      : props.idOperadora,
            fk_classificacao  : props.idClassificacao,
            comissao1         : props.comissao1,
            comissao2         : props.comissao2,
            comissao3         : props.comissao3,
            comissao4         : props.comissao4,
            comissao5         : props.comissao5,
            comissao6         : props.comissao6,
            comissao7         : props.comissao7,
            comissao8         : props.comissao8,
            comissao9         : props.comissao9,
            comissao10        : props.comissao10,
            comissao11        : props.comissao11,
            comissao12        : props.comissao12
        });
        const requisicao = (props.id > 0) ? api.put : api.post;
        const url = (props.id > 0) ? `/comissao/${props.id}` : `/comissao`;
        
        await requisicao(url, data, {headers: authHeader()})
        .then(response => {
            
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    setHasResult(true);
                    const resultado = response.data.comissoes[0];
                    if(props.id > 0) {
                        
                        const updateComissao = comissoes.filter((item) => item.id !== props.id);
                        setComissoes([...updateComissao,  { id              : resultado.id, 
                                                            fk_operadora     : resultado.fk_operadora,
                                                            fk_classificacao : resultado.fk_classificacao,
                                                            classificacao   : resultado.classificacao,
                                                            operadora       : resultado.operadora,
                                                            comissao1       : resultado.comissao1,
                                                            comissao2       : resultado.comissao2,
                                                            comissao3       : resultado.comissao3,
                                                            comissao4       : resultado.comissao4,
                                                            comissao5       : resultado.comissao5,
                                                            comissao6       : resultado.comissao6,
                                                            comissao7       : resultado.comissao7,
                                                            comissao8       : resultado.comissao8,
                                                            comissao9       : resultado.comissao9,
                                                            comissao10      : resultado.comissao10,
                                                            comissao11      : resultado.comissao11,
                                                            comissao12      : resultado.comissao12} ]);
                        Swal.fire(
                            'Sucesso!',
                            'Operadora alterada com sucesso!',
                            'success'
                        );
                        
                    } else {
                        
                        setComissoes([...comissoes, { id              : resultado.id, 
                                                        fk_operadora     : resultado.fk_operadora,
                                                        fk_classificacao : resultado.fk_classificacao,
                                                        classificacao   : resultado.classificacao,
                                                        operadora       : resultado.operadora,
                                                        comissao1       : resultado.comissao1,
                                                        comissao2       : resultado.comissao2,
                                                        comissao3       : resultado.comissao3,
                                                        comissao4       : resultado.comissao4,
                                                        comissao5       : resultado.comissao5,
                                                        comissao6       : resultado.comissao6,
                                                        comissao7       : resultado.comissao7,
                                                        comissao8       : resultado.comissao8,
                                                        comissao9       : resultado.comissao9,
                                                        comissao10      : resultado.comissao10,
                                                        comissao11      : resultado.comissao11,
                                                        comissao12      : resultado.comissao12} ]);
                        Swal.fire(
                            'Sucesso!',
                            'Classificação cadastrada com sucesso!',
                            'success'
                        );
                        setPage(1);
                        setTxtChanged('');
                        setTotal(parseInt(response.data.XCountRegister));  
                        
                    }
                    limparInfo();
                }
                
            }

        }).catch(() => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {
                
                setLoading(false);
            }

        });            

    }

    const removerComissao = async (props) => {
        
        setLoading(true);
    
        await api.delete(`/comissao/${props.id}`, {headers: authHeader()})
        .then(response => {

            if(mountedRef.current) {
            
                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }
                    Swal.fire(
                        'Atenção!',
                        `${resultadoTexto}`,
                        'warning'
                    );
                    return false;                 
                }
                else
                {
                    Swal.fire(
                        'Sucesso!',
                        'Operadora removida com sucesso!',
                        'success'
                    );
                    setHasResult(true);
                    setComissoes(response.data.comissoes);
                    setTotal(parseInt(response.data.XCountRegister));
                    setTxtChanged('');
                    setPage(1);
                    clearFilters();
                    if((!!txtChanged === false && !!selectChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                        setHasResult(false);
                    }
                }

            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                
                setLoading(false);
            }

        });
        
    }

    const clearFilters = () => {
        setTxtChanged('');
        setSelectChanged('');
    }

    useEffect(() => {
        
        loadComissoes();
        loadInfo();
        return () => { mountedRef.current = false }

    }, []);

    return (
        (error) ? <></> :
        <main>
            <section id="comissao">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Item eventKey="1" onClick={exibirModal}><FiPlusCircle />&nbsp;&nbsp;Adicionar</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>      
                        <Modal
                            animation={false}
                            show={modalShow}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={()=> false}
                            >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                {labelBotao === 'Alterar' ? 'Alterar' : 'Adicionar'} Comissão
                                </Modal.Title>
                            </Modal.Header>  
                        <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize={true} 
                            initialValues={{
                                id: id,
                                idOperadora: idOperadora,
                                idClassificacao: idClassificacao,
                                comissao1: comissao1,
                                comissao2: comissao2,
                                comissao3: comissao3,
                                comissao4: comissao4,
                                comissao5: comissao5,
                                comissao6: comissao6,
                                comissao7: comissao7,
                                comissao8: comissao8,
                                comissao9: comissao9,
                                comissao10: comissao10,
                                comissao11: comissao11,
                                comissao12: comissao12
                            }}
                            validationSchema={() => {
                                setEnableValidation(true);    
                                return Yup.object({
                                idOperadora: Yup.number().moreThan(0, 'Escolha um vendedor')
                                                         .required('Campo deve ser preenchido!'),
                                idClassificacao: Yup.number().moreThan(0, 'Escolha um vendedor')
                                                             .required('Campo deve ser preenchido!'),
                            })}}
                            onSubmit={cadastrarInfo}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                <Form.Control 
                                    autoComplete="off" 
                                    readOnly={true}
                                    name="id" 
                                    type="hidden" 
                                    onChange={handleChange} 
                                    value={values.id}
                                    placeholder=""
                                />
                                 <Row>
                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="select-operadora">
                                            <Form.Label>Operadora*</Form.Label>                        
                                            <Select 
                                                className={`select-default ${!!errors.idOperadora ? 'is-invalid' : ''}`}
                                                options={operadoras} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("idOperadora", e.value) }
                                                value={values.idOperadora > 0 ? operadoras.find(obj => obj.value === values.idOperadora) : ''} 
                                                name="idOperadora" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.idOperadora}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Group controlId="select-classificacao">
                                            <Form.Label>Classificação*</Form.Label>                        
                                            <Select 
                                                className={`select-default ${!!errors.idClassificacao ? 'is-invalid' : ''}`}
                                                options={classificacoes} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("idClassificacao", e.value) }
                                                value={values.idClassificacao > 0 ? classificacoes.find(obj => obj.value === values.idClassificacao) : ''} 
                                                name="idClassificacao" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.idClassificacao}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 1</Form.Label>
                                        <Form.Group controlId="field-comissao1">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao1`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao1} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 2</Form.Label>
                                        <Form.Group controlId="field-comissao2">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao2`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao2} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 3</Form.Label>
                                        <Form.Group controlId="field-comissao3">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao3`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao3} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 4</Form.Label>
                                        <Form.Group controlId="field-comissao4">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao4`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao4} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 5</Form.Label>
                                        <Form.Group controlId="field-comissao5">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao5`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao5} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 6</Form.Label>
                                        <Form.Group controlId="field-comissao6">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao6`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao6} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 7</Form.Label>
                                        <Form.Group controlId="field-comissao7">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao7`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao7} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 8</Form.Label>
                                        <Form.Group controlId="field-comissao8">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao8`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao8} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 9</Form.Label>
                                        <Form.Group controlId="field-comissao9">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao9`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao9} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 10</Form.Label>
                                        <Form.Group controlId="field-comissao10">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao10`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao10} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 11</Form.Label>
                                        <Form.Group controlId="field-comissao11">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao11`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao11} 
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={4} lg={3}>
                                        <Form.Label>Comissão 12</Form.Label>
                                        <Form.Group controlId="field-comissao12">
                                            <CurrencyInput 
                                                    autoComplete="off" 
                                                    className="form-control"
                                                    name={`comissao12`}
                                                    type="text" 
                                                    guide={false}
                                                    onChange={handleChange}
                                                    placeholder="0,00"
                                                    value={values.comissao12} 
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" size="sm" type="submit">
                                        <FiSave /> {labelBotao}
                                    </Button>
                                    <Button variant="primary" 
                                            size="sm" 
                                            onClick={() => {
                                                resetForm();
                                                limparInfo();
                                            }} 
                                            type="reset">
                                        <FiDelete /> Limpar
                                    </Button>
                                    <Button variant="secondary" 
                                            className="padrao-cancelar"
                                            size="sm" 
                                            onClick={() => setModalShow(false)}>
                                        <FaTimes /> Fechar
                                    </Button>
                                </Modal.Footer>
                            </Form>
                            )}
                        </Formik>    
                        </Modal>     
                            {
                                (hasResult) ?
                            
                                <Fragment>
                                    <Table responsive hover size="md">
                                    <thead>
                                        <tr>
                                            <th style={{width:"250px"}}>
                                                <Select 
                                                    className="basic-single"
                                                    options={operadoras} 
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder="Operadora"
                                                    value={parseInt(selectChanged) > 0 ? operadoras.find(obj => obj.value === parseInt(selectChanged)) : ''} 
                                                    menuPortalTarget={document.body}
                                                    noOptionsMessage={() => 'Sem resultados!'}
                                                    onChange={onSelectChanged}
                                                    styles={{
                                                        menu: (provided, state) => {
                                                            const borderRadius = 0;
                                                            const fontSize = 12;
                                                            return { ...provided, borderRadius, fontSize };
                                                        },
                                                    }}
                                                />
                                            </th>
                                            <th>
                                            <Form.Group controlId="field-search">
                                            {loadingSearch ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        /> : <FiSearch />}
                                            <Form.Control 
                                                autoComplete="off" 
                                                type="text"
                                                placeholder="Classificação"
                                                aria-label="Classificação"
                                                maxLength={20}
                                                value={txtChanged}
                                                onChange={(e) => {
                                                    setTxtChanged(e.target.value.trim());
                                                    if(e.target.value === '') {
                                                        onTextChanged(e);
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if(e.key === 'Enter') {
                                                        onTextChanged(e);
                                                    }
                                                }}
                                            />
                                            </Form.Group>
                                            </th>
                                            <th style={{width:"15px"}}></th>
                                            <th style={{width:"15px"}}></th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(comissoes.length === 0) ? <><tr><td colSpan={4} style={{textAlign: 'center'}}>Nenhum resultado encontrado!</td></tr></> : <>
                                        {
                                            comissoes.sort((a,b) =>  +(a.id > b.id) - 1).slice(0, 10).map((props) => {
                                                return (
                                                <tr key={props.id}>
                                                    <td>{props.operadora}</td>
                                                    <td>{props.classificacao}</td>
                                                    <td>
                                                        <button className="botao" onClick={() => carregarComissao(props)}>
                                                            <FiEdit style={{color:'#638c5e', cursor: 'pointer'}} />
                                                        </button>
                                                    </td>
                                                    <td>
                                                        <button className="botao" onClick={() => excluirComissao(props)}>
                                                            <FiTrash style={{color:'#c94646', cursor: 'pointer'}} />                                            
                                                        </button>
                                                    </td>
                                                </tr>
                                                )
                                            })
                                        }
                                        </>}
                                    </tbody>
                                    
                                    </Table>
                                    {(comissoes.length === 0) ? <></> :
                                        <>
                                        <Pagination
                                        nextPageText={<FaCaretRight size={19} />}
                                        prevPageText={<FaCaretLeft size={19}  />}
                                        lastPageText={<FaStepForward />}
                                        firstPageText={<FaStepBackward />}
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={5}
                                        onChange={(ev) => loadComissoes(ev, txtChanged, selectChanged)}
                                        />
                                        </>
                                    }       
                                </Fragment>
                            : 
                                !hasResult && !loading ? <NoResult /> : 'Loading...'
                        }              
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>    
    );
}

export default Comissao;