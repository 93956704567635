import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, DropdownButton } from 'react-bootstrap';
import { FiSave, FiDelete, FiMoreHorizontal } from 'react-icons/fi';
import Arvore from '../../../_components/Arvore';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Carregando from '../../../_components/Carregando';
import './styles.css';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import CurrencyInput from '../../../_components/CurrencyInput';
import NoResult from '../../Errors/noResult';

const ValorOdonto = () => {

    const [loading, setLoading]                   = useState(true);
    const [error, setError]                       = useState(false);
    const [operadorasOdonto, setOperadorasOdonto] = useState([]);
    const [id, setId]                             = useState([]);
    const [descricao, setDescricao]               = useState([]);
    const [boleto, setBoleto]                     = useState([]);
    const [cartao, setCartao]                     = useState([]);
    const mountedRef                              = useRef(true);
    
    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Tabela de Valores Odontológicos' ];

    const loadOperadorasOdonto = async () => {
        
        await api.get('operadoraValorOdonto/', { headers: authHeader()
        }).then((response) => {

            if(mountedRef.current) {
            
                let ids = response.data.operadoraValorOdonto.map((x) => x.id);
                setId(ids);

                let descricoes = response.data.operadoraValorOdonto.map((x) => x.descricao);
                setDescricao(descricoes);

                let cartoes = response.data.operadoraValorOdonto.map((x) => x.cartao);
                setCartao(cartoes);

                let boletos = response.data.operadoraValorOdonto.map((x) => x.boleto);
                setBoleto(boletos);

                setOperadorasOdonto(response.data.operadoraValorOdonto);

            }

        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }

        });
            
    }

    const cancelarValores = () => {
        setLoading(true);
        setId([]);
        setDescricao([]);
        setCartao([]);
        setBoleto([]);
        setOperadorasOdonto([]);
        loadOperadorasOdonto();
    }

    const cadastrarInfo = async (props) => { 
        
        setLoading(true);
        
        let data = JSON.stringify({
            props
        });

        await api.post(`operadoraValorOdonto`, data, {headers: authHeader()})
        .then(response => {
            
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    
                    setId([]);
                    setDescricao([]);
                    setCartao([]);
                    setBoleto([]);
                    setOperadorasOdonto([]);
                    
                    let ids = response.data.operadoraValorOdonto.map((x) => x.id);
                    setId(ids);

                    let descricoes = response.data.operadoraValorOdonto.map((x) => x.descricao);
                    setDescricao(descricoes);

                    let cartoes = response.data.operadoraValorOdonto.map((x) => x.cartao);
                    setCartao(cartoes);

                    let boletos = response.data.operadoraValorOdonto.map((x) => x.boleto);
                    setBoleto(boletos);

                    setOperadorasOdonto(response.data.operadoraValorOdonto);

                    Swal.fire({
                        title: `Tabela Plano de Odontologicos`,
                        text: `Valores alterados com sucesso!`,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok',
                        cancelButtonText: 'Não'
                        }).then((result) => {
                        if (result.value) {
                            setTimeout(function(){ 
                                window.scrollTo(0, 0);
                            }, 500);        
                        }
                    });
                }
            
            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }

        });
        
    }

    useEffect(() => {

        loadOperadorasOdonto();
        return () => { mountedRef.current = false }

    }, []);

    return(
        (error) ? <></> :
        <main>
            <section id="valorodonto">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    disabled={true}
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body> 
                        {
                        operadorasOdonto.length > 0 
                        ?     
                            <Formik
                                enableReinitialize={true} 
                                initialValues={{
                                    operadoras: operadorasOdonto,
                                    id: id,
                                    descricao: descricao,
                                    boleto: boleto,
                                    cartao: cartao
                                }}
                                validationSchema={ Yup.object({
                                                                
                                })}
                                onSubmit={cadastrarInfo}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    resetForm,
                                    setFieldValue,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                
                                        <Table responsive hover size="md">
                                        <thead>
                                            <tr>
                                                <th>Planos</th>
                                                <th>Cartão de Crédito</th>
                                                <th>Boleto Bancário</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            operadorasOdonto.map((props, index) => {
                                                return (
                                                    <tr key={props.id.toString()}>
                                                        <td>
                                                            {props.descricao}
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`field-cartao${props.id}`}> 
                                                                <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`cartao.${index}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onChange={handleChange}
                                                                        placeholder=""
                                                                        value={values.cartao[index]} 
                                                                />
                                                            </Form.Group> 
                                                        </td>
                                                        <td>
                                                            <Form.Group controlId={`field-boleto${props.id}`}>
                                                                <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`boleto.${index}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onChange={handleChange}
                                                                        placeholder=""
                                                                        value={values.boleto[index]}
                                                                /> 
                                                            </Form.Group> 
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    
                                        }
                                        </tbody>
                                        </Table>
                                    <Button variant="secondary" size="sm" type="submit">
                                    <FiSave /> Alterar
                                    </Button>
                                    <Button 
                                        variant="primary" 
                                        size="sm" 
                                        onClick={cancelarValores} 
                                        type="reset">
                                    <FiDelete /> Cancelar
                                    </Button>
                                </Form>
                                )}
                            </Formik>  
                        :
                            operadorasOdonto.length === 0 && !loading ? <NoResult /> : 'Loading...'
                        }                      
                            
                           
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    )
}

export default ValorOdonto;