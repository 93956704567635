import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, DropdownButton } from 'react-bootstrap';
import { FiSearch, FiMoreHorizontal, FiDelete } from 'react-icons/fi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import { convertCurrencyBr } from '../../../utils/global';
import axios from 'axios';
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import './styles.css';
import NoResult from '../../Errors/noResult';
import PageError from '../../Errors/pageError';

const Saude = () => {

    const [loading , setLoading] = useState(true); 
    const [error , setError] = useState(false);
    const [enableValidation , setEnableValidation] = useState(false);
    const [operadoras , setOperadoras] = useState([]);
    const [resultado , setResultado] = useState([]);
    const [fkOperadora , setFkOperadora] = useState(0);
    const [isFiltered , setIsFiltered] = useState(false);
    const mountedRef = useRef(true);

    const lista = ['Home', 'Tabelas', 'Plano de Saúde'];

    const faixa_etaria = {};
          faixa_etaria['0_a_18_anos']  = '0 a 18 anos';
          faixa_etaria['19_a_23_anos'] = '19 a 23 anos';
          faixa_etaria['24_a_28_anos'] = '24 a 28 anos';
          faixa_etaria['29_a_33_anos'] = '29 a 33 anos';
          faixa_etaria['34_a_38_anos'] = '34 a 38 anos';
          faixa_etaria['39_a_43_anos'] = '39 a 43 anos';
          faixa_etaria['44_a_48_anos'] = '44 a 48 anos';
          faixa_etaria['49_a_53_anos'] = '49 a 53 anos';
          faixa_etaria['54_a_58_anos'] = '54 a 58 anos';
          faixa_etaria['59_anos_+']    = '59 anos +';
    
    const FiltrarMetas = async (props) => {
        
        setLoading(true);
        setIsFiltered(true);

        let dados = {
            fk_operadora: props.fk_operadora
        };

        await api.get('getTabelaSaude/', {
            params: dados,
            headers: authHeader()
        }).then((response) => {
            var result = response.data.classificacao.reduce((memo, value) => {
                (memo[value.descricao] = memo[value.descricao] || []).push(value);
                return memo;
            }, []);
            setResultado(result);   
        }).catch(erro => {
            setError(true);
        }).finally(e => {
            setLoading(false);
        });

    }

    const limparInfo = () => {
        setFkOperadora(0);
        setIsFiltered(false);
        setResultado([]);
    }

    const loadInfo = async () => {
        let operadoras = "getAllOperadoras/";

        const requestOperadoras = await api.get(operadoras,{ params: { page: 1, t: '', s: '' }, headers: authHeader() });
        
        axios.all([requestOperadoras]).then(axios.spread((...responses) => {
            
            const requestOperadoras = responses[0];
            if(mountedRef.current) {
                setOperadoras(requestOperadoras.data.operadoras);
            }

        })).catch(errors => {

            setError(true);

        }).finally(e => {

            setLoading(false);

        });
    }

    useEffect(() => {
        loadInfo();      
        return () => { mountedRef.current = false }  
    }, []);

    return (
        (error) ? <PageError /> :
        <main>
            <section id="tabela-saude">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                            <DropdownButton
                                disabled={true}
                                variant="link"
                                title={<FiMoreHorizontal color="596d98" />}
                                id="dropdown-menu-align-right"
                                >
                            </DropdownButton>
                        </div>
                        </div>
                        <Card.Body>    
                        
                        <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize={true} 
                            initialValues={{
                                fk_operadora: fkOperadora                                
                            }}
                            validationSchema={ () => {
                            setEnableValidation(true);    
                            return Yup.object({
                                fk_operadora: Yup.number()
                                                 .moreThan(0, 'Selecione uma operadora!')
                                                 .required('Selecione uma operadora!'),
                            })}}
                            onSubmit={FiltrarMetas}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                validateForm,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} md={3}>
                                        <Form.Group controlId="select-operadora">
                                            <Form.Label>Operadora*</Form.Label>    
                                            <Select 
                                                className={`select-default ${!!errors.fk_operadora ? 'is-invalid' : ''}`}
                                                options={operadoras.filter(e => e.tipo === "1").map(e => ({ value: e.id, label: e.descricao}))} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("fk_operadora", e.value) }
                                                value={values.fk_operadora > 0 ? operadoras.find(obj => obj.value === values.fk_operadora) : ''} 
                                                name="fk_operadora" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.fk_operadora}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div>
                                    <Button variant="secondary" size="sm" type="submit">
                                        <FiSearch /> Filtrar
                                    </Button>&nbsp;
                                    <Button variant="primary" 
                                            size="sm" 
                                            onClick={() => {
                                                resetForm();
                                                limparInfo();
                                            }} 
                                            type="reset">
                                         <FiDelete /> Limpar
                                    </Button>
                                </div>
                            </Form>
                            )}
                        </Formik>
                        </Card.Body>
                        </Card>
                                {
                                (isFiltered) ? 
                                    <Card style={{marginTop: '20px', padding: '25px'}}>
                                        <Card.Body>
                                        {
                                        (Object.entries(resultado).length === 0) ? <NoResult /> : 
                                            Object.entries(resultado).map((e, index) => {
                                                return(   
                                                    <div key={index}>
                                                        
                                                        <div style={{width:'100%', padding: '15px', color: 'white', background: '#596d98'}}>{e[0]}</div>
                                                        
                                                        <Table responsive hover size="md">
                                                            <thead>
                                                                <tr>
                                                                    <th  className="celulaTabelaValorFaixaEtaria">Faixa Etária </th>
                                                                    {/* Enfermaria CCPE */}
                                                                    {((parseInt(e[1][0].ccpe_enfermaria)  === 1) ? (<th>Com co-partipação<br></br>Enfermaria</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpe_com_parto)   === 1) ? (<th>[CPP] [E] [CP]</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpe_sem_parto)   === 1) ? (<th>[CPP] [E] [SP]</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpe_com_dental)  === 1) ? (<th>[CPP] [E] [CD]</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpe_sem_dental)  === 1) ? (<th>[CPP] [E] [SD]</th>) : null)}
                                                                    

                                                                    {/* Apartamento CCPA */}
                                                                    {((parseInt(e[1][0].ccpa_apartamento) === 1) ? (<th>Com co-partipação<br></br>Apartamento</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpa_com_parto)   === 1) ? (<th>[CPP] [A] [CP]</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpa_sem_parto)   === 1) ? (<th>[CPP] [A] [SP]</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpa_com_dental)  === 1) ? (<th>[CPP] [A] [CD]</th>) : null)}
                                                                    {((parseInt(e[1][0].ccpa_sem_dental)  === 1) ? (<th>[CPP] [A] [SD]</th>) : null)}
                                                                

                                                                    {/* Enfermaria SCPE */}
                                                                    {((parseInt(e[1][0].scpe_enfermaria)  === 1) ? (<th>Sem co-partipação<br></br>Enfermaria</th>) : null)}
                                                                    {((parseInt(e[1][0].scpe_com_parto)   === 1) ? (<th>[SCP] [E] [CP]</th>) : null)}
                                                                    {((parseInt(e[1][0].scpe_sem_parto)   === 1) ? (<th>[SCP] [E] [SP]</th>) : null)}
                                                                    {((parseInt(e[1][0].scpe_com_dental)  === 1) ? (<th>[SCP] [E] [CD]</th>) : null)}
                                                                    {((parseInt(e[1][0].scpe_sem_dental)  === 1) ? (<th>[SCP] [E] [SD]</th>) : null)}
                                                                    
                                                                    {/* Apartamento SCPA */}
                                                                    {((parseInt(e[1][0].scpa_apartamento) === 1) ? (<th>Sem co-partipação<br></br>Apartamento</th>) : null)}
                                                                    {((parseInt(e[1][0].scpa_com_parto)   === 1) ? (<th>[SCP] [A] [CP]</th>) : null)}
                                                                    {((parseInt(e[1][0].scpa_sem_parto)   === 1) ? (<th>[SCP] [A] [SP]</th>) : null)}
                                                                    {((parseInt(e[1][0].scpa_com_dental)  === 1) ? (<th>[SCP] [A] [CD]</th>) : null)}
                                                                    {((parseInt(e[1][0].scpa_sem_dental)  === 1) ? (<th>[SCP] [A] [SD]</th>) : null)}
                                                                
                                                                    {
                                                                        (parseInt(e[1][0].ambulatorial)  === 1) 
                                                                        ?
                                                                        <th>
                                                                            Ambulatorial 
                                                                        </th>
                                                                        : 
                                                                        null
                                                                    }
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    e[1].map((f, index) => (
                                                                        <tr key={index}>
                                                                            <td className="celulaTabelaValorFaixaEtaria">{faixa_etaria[f.faixa_etaria]}</td>
                                                                            {((parseInt(f.ccpe_enfermaria) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_com_coparticipacao_enfermaria)}</td> : null)}
                                                                            {((parseInt(f.ccpe_com_parto)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpe_com_parto)}</td> : null)}
                                                                            {((parseInt(f.ccpe_sem_parto)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpe_sem_parto)}</td> : null)}
                                                                            {((parseInt(f.ccpe_com_dental) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpe_com_dental)}</td> : null)}
                                                                            {((parseInt(f.ccpe_sem_dental) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpe_sem_dental)}</td> : null)}
                                                                            

                                                                            {/* Apartamento CCPA */}
                                                                            {((parseInt(f.ccpa_apartamento) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_com_coparticipacao_apartamento)}</td> : null)}
                                                                            {((parseInt(f.ccpa_com_parto)   === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpa_com_parto)}</td> : null)}
                                                                            {((parseInt(f.ccpa_sem_parto)   === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpa_sem_parto)}</td> : null)}
                                                                            {((parseInt(f.ccpa_com_dental)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpa_com_dental)}</td> : null)}
                                                                            {((parseInt(f.ccpa_sem_dental)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ccpa_sem_dental)}</td> : null)}
                                                                        

                                                                            {/* Enfermaria SCPE */}
                                                                            {((parseInt(f.scpe_enfermaria) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_sem_coparticipacao_enfermaria)}</td> : null)}
                                                                            {((parseInt(f.scpe_com_parto)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpe_com_parto)}</td> : null)}
                                                                            {((parseInt(f.scpe_sem_parto)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpe_sem_parto)}</td> : null)}
                                                                            {((parseInt(f.scpe_com_dental) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpe_com_dental)}</td> : null)}
                                                                            {((parseInt(f.scpe_sem_dental) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpe_sem_dental)}</td> : null)}
                                                                            
                                                                            {/* Apartamento SCPA */}
                                                                            {((parseInt(f.scpa_apartamento) === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_sem_coparticipacao_apartamento)}</td> : null)}
                                                                            {((parseInt(f.scpa_com_parto)   === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpa_com_parto)}</td> : null)}
                                                                            {((parseInt(f.scpa_sem_parto)   === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpa_sem_parto)}</td> : null)}
                                                                            {((parseInt(f.scpa_com_dental)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpa_com_dental)}</td> : null)}
                                                                            {((parseInt(f.scpa_sem_dental)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_scpa_sem_dental)}</td> : null)}

                                                                            {((parseInt(f.ambulatorial)  === 1) ? <td className="celulaTabelaValor">{convertCurrencyBr(f.valor_ambulatorial)}</td> : null)}

                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </Table>

                                                    </div>
                                                )
                                            })
                                        }
                                        </Card.Body>
                                    </Card>
                                : ''
                                }
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    )
}

export default Saude;