import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, Spinner, Modal, Dropdown, DropdownButton, Toast } from 'react-bootstrap';
import { FiSave, FiEdit, FiTrash, FiSearch, FiDelete, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { FaCaretRight, FaCaretLeft, FaStepBackward, FaStepForward, FaTimes, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import Pagination from "react-js-pagination";
import Arvore from '../../../_components/Arvore';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Carregando from '../../../_components/Carregando';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Swal from "sweetalert2";
import ExportPDF from './exportPDF';
import { saveAs } from "file-saver";
import { pdf } from '@react-pdf/renderer';
import './styles.css';
import NoResult from '../../Errors/noResult';
import PageError from '../../Errors/pageError';

const Administradora = () => {

    const [txtChanged       , setTxtChanged]       = useState('');
    const [oldTxtChanged    , setOldTxtChanged]    = useState('');
    const [hasResult        , setHasResult]        = useState(false);
    const [loadingSearch    , setLoadingSearch]    = useState(false);
    const [administradoras  , setAdministradoras]  = useState([]);
    const [total            , setTotal]            = useState(0);
    const [page             , setPage]             = useState(1);
    const [loading          , setLoading]          = useState(true);
    const [error            , setError]            = useState(false);
    const [isPdf            , setIsPdf]            = useState(false);
    const [isXLS            , setIsXLS]            = useState(false);    
    const [id               , setId]               = useState(0); 
    const [descricao        , setDescricao]        = useState('');
    const [labelBotao       , setLabelBotao]       = useState('Salvar');
    const [enableValidation , setEnableValidation] = useState(false);
    const [modalShow        , setModalShow]        = useState(false);
    const mountedRef                               = useRef(true);
    
    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Administradora' ];

    function escapeRegexCharacters(str)
    {
        return str.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&');
    }

    function onTextChanged(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        if(oldTxtChanged !== valor) {
            setTxtChanged(valor);
            setOldTxtChanged(valor);
            setLoadingSearch(true);
            loadAdministradoras(1, valor);
        }                    
    }

    const loadAdministradoras = async (pagina = 1, txtChanged = '') => {
        
        await api.get('administradora/', {
            params: {
                page: pagina,
                t: txtChanged
            },
            headers: authHeader()
        }).then((response) => {

            if(mountedRef.current) {
                setHasResult(true);
                setAdministradoras(response.data.administradoras);
                setTotal(parseInt(response.data.XCountRegister));
                setPage(pagina);
                if((!!txtChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                    setHasResult(false);
                }
            }

        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                setLoading(false);
                setLoadingSearch(false);
            }

        });
            
    }

    const cadastrarInfo = async (props, info) => {
        
        setModalShow(false);

        setLoading(true);

        let data = JSON.stringify({
            descricao: props.descricao
        });

        const requisicao = (props.id > 0) ? api.put : api.post;

        const url = (props.id > 0) ? `/administradora/${props.id}` : `/administradora`;
        
        await requisicao(url, data, {headers: authHeader()})
        .then(response => {

            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    setHasResult(true);
                    const resultado = response.data.administradoras[0];
                    if(props.id > 0) {
                        const updateOperadora = administradoras.filter((item) => item.id !== props.id);
                        setAdministradoras([...updateOperadora, {id: resultado.id, descricao: resultado.descricao} ]);
                        Swal.fire(
                            'Sucesso!',
                            'Operadora alterada com sucesso!',
                            'success'
                        );
                        limparInfo();
                    } else {
                        setAdministradoras([...administradoras, {id: resultado.id, descricao: resultado.descricao} ]);
                        setPage(1);
                        Swal.fire(
                            'Sucesso!',
                            'Operadora cadastrada com sucesso!',
                            'success'
                        );
                        
                        setTotal(parseInt(response.data.XCountRegister));
                    }                    
                    
                }

            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {
                setLoading(false);
            }

        });
        
    }

    const limparInfo = () => {
        setEnableValidation(false);
        setId(0);
        setDescricao('');
        setLabelBotao('Salvar');
    }

    const carregarAdministradora = (props) => {
        setId(props.id);
        setDescricao(props.descricao);
        setLabelBotao('Alterar');
        setModalShow(true);
        window.scrollTo(0, 0);
    }

    const excluirAdministradora = (props) => {
        
        limparInfo();
        
        Swal.fire({
            title: `Deseja excluir a classificação ${props.descricao}?`,
            text: `Está operação não poderá ser desfeita!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {

                removerAdministradora(props);
               
            }
        });

    }

    const removerAdministradora = async (props) => {
        
        setLoading(true);
        
        await api.delete(`/administradora/${props.id}`, {headers: authHeader()})
        .then(response => {
    
            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }
                    Swal.fire(
                        'Atenção!',
                        `${resultadoTexto}`,
                        'warning'
                    );
                    return false;                 
                }
                else
                {
                    Swal.fire(
                        'Sucesso!',
                        'Administradora removida com sucesso!',
                        'success'
                    );
                    setHasResult(true);
                    setAdministradoras(response.data.administradoras);
                    setTotal(parseInt(response.data.XCountRegister));
                    setTxtChanged('');
                    setPage(1);
                    clearFilters();
                    if((!!txtChanged === false) && parseInt(response.data.XCountRegister) === 0) {
                        setHasResult(false);
                    }

                }
                
            }

        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                
                setLoading(false);
            }
        });
        
    }

    const clearFilters = () => {
        setTxtChanged('');
    }    

    const exibirModal = () => {
        limparInfo();
        setEnableValidation(false);
        setModalShow(true);
    }

    const carregarPDF = async () => {
        
        setIsPdf(true);
        
        await api.get('getAllAdministradoras/', { 
            params: {
                page: 1,
                t: txtChanged
            },
            headers: authHeader()
        }).then( async (response) => {

            if(mountedRef.current) {   
                const blob = await pdf(<ExportPDF dados={response.data.administradoras} />).toBlob();
                saveAs(blob, "Listagem das Administradoras.pdf");
            }

        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {   

                setIsPdf(false);
            }

        });
        
    }

    const carregarXLS = async () => {
        
        setIsXLS(true);
        
        await api.get('getAllAdministradoras/', { 
            params: {
                page: 1,
                t: txtChanged
            },
            headers: authHeader()
        }).then( async (response) => {

            if(mountedRef.current) {

                const data = response.data.administradoras.map((row) => ({
                    descricao: row.descricao
                }));
                const csvData = objectToCSV(data);
                download(csvData);

            }

        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {
                
                setIsXLS(false);
            }

        });
            
    }

    const objectToCSV = (data) => {
        const csvRows = [];
        const headers =  Object.keys(data[0]);
        csvRows.push(headers.join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    }

    const download = (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Listagem das Administradoras.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    useEffect(() => {

        loadAdministradoras();
        return () => { mountedRef.current = false }

    }, []);

    return(
        (error) ? <PageError /> :
        <main>
            <section id="administradora">
            <Container fluid>
                <div className="toast-group">
                    <Toast style={{display: isPdf ? 'block' : 'none'}} >
                            <Toast.Header>
                                <strong className="mr-auto">Aguarde!</strong>
                            </Toast.Header>
                            <Toast.Body>
                                <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                /> Exportando PDF
                            </Toast.Body>
                        </Toast> 
                        <Toast style={{display: isXLS ? 'block' : 'none'}}>
                        <Toast.Header>
                            <strong className="mr-auto">Aguarde!</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            /> Exportando CSV
                        </Toast.Body>
                    </Toast>                
                </div>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Item eventKey="1" onClick={exibirModal}><FiPlusCircle />&nbsp;&nbsp;Adicionar</Dropdown.Item>
                                    {
                                    (administradoras.length === 0) ? <></> :
                                    <>
                                        <Dropdown.Item eventKey="2" onClick={carregarPDF}><FaFilePdf style={{color:'red'}} />&nbsp;&nbsp;Exportar PDF</Dropdown.Item>
                                        <Dropdown.Item eventKey="3" onClick={carregarXLS}><FaFileExcel style={{color:'green'}} />&nbsp;&nbsp;Exportar CSV</Dropdown.Item>
                                    </>
                                    }
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>                        
                        <Modal
                            animation={false}
                            show={modalShow}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            onHide={()=> false}
                            >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                {labelBotao === 'Alterar' ? 'Alterar' : 'Adicionar'} Operadora
                                </Modal.Title>
                            </Modal.Header>  
                        <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize={true} 
                            initialValues={{
                                id: id,
                                descricao: descricao,
                            }}
                            validationSchema={() => {
                                setEnableValidation(true);  
                                return Yup.object({
                                descricao: Yup.string()
                                        .required('Campo deve ser preenchido!')
                                        .matches(
                                        /^[A-Za-zÀ-ÿ0-9 ()_']+$/,
                                        "Não deve conter caracteres especiais"
                                        ),                                
                            })}}
                            onSubmit={cadastrarInfo}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Modal.Body>
                                <Form.Control 
                                    autoComplete="off" 
                                    readOnly={true}
                                    name="id" 
                                    type="hidden" 
                                    onChange={handleChange} 
                                    value={values.id}
                                    placeholder=""
                                />
                                <Form.Label>Descrição*</Form.Label>
                                <Form.Group controlId="field-administradora">
                                    <Form.Control 
                                        autoComplete="off" 
                                        name="descricao" 
                                        type="text" 
                                        onChange={handleChange} 
                                        value={values.descricao}
                                        isInvalid={!!errors.descricao}
                                        placeholder=""
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.descricao}</Form.Control.Feedback>
                                </Form.Group>
                                </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" size="sm" type="submit">
                                            <FiSave /> {labelBotao}
                                        </Button>
                                        <Button variant="primary" 
                                                size="sm" 
                                                onClick={() => {
                                                    resetForm();
                                                    limparInfo();
                                                }} 
                                                type="reset">
                                            <FiDelete /> Limpar
                                        </Button>
                                        <Button variant="secondary" 
                                                className="padrao-cancelar"
                                                size="sm" 
                                                onClick={() => setModalShow(false)}>
                                            <FaTimes /> Fechar
                                        </Button>
                                    </Modal.Footer>
                            </Form>
                            )}
                        </Formik>  
                        </Modal>                      
                            {
                                (hasResult) 
                                ?
                                <Fragment>
                                    <Table responsive hover size="md">
                                    <thead>
                                        <tr>
                                            <th>
                                            <Form.Group controlId="field-search">
                                            {loadingSearch ? <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        /> : <FiSearch />}
                                            <Form.Control 
                                                autoComplete="off" 
                                                type="text"
                                                placeholder="Administradora"
                                                aria-label="Administradora"
                                                maxLength={20}
                                                value={txtChanged}
                                                onChange={(e) => {
                                                    setTxtChanged(e.target.value.trim());
                                                    if(e.target.value === '') {
                                                        onTextChanged(e);
                                                    }
                                                }}
                                                onKeyPress={(e) => {
                                                    if(e.key === 'Enter') {
                                                        onTextChanged(e);
                                                    }
                                                }}
                                            />
                                            </Form.Group>
                                            </th>
                                            <th style={{width:"15px"}}></th>
                                            <th style={{width:"15px"}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(administradoras.length === 0) ? <><tr><td colSpan={3} style={{textAlign: 'center'}}>Nenhum resultado encontrado!</td></tr></> : <>
                                            {
                                                administradoras.sort((a,b) =>  +(a.descricao.toLowerCase() > b.descricao.toLowerCase()) || +(a.descricao.toLowerCase() === b.descricao.toLowerCase()) - 1).slice(0, 10).map((props) => {
                                                    return (
                                                    <tr key={props.id}>
                                                        <td>{props.descricao}</td>
                                                        <td>
                                                            <button className="botao" onClick={() => carregarAdministradora(props)}>
                                                                <FiEdit style={{color:'#638c5e', cursor: 'pointer'}} />
                                                            </button>
                                                        </td> 
                                                        <td>
                                                            <button className="botao" onClick={() => excluirAdministradora(props)}>
                                                                <FiTrash style={{color:'#c94646', cursor: 'pointer'}} />                                            
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            </>
                                        }
                                    </tbody>
                                    
                                    </Table>
                                    {
                                    (administradoras.length === 0) ? <></> :
                                        <>
                                        <Pagination
                                        nextPageText={<FaCaretRight size={19} />}
                                        prevPageText={<FaCaretLeft size={19}  />}
                                        lastPageText={<FaStepForward />}
                                        firstPageText={<FaStepBackward />}
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={5}
                                        onChange={(ev) => loadAdministradoras(ev, txtChanged)}
                                        />
                                        </>
                                    } 
                                </Fragment>    
                            :
                                !hasResult && !loading ? <NoResult /> : 'Loading...'
                            }
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    );
}

export default Administradora;