import React, { useContext } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './styles.css';
import homebackground from '../../assets/home-background.svg';
// import { authenticationService } from '../../_services';
import UserContext from '../../hooks/UserContext'; 

const Home = () => {

    const { user } = useContext(UserContext);
    // const usuario = authenticationService.currentUserValue;

    return (
        <main>
        <Container fluid>
            <div>
                <Row className="home-conteudo align-items-stretch">
                    <Col sm={12} md={9} lg={4} className="home-texto">
                        
                        <h3>Bem vindo(a), {user.charAt(0).toUpperCase() + user.slice(1).toLowerCase()}!</h3>
                        <p>O CS Seguros oferece para você corretor, uma facilidade para gerir suas propostas!</p>
                        <hr/>
                        <p>Entre em contato com o nosso suporte atraves do whatsapp: (81) 99721-0680</p>
                    </Col>
                    <Col  sm={12} md={9} lg={4} className="d-none d-lg-block">
                        <img src={homebackground} alt="Home Background" />
                    </Col>
                </Row>
            </div>
        </Container>
        </main>
    ); 
}

export default Home;