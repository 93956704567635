export function convertCurrencyEn(valor) {
    if(valor === '' || valor === undefined  || valor === null || valor === 'undefined' || valor === 0) return 0;
    valor = valor.replace(/[.]/g, "");
    valor = valor.replace(/[,]/g, ".");
    valor = parseFloat(valor);
    return valor;
}

export function convertCurrencyBr(valor) {
    
    return (valor > 0 && valor !== '') ? new Intl.NumberFormat([], {
        style: 'currency',
        currency: 'BRL'
    }).format(valor).split('R$ ')[1] : 0;
}