import React from 'react';
import { useParams } from "react-router-dom";
import Proposta from '../_components/proposta';


const PropostaPessoaJuridica = () => {
    
    // Breadcrumb 
    const lista = ['Home', 'Proposta', 'Pessoa Jurídica'];

    let { idContract } = useParams();

    return (
        <>
            <Proposta lista={lista} idContract={idContract} pfpj={2} />
        </>  
    );
}

export default PropostaPessoaJuridica;