import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import permissionNotAllowed from '../../assets/not_allowed.svg';

const NotAllowed = () => {
    return(
        <main>
        <Container fluid>
            <div>
                <Row className="justify-content-md-center">
                    <Col  sm={12} md={12} lg={12} className="d-none d-lg-block" style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                        <br></br>
                        <h3>Você não tem permissão para acessar esse recurso!</h3>
                        <br></br>
                        <img src={permissionNotAllowed} alt="Not Allowed" style={{width: '365px'}} />
                    </Col>
                </Row>
                <br></br>
                <br></br>
                <Row>
                    <Col sm={12} md={12} lg={12} className="d-none d-lg-block" style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                        <Link className="btn btn-primary" to="/home">Página Inicial</Link>
                    </Col>
                </Row>
            </div>
        </Container>
        </main>
    );
}

export default NotAllowed;