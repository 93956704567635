import { api } from '../_services';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const authenticationService = {
    login,
    logout,
    get currentUserValue () { return cookies.get('currentUser') },
    get currentTokenValue () { return JSON.stringify(cookies.get('temporalyToken')) },
    get currentPerfil () { return JSON.stringify(cookies.get('perfil')) },
};

function login(props) {

    const dadosLogin = {
        username: props.login,
        password: props.pass
    }

    const base = window.location.pathname.split('/')[1];
    
    api.interceptors.response.use(
        response => {
            return response
        },
        error => {
            if ([500].indexOf(error.response.status) !== -1) {
                localStorage.setItem('NETWORK-ERROR', "Por favor verifique sua conexão com a internet, caso o problema persista entre em contato com o administador do sistema!\n Rodrigo: +55 (81) 99203-4185 <br>Bruna: +55 (81) 99721-0680");
            }
            return Promise.reject(error)
        }
    );

    return api.post('doLogin/', JSON.stringify(dadosLogin), {
                    headers: {
                        'CONTEXT': base
                    }
               }).then(response => {
                    
                    if(response.data.user === undefined || JSON.stringify(response.data.token) === undefined) {
                        logout();
                    }
                    return response.data;

              }).catch((response) => {
                
                const error = (response.response.data.messages.error) || response.response.statusText;
                return Promise.reject(error);
              });
}

async function logout () {
    
    cookies.remove('temporalyToken', { path: '/' });
    
}
