import { createContext } from 'react';

const UserContext = createContext({
    user: '',
    setUser: (x) => {},
    perfil: 'Vendedor',
    setPerfil: (x) => {},
    uuidz: '',
    setUuidz: (x) => {},
});

export default UserContext;