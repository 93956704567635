import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Routes from './routes';

const App = () => {
  return (
    <Routes />
  );
}

export default App;
