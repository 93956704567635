import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { authenticationService, api } from './_services';
import Carregando from './_components/Carregando';
import ProtectedRoute from './protected.route';

import Main from './pages/main.js';

import Login from './pages/Login';
import Header from './pages/Header';
import Home from './pages/Home';
import Operadoras from './pages/Cadastros/Operadoras';
import Classificacao from './pages/Cadastros/Classificacao';
import Administradora from './pages/Cadastros/Administradora';
import ValorSaude from './pages/Cadastros/ValorSaude';
import ValorOdonto from './pages/Cadastros/ValorOdonto';
import Vendedores from './pages/Cadastros/Vendedores';
import Metas from './pages/Cadastros/Metas';
import Comissao from './pages/Cadastros/Comissao';
import NotFound from './pages/Errors/notFound';
import PropostaNotFound from './pages/Errors/propostaNotFound';
import NotAllowed from './pages/Errors/notAllowed';

import PropostaPessoaFisica from './pages/Propostas/PessoaFisica';
import PropostaPessoaJuridica from './pages/Propostas/PessoaJuridica';
import DetalheProposta from './pages/Propostas/Detalhe';
import ConsultarPropostas from './pages/Propostas/Consulta';

import TabelaValorSaude from './pages/Tabelas/Saude';
import Simulador from './pages/Simulador';
import ConsultarMetas from './pages/Metas';

import RelatorioVendas from './pages/Relatorios/Vendas';
import RankingVendas from './pages/Relatorios/Ranking';
import RelatorioVencimento from './pages/Relatorios/Vencimento';
import RelatorioComissao from './pages/Relatorios/Comissoes';
import RelatorioMetas from './pages/Relatorios/Metas';
import Aniversariantes from './pages/Relatorios/Aniversariantes';

import UserContext from './hooks/UserContext';
import PageError from './pages/Errors/pageError';

const Routes = (props) => {
    
    const base = window.location.pathname.split('/')[1];
    const [contexto, setContexto] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState('');
    const [perfil, setPerfil] = useState('');
    const [uuidz, setUuidz] = useState('');
    const pathNames = [ '',
                        'home',
                        'operadoras',
                        'classificacao',
                        'administradora',
                        'valor-saude',
                        'valor-odonto',
                        'vendedores',
                        'metas',
                        'comissao',
                        'consultar-metas',
                        'ranking-de-vendas',
                        'relatorio-de-vencimento',
                        'relatorio-de-comissao',
                        'relatorio-de-metas',
                        'aniversariantes',
                        'proposta-pessoa-fisica',
                        'editar-proposta-pessoa-fisica',
                        'detalhe-proposta-pessoa-fisica',
                        'proposta-pessoa-juridica',
                        'tabela-valor-saude',
                        'simulador',
                        'propostas',                        
                        'relatorio-de-vendas',
                        'erro-interno-servidor',
                        'proposta-nao-encontrado',
                        'detalhe-proposta-pessoa-fisica',
                        'detalhe-proposta-pessoa-juridica'];

    const NoExist = (props) => {
        
        if (!pathNames.includes(props.location.pathname.split('/')[1])) {
            return (
                <NotFound />
            )
        } else {
            return null;
        }

    }

    useEffect(() => {
        
        setLoading(true);
        
        if(!!base === true) {

            const validarContexto = async () => {
                await api.get(`validContext/`, {
                    headers: { 
                        'CONTEXT': base 
                    }
                }).then(resp => {
                    if(resp.data) {
                        setContexto(true);
                    }else {
                        setContexto(false);               
                    }
                }).finally( async (e) => {

                    if(base && authenticationService.currentTokenValue) {

                        const currentToken = authenticationService.currentTokenValue.substring(1,authenticationService.currentTokenValue.length - 1);
                        
                        await api.get(`getInfoVendedor/`, {
                            headers: { 
                                'Authorization': `Bearer `+currentToken,
                                'CONTEXT': base,
                            }
                        }).then(resp => {
                            setUser(resp.data[0].usuario);
                            setPerfil(resp.data[0].perfil);
                            setUuidz(resp.data[0].uuid);
                        }).catch(error => {
                            console.log("Erro interno do servidor, entre em contato com o administrador do sistema!");
                        })
                    }      
                    
                    setLoading(false);

                });
            }
            
            validarContexto();

        } else {
            setLoading(false);
        }

    }, [base, user]);

    if(!loading) {
        if(contexto) {
            return(
                <BrowserRouter basename={`/${base}/`}>
                    <Switch>
                        <UserContext.Provider value={{ user, setUser, perfil, setPerfil, uuidz, setUuidz }}>
                            <Route exact path="/" component={Login} />
                            <ProtectedRoute exact path="/home" component={ () => <><Header /><Home /></>} />
                            {
                            perfil === "Admin" ? 
                            <Fragment>
                            <ProtectedRoute exact path="/operadoras" component={ () => <><Header /><Operadoras /></>} />
                            <ProtectedRoute exact path="/classificacao" component={ () => <><Header /><Classificacao /></>} />
                            <ProtectedRoute exact path="/administradora" component={ () => <><Header /><Administradora /></>} />
                            <ProtectedRoute exact path="/valor-saude" component={ () => <><Header /><ValorSaude /></>} />
                            <ProtectedRoute exact path="/valor-odonto" component={ () => <><Header /><ValorOdonto /></>} />
                            <ProtectedRoute exact path="/vendedores" component={ () => <><Header /><Vendedores /></>} />
                            <ProtectedRoute exact path="/metas" component={ () => <><Header /><Metas /></>} />
                            <ProtectedRoute exact path="/comissao" component={ () => <><Header /><Comissao /></>} />
                            <ProtectedRoute exact path="/consultar-metas" component={ () => <><Header /><ConsultarMetas /></>} />
                            <ProtectedRoute exact path="/ranking-de-vendas" component={ () => <><Header /><RankingVendas /></>} />
                            <ProtectedRoute exact path="/relatorio-de-vencimento" component={ () => <><Header /><RelatorioVencimento /></>} />
                            <ProtectedRoute exact path="/relatorio-de-comissao" component={ () => <><Header /><RelatorioComissao /></>} />
                            <ProtectedRoute exact path="/relatorio-de-metas" component={ () => <><Header /><RelatorioMetas /></>} />
                            <ProtectedRoute exact path="/aniversariantes" component={ () => <><Header /><Aniversariantes /></>} />
                            </Fragment>
                            : 
                            <Fragment>
                            <ProtectedRoute exact path="/operadoras" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/classificacao" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/administradora" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/valor-saude" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/valor-odonto" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/vendedores" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/metas" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/comissao" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/consultar-metas" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/ranking-de-vendas" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/relatorio-de-vencimento" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/relatorio-de-comissao" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/relatorio-de-metas" component={ () => <NotAllowed />} />
                            <ProtectedRoute exact path="/aniversariantes" component={ () => <NotAllowed />} />
                            </Fragment>
                            }
                            <ProtectedRoute exact path="/proposta-pessoa-fisica" component={ () => <><Header /><PropostaPessoaFisica /></>} />
                            <ProtectedRoute exact path="/editar-proposta-pessoa-fisica/:idContract?" component={ () => <><Header /><PropostaPessoaFisica /></>} /> 
                            <ProtectedRoute exact path="/detalhe-proposta-pessoa-fisica/:id?" component={ () => <><Header /><DetalheProposta /></>} />
                            <ProtectedRoute exact path="/proposta-pessoa-juridica" component={ () => <><Header /><PropostaPessoaJuridica /></>} />
                            <ProtectedRoute exact path="/editar-proposta-pessoa-juridica/:idContract?" component={ () => <><Header /><PropostaPessoaJuridica /></>} />
                            <ProtectedRoute exact path="/detalhe-proposta-pessoa-juridica/:id?" component={ () => <><Header /><DetalheProposta /></>} />
                            <ProtectedRoute exact path="/propostas" component={ () => <><Header /><ConsultarPropostas /></>} />
                            <ProtectedRoute exact path="/tabela-valor-saude" component={ () => <><Header /><TabelaValorSaude /></>} />
                            <ProtectedRoute exact path="/simulador" component={ () => <><Header /><Simulador /></>} />
                            <ProtectedRoute exact path="/relatorio-de-vendas" component={ () => <><Header /><RelatorioVendas /></>} />
                            <ProtectedRoute exact path="/erro-interno-servidor" component={ () => <><Header /><PageError /></>} />
                            <ProtectedRoute exact path="/nao-encontrado" component={ () => <><Header /><NotFound /></>} />
                            <ProtectedRoute exact path="/proposta-nao-encontrado" component={ () => <><Header /><PropostaNotFound /></>} />
                            <Route path='*' render={(props) => <NoExist {...props} />} />
                        </UserContext.Provider>
                        
                    </Switch>
                </BrowserRouter>
            );
        }else {
            return(
                <BrowserRouter basename={`/${base}/`}>
                    <Switch>
                        <Route path="*" component={() => <Main /> } />
                    </Switch>
                </BrowserRouter>
            );
        }
    }else {
        return (
            <Carregando Loading={loading} />
        )
    }
}

export default Routes;

