import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { authenticationService, api } from './_services';
import { useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import UserContext from './hooks/UserContext';

const ProtectedRoute = ({ component: Component, ...rest }) => {
    
    const history = useHistory();
    const base = window.location.pathname.split('/')[1];
    const doLogout = () => {
        authenticationService.logout();
        history.push('/');
    }

    const { user, perfil, uuidz } = useContext(UserContext);

    return (
        <Route 
            {...rest} 
            render={props => {
                
                const currentUser = user;
                const currentUserToken = authenticationService.currentTokenValue;
                if (!currentUser || !currentUserToken) 
                {
                    doLogout();
                    return;
                }
                else
                {

                    const currentToken = authenticationService.currentTokenValue.substring(1,authenticationService.currentTokenValue.length - 1);
                    
                    try {
                        if(jwt.decode(currentToken) === null || !jwt.decode(currentToken)) {
                            doLogout();
                            return;    
                        }
                    }
                    catch(err)
                    {
                        doLogout();
                        return;
                    }                    
                    
                    //const CancelToken = axios.CancelToken;
                    //const source = CancelToken.source();

                    api.defaults.headers.common['Authorization'] = `Bearer `+currentToken;
                    api.defaults.headers.common['CONTEXT'] = base;
                    api.defaults.headers.common['Perfil'] = perfil;
                    api.defaults.headers.common['Uuid'] = uuidz;

                    api.interceptors.request.use(
                        request => {
                            
                            const auth = request.headers.common.Authorization;
                            const contexto = request.headers.common.CONTEXT;
                            if(!auth || !contexto) {
                                doLogout();  
                                return false;
                            }
                            
                            //request.params = {...request.params, cancelToken: source.token}
                            
                            return request;
                        }
                    )

                    api.interceptors.response.use(
                        response => {
                            return response
                        },
                        error => {
                            
                            if(error.response) {

                                if ([404].indexOf(error.response.status) !== -1) {
                                    console.log(error.response.data.messages.error);
                                    switch(error.response.data.messages.error) {
                                        case 'Proposta não encontrada!':
                                            history.push('/proposta-nao-encontrado');
                                            return;
                                        default:
                                            history.push('/nao-encontrado');
                                            return false;
                                    }
                                }
                                
                                if ([400].indexOf(error.response.status) !== -1) {
                                    return false;
                                }

                                if ([500].indexOf(error.response.status) !== -1) {
                                    history.push('/erro-interno-servidor');
                                    return false;
                                }

                                if ([403].indexOf(error.response.status) !== -1) {
                                    
                                    return false;
                                }
                                
                                if ([401].indexOf(error.response.status) !== -1) {
                                    console.log(error.response.data.messages.error);
                                    switch (error.response.data.messages.error) {
                                        case 'Expired token':
                                            localStorage.setItem('NETWORK-ERROR', "Sessão expirada!");
                                            return doLogout();
                                        case 'Não tem permissão de acesso!':
                                            localStorage.setItem('NETWORK-ERROR', "Não tem permissão de acesso!");
                                            return doLogout();
                                        case 'Usuário ou senha incorretos!':
                                            return localStorage.setItem('NETWORK-ERROR', "Usuário ou senha incorretos!");
                                        case 'Erro ao tentar entrar no sistema!':
                                            return localStorage.setItem('NETWORK-ERROR', "Erro ao tentar entrar no sistema!");
                                        case 'Signature verification failed':
                                            localStorage.setItem('NETWORK-ERROR', "Credenciais inválidas!");
                                            return doLogout();
                                        default:
                                            localStorage.setItem('NETWORK-ERROR', "Erro encontrado, tente novamente!");
                                            return doLogout();
                                    }
                                }

                            }

                            return Promise.reject(error)
                        }
                    );

                    return <Component {...props} />
                                  
                }
            }
        } />
    )
}

export default ProtectedRoute;