import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Table, Spinner, Dropdown, DropdownButton, Toast } from 'react-bootstrap';
import { FiSearch, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import { FaCaretRight, FaCaretLeft, FaStepBackward, FaStepForward, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import Pagination from "react-js-pagination";
import Arvore from '../../../_components/Arvore';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Carregando from '../../../_components/Carregando';
import './styles.css';
import ExportPDF from './exportPDF';
import { saveAs } from "file-saver";
import { pdf } from '@react-pdf/renderer';
import NoResult from '../../Errors/noResult';

const Consulta = () => {

    const [txtChangedNome           , setTxtChangedNome]           = useState('');
    const [txtChangedNumeroProposta , setTxtChangedNumeroProposta] = useState('');
    const [txtChangedOperadora      , setTxtChangedOperadora]      = useState('');
    const [txtChangedVendedor       , setTxtChangedVendedor]       = useState('');
    const [txtChangedCPFCNPJ        , setTxtChangedCPFCNPJ]        = useState('');
    const [propostas                , setPropostas]                = useState([]);
    const [total                    , setTotal]                    = useState(0);
    const [page                     , setPage]                     = useState(1);
    const [loading                  , setLoading]                  = useState(true);
    const [error                    , setError]                    = useState(false);
    const [isPdf                    , setIsPdf]                    = useState(false);
    const [isXLS                    , setIsXLS]                    = useState(false);  
    const [searching                , setSearching]                = useState(false);  
    const mountedRef                                               = useRef(true);

    // Breadcrumb 
    const lista = ['Home', 'Consulta', 'Propostas' ];

    function escapeRegexCharacters(str)
    {
        return str.replace(/[.*+?^${}()<>|[\]\\]/g, '\\$&');
    }

    function onTextChangedNumero(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        loadPropostas(1, valor, txtChangedOperadora, txtChangedVendedor, txtChangedCPFCNPJ, txtChangedNome);
        setTxtChangedNumeroProposta(valor);
        setSearching(true);
    }

    function onTextChangedNome(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        loadPropostas(1, txtChangedNumeroProposta, txtChangedOperadora, txtChangedVendedor, txtChangedCPFCNPJ, valor);
        setTxtChangedNome(valor);
        setSearching(true);
    }
    
    function onTextChangedOperadora(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        loadPropostas(1, txtChangedNumeroProposta, valor, txtChangedVendedor, txtChangedCPFCNPJ, txtChangedNome);
        setTxtChangedOperadora(valor);
        setSearching(true);
    }
    
    function onTextChangedVendedor(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        loadPropostas(1, txtChangedNumeroProposta, txtChangedOperadora, valor, txtChangedCPFCNPJ, txtChangedNome);
        setTxtChangedVendedor(valor);
        setSearching(true);
    }
    
    function onTextChangedCpfCnpj(e)
    {
        const valor = escapeRegexCharacters(e.target.value.trim());
        loadPropostas(1, txtChangedNumeroProposta, txtChangedOperadora, txtChangedVendedor, valor, txtChangedNome);
        setTxtChangedCPFCNPJ(valor);
        setSearching(true);
    }
    
    const loadPropostas = async (pagina = 1, txtChangedNumeroProposta, txtChangedOperadora, txtChangedVendedor, txtChangedCPFCNPJ, txtChangedNome) => {
        
        await api.get('proposta/', {
            params: {
                page: pagina,
                tn: txtChangedNome,
                tnp: txtChangedNumeroProposta,
                to: txtChangedOperadora,
                tv: txtChangedVendedor,
                tc: txtChangedCPFCNPJ
            },
            headers: authHeader()
        }).then((response) => {

            if(mountedRef.current) {
                setPropostas(response.data.propostas);
                setTotal(parseInt(response.data.XCountRegister));
                setPage(pagina);
            }

        }).catch(errors => {
            
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {

                setLoading(false);

            }

        });

    }

    const carregarPDF = async () => {

        setIsPdf(true);
        
        await api.get('getAllPropostas/', { 
            params: {
                t: txtChangedNome,
                tn: txtChangedNome,
                tnp: txtChangedNumeroProposta,
                to: txtChangedOperadora,
                tv: txtChangedVendedor,
                tc: txtChangedCPFCNPJ
            },
            headers: authHeader()
        }).then( async (response) => {
            
            if(mountedRef.current) {
                const blob = await pdf(<ExportPDF dados={response.data.propostas} />).toBlob();
                saveAs(blob, "Listagem das Propostas.pdf");
            }

        }).catch(errors => {
            
            setError(true);

        }).finally(e => {
            
            if(mountedRef.current) {

                setIsPdf(false);

            }

        });            
        
    }

    const carregarXLS = async () => {
        
        setIsXLS(true);
        
        await api.get('getAllPropostas/', { 
            params: {
                t: txtChangedNome,
                tn: txtChangedNome,
                tnp: txtChangedNumeroProposta,
                to: txtChangedOperadora,
                tv: txtChangedVendedor,
                tc: txtChangedCPFCNPJ
            },
            headers: authHeader()
        }).then( async (response) => {
            
            if(mountedRef.current) {
                const data = response.data.propostas.map((row) => ({
                    numero: ' '+row.numero_proposta,
                    data_venda: ' '+row.data_venda,
                    vendedor: ' '+row.vendedor,
                    operadora: ' '+row.operadora,
                    nome: ' '+row.nome,
                    cpf_cnpj: ' '+row.cpf_cnpj,
                    telefone: ' '+row.telefone,
                    celular: ' '+row.celular,
                    email: ' '+row.email,
                    valor: ' '+row.valor_proposta
                }));
                const csvData = objectToCSV(data);
                download(csvData);
            }

        }).catch(errors => {
            
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setIsXLS(false);

            }

        });

    }

    const objectToCSV = (data) => {
        const csvRows = [];
        const headers =  Object.keys(data[0]);
        csvRows.push(headers.join(','));
        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"');
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    }

    const download = (data) => {
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'Listagem das Propostas.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
  
    useEffect(() => {
        
        loadPropostas();       
        return () => { mountedRef.current = false } 

    }, []);

    return(
        (error) ? <></> :
        <main>
            <section id="propostas">
            <Container fluid>
                <div className="toast-group">
                    <Toast style={{display: isPdf ? 'block' : 'none'}} >
                        <Toast.Header>
                            <strong className="mr-auto">Aguarde!</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            /> Exportando PDF
                        </Toast.Body>
                    </Toast> 
                    <Toast style={{display: isXLS ? 'block' : 'none'}}>
                    <Toast.Header>
                        <strong className="mr-auto">Aguarde!</strong>
                    </Toast.Header>
                    <Toast.Body>
                        <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        /> Exportando CSV
                    </Toast.Body>
                </Toast>                
                </div>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    {
                                    (propostas.length === 0) ? 
                                    <Fragment>
                                        <Dropdown.Header><small>Proposta</small></Dropdown.Header>
                                        <Dropdown.Item as={Link} to={`/proposta-pessoa-fisica`} eventKey="1">
                                            <FiPlusCircle />&nbsp;&nbsp;Novo
                                        </Dropdown.Item>
                                    </Fragment> :
                                    <Fragment>
                                        <Dropdown.Item eventKey="1" onClick={carregarPDF}><FaFilePdf style={{color:'red'}} />&nbsp;&nbsp;Exportar PDF</Dropdown.Item>
                                        <Dropdown.Item eventKey="2" onClick={carregarXLS}><FaFileExcel style={{color:'green'}} />&nbsp;&nbsp;Exportar CSV</Dropdown.Item>
                                    </Fragment>
                                    }
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>
                            {
                            (propostas.length > 0 || (txtChangedNumeroProposta.length > 0 || txtChangedOperadora.length > 0 || txtChangedVendedor.length > 0 || txtChangedCPFCNPJ.length > 0 || txtChangedNome.length > 0)) 
                            ?
                                <Fragment>
                                    <Table responsive hover size="md">
                                        <thead>
                                            <tr>
                                                <th style={{minWidth:"170px", width:"170px"}}>
                                                    <Form.Group controlId="numero-proposta">
                                                        <FiSearch />
                                                        <Form.Control 
                                                            autoComplete="off" 
                                                            type="text"
                                                            placeholder="Nº da proposta"
                                                            aria-label="Nº da proposta"
                                                            maxLength={20}
                                                            onChange={onTextChangedNumero}
                                                        />
                                                    </Form.Group>
                                                </th>
                                                <th style={{minWidth:"170px"}}>
                                                    <Form.Group controlId="operadora">
                                                        <FiSearch />
                                                        <Form.Control 
                                                            autoComplete="off" 
                                                            type="text"
                                                            placeholder="Operadora"
                                                            aria-label="Operadora"
                                                            maxLength={20}
                                                            onChange={onTextChangedOperadora}
                                                        />
                                                    </Form.Group>
                                                </th>
                                                <th style={{minWidth:"170px"}}>
                                                    <Form.Group controlId="vendedor">
                                                        <FiSearch />
                                                        <Form.Control 
                                                            autoComplete="off" 
                                                            type="text"
                                                            placeholder="Vendedor"
                                                            aria-label="Vendedor"
                                                            maxLength={20}
                                                            onChange={onTextChangedVendedor}
                                                        />
                                                    </Form.Group>
                                                </th>
                                                <th style={{minWidth:"155px", width: "155px"}}>
                                                    <Form.Group controlId="cpfCnpj">
                                                        <FiSearch />
                                                        <Form.Control 
                                                            autoComplete="off" 
                                                            type="text"
                                                            placeholder="CPF/CNPJ"
                                                            aria-label="CPF/CNPJ"
                                                            maxLength={20}
                                                            onChange={onTextChangedCpfCnpj}
                                                        />
                                                    </Form.Group>
                                                </th>
                                                <th style={{minWidth:"200px"}}>
                                                    <Form.Group controlId="nome">
                                                        <FiSearch />
                                                        <Form.Control 
                                                            autoComplete="off" 
                                                            type="text"
                                                            placeholder="Nome/Nome Fantasia"
                                                            aria-label="Nome/Nome Fantasia"
                                                            maxLength={20}
                                                            onChange={onTextChangedNome}
                                                        />
                                                    </Form.Group>
                                                </th>
                                                <th style={{width:"15px"}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(!propostas[0] || propostas.length === 0) ? <><tr><td colSpan={6} style={{textAlign: 'center'}}>Nenhum resultado encontrado!</td></tr></> : <>
                                            {
                                                propostas.sort((a,b) => (b.data_venda - a.data_venda)).slice(0, 10).map((props) => {
                                                    return (
                                                    <tr key={props.id}>
                                                        <td>{props.numero_proposta}</td>
                                                        <td>{props.operadora}</td>
                                                        <td>{props.vendedor}</td>
                                                        <td>{props.cpf_cnpj}</td>
                                                        <td>{props.nome}</td>
                                                        <td>
                                                            <button className="botao">
                                                                <Link to={(props.pf_pj === '1') ? `/detalhe-proposta-pessoa-fisica/${props.uuidProposta}` : `/detalhe-proposta-pessoa-juridica/${props.uuidProposta}`}>
                                                                    <FiSearch style={{color:'#638c5e', cursor: 'pointer'}} />
                                                                </Link>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    )
                                                })
                                            }
                                            </>}
                                        </tbody>
                                    </Table>
                                    <Pagination
                                        nextPageText={<FaCaretRight size={19} />}
                                        prevPageText={<FaCaretLeft size={19}  />}
                                        lastPageText={<FaStepForward />}
                                        firstPageText={<FaStepBackward />}
                                        activePage={page}
                                        itemsCountPerPage={10}
                                        totalItemsCount={total}
                                        pageRangeDisplayed={5}
                                        onChange={(ev) => loadPropostas(ev, txtChangedNumeroProposta, txtChangedOperadora, txtChangedVendedor, txtChangedCPFCNPJ, txtChangedNome)}
                                    />
                                </Fragment>
                            :
                            propostas.length === 0 && !searching && !loading ? <NoResult /> : 'Loading...'
                            }
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    );
}

export default Consulta;