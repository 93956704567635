import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, DropdownButton } from 'react-bootstrap';
import { FiSave, FiDelete, FiCheckCircle, FiMoreHorizontal } from 'react-icons/fi';
import Arvore from '../../../_components/Arvore';
import { api } from '../../../_services';
import { authHeader } from '../../../_helpers/auth-header';
import Carregando from '../../../_components/Carregando';
import { Formik } from 'formik';
import Select from 'react-select';
import CurrencyInput from '../../../_components/CurrencyInput';
import Swal from "sweetalert2";
import NoResult from '../../Errors/noResult';
import PageError from '../../Errors/pageError';

import './styles.css';

const ValorSaude = () => {

    const [classif          , setClassif]          = useState([]);
    const [classifList      , setClassifList]      = useState([]);
    const [loading          , setLoading]          = useState(true);
    const [error            , setError]            = useState(false);
    const [percentual       , setPercentual]       = useState(0);
    const [idClassificacao  , setidClassificacao]  = useState(0);
    const [habilitarForm    , setHabilitarForm]    = useState(false);
    const [ccpe_enfermaria  , setccpe_enfermaria]  = useState(0);
    const [ccpe_com_parto   , setccpe_com_parto]   = useState(0);
    const [ccpe_sem_parto   , setccpe_sem_parto]   = useState(0);
    const [ccpe_com_dental  , setccpe_com_dental]  = useState(0);
    const [ccpe_sem_dental  , setccpe_sem_dental]  = useState(0);
    const [ccpa_apartamento , setccpa_apartamento] = useState(0);
    const [ccpa_com_parto   , setccpa_com_parto]   = useState(0);
    const [ccpa_sem_parto   , setccpa_sem_parto]   = useState(0);
    const [ccpa_com_dental  , setccpa_com_dental]  = useState(0);
    const [ccpa_sem_dental  , setccpa_sem_dental]  = useState(0);
    const [scpe_enfermaria  , setscpe_enfermaria]  = useState(0);
    const [scpe_com_parto   , setscpe_com_parto]   = useState(0);
    const [scpe_sem_parto   , setscpe_sem_parto]   = useState(0);
    const [scpe_com_dental  , setscpe_com_dental]  = useState(0);
    const [scpe_sem_dental  , setscpe_sem_dental]  = useState(0);
    const [scpa_apartamento , setscpa_apartamento] = useState(0);
    const [scpa_com_parto   , setscpa_com_parto]   = useState(0);
    const [scpa_sem_parto   , setscpa_sem_parto]   = useState(0);
    const [scpa_com_dental  , setscpa_com_dental]  = useState(0);
    const [scpa_sem_dental  , setscpa_sem_dental]  = useState(0);
    const [amb              , setamb]              = useState(0);
    const [CCOenf           , setCCOenf]           = useState({ CCOenf: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOenfComParto   , setCCOenfComParto]   = useState({ CCOenfComParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOenfSemParto   , setCCOenfSemParto]   = useState({ CCOenfSemParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOenfComDental  , setCCOenfComDental]  = useState({ CCOenfComDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOenfSemDental  , setCCOenfSemDental]  = useState({ CCOenfSemDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOapto          , setCCOapto]          = useState({ CCOapto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOaptoComParto  , setCCOaptoComParto]  = useState({ CCOaptoComParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOaptoSemParto  , setCCOaptoSemParto]  = useState({ CCOaptoSemParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOaptoComDental , setCCOaptoComDental] = useState({ CCOaptoComDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [CCOaptoSemDental , setCCOaptoSemDental] = useState({ CCOaptoSemDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOenf           , setSCOenf]           = useState({ SCOenf: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOenfComParto   , setSCOenfComParto]   = useState({ SCOenfComParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOenfSemParto   , setSCOenfSemParto]   = useState({ SCOenfSemParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOenfComDental  , setSCOenfComDental]  = useState({ SCOenfComDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOenfSemDental  , setSCOenfSemDental]  = useState({ SCOenfSemDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOapto          , setSCOapto]          = useState({ SCOapto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOaptoComParto  , setSCOaptoComParto]  = useState({ SCOaptoComParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOaptoSemParto  , setSCOaptoSemParto]  = useState({ SCOaptoSemParto: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOaptoComDental , setSCOaptoComDental] = useState({ SCOaptoComDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [SCOaptoSemDental , setSCOaptoSemDental] = useState({ SCOaptoSemDental: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const [ambulatorial     , setambulatorial]     = useState({ ambulatorial: { '0_a_18_anos': 0.00, '19_a_23_anos': 0.00, '24_a_28_anos': 0.00, '29_a_33_anos': 0.00, '34_a_38_anos': 0.00, '39_a_43_anos': 0.00, '44_a_48_anos': 0.00, '49_a_53_anos': 0.00, '54_a_58_anos': 0.00, '59_anos_+': 0.00 } });
    const mountedRef                               = useRef(true);
    
    const faixa_etaria = [
        '0_a_18_anos',
        '19_a_23_anos',
        '24_a_28_anos',
        '29_a_33_anos',
        '34_a_38_anos',
        '39_a_43_anos',
        '44_a_48_anos',
        '49_a_53_anos',
        '54_a_58_anos',
        '59_anos_+',
    ];

    // Breadcrumb 
    const lista = ['Home', 'Cadastro', 'Valor Saúde' ];

    const loadClassificacoes = async () => {
        
        await api.get('classificacaoValor/', {headers: authHeader()
        }).then((response) => {
            
            if(mountedRef.current) {

                const arrClassificacao = [];
                
                const selectClassificacao = response.data.classificacao.reduce((memo, value) => {
                    (memo[value.operadoraDesc] = memo[value.operadoraDesc] || []).push(value);
                    return memo;
                }, []);
                
                Object.entries(selectClassificacao).map((e, index) => {
                    
                    return arrClassificacao.push({
                        label: e[0],
                        options: e[1].map(x => {
                            return {
                                value : x.id,
                                label : x.descricao
                            }
                        })
                    });

                });
                
                setClassifList(arrClassificacao);
                setClassif(response.data.classificacao);

            }

        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }
            
        });            
        
    }

    const cancelarPercentual = () => {
        setLoading(true);
        carregarValorClassificacao(idClassificacao);
    }

    const currencyBrEn = (value) => {

        let validCurreny = /^-{0,1}\d*\.{0,1}\d+$/;
        
        if(!validCurreny.test(value))
        {
            return parseFloat(value.toString().replace(/[.]/g, "").replace(/[,]/g, "."));
        }
        else
        {   
            
            if(typeof value === 'string')
            {
                return parseFloat(value.toString().replace(/[.]/g, "").replace(/[,]/g, "."));
            }
            else
            {
                return parseFloat(value);
            }
            
        }   
        
    }

    const aplicarPercentual = (props) => {
        
        let valor = parseFloat(props.percentual.toString().replace(/[.]/g, "").replace(/[,]/g, "."));
        
        if(valor === '' || valor === 0.00 || valor === 0 || valor === undefined || valor === 'undefined' || isNaN(valor)){
            Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                html: 'Insira um valor maior do que zero!',
            });                    
        }
        else
        {
            Swal.fire({
                title: `Tem certeza que deseja aplicar a taxa?`,
                text: `Caso tenha alterado os valores e deseja aplicar a taxa de percentual, antes você deve clicar em salvar para depois aplicar a taxa.`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sim',
                cancelButtonText: 'Não'
              }).then((result) => {
                if (result.value) {
                    setLoading(true);
                    setTimeout(function(){
                        setCCOenf({
                            CCOenf: {
                            '0_a_18_anos' : currencyBrEn(props.CCOenf["0_a_18_anos"]) + (currencyBrEn(props.CCOenf["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOenf["19_a_23_anos"]) + (currencyBrEn(props.CCOenf["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOenf["24_a_28_anos"]) + (currencyBrEn(props.CCOenf["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOenf["29_a_33_anos"]) + (currencyBrEn(props.CCOenf["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOenf["34_a_38_anos"]) + (currencyBrEn(props.CCOenf["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOenf["39_a_43_anos"]) + (currencyBrEn(props.CCOenf["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOenf["44_a_48_anos"]) + (currencyBrEn(props.CCOenf["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOenf["49_a_53_anos"]) + (currencyBrEn(props.CCOenf["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOenf["54_a_58_anos"]) + (currencyBrEn(props.CCOenf["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOenf["59_anos_+"]) + (currencyBrEn(props.CCOenf["59_anos_+"]) * valor/100),
                            }
                        });

                        setCCOenfComParto({
                            CCOenfComParto: {
                            '0_a_18_anos'  : currencyBrEn(props.CCOenfComParto["0_a_18_anos"] ) + (currencyBrEn(props.CCOenfComParto["0_a_18_anos"]) * valor/100),
                            '19_a_23_anos' : currencyBrEn(props.CCOenfComParto["19_a_23_anos"]) + (currencyBrEn(props.CCOenfComParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos' : currencyBrEn(props.CCOenfComParto["24_a_28_anos"]) + (currencyBrEn(props.CCOenfComParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos' : currencyBrEn(props.CCOenfComParto["29_a_33_anos"]) + (currencyBrEn(props.CCOenfComParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos' : currencyBrEn(props.CCOenfComParto["34_a_38_anos"]) + (currencyBrEn(props.CCOenfComParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos' : currencyBrEn(props.CCOenfComParto["39_a_43_anos"]) + (currencyBrEn(props.CCOenfComParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos' : currencyBrEn(props.CCOenfComParto["44_a_48_anos"]) + (currencyBrEn(props.CCOenfComParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos' : currencyBrEn(props.CCOenfComParto["49_a_53_anos"]) + (currencyBrEn(props.CCOenfComParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos' : currencyBrEn(props.CCOenfComParto["54_a_58_anos"]) + (currencyBrEn(props.CCOenfComParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'    : currencyBrEn(props.CCOenfComParto["59_anos_+"]) + (currencyBrEn(props.CCOenfComParto["59_anos_+"]) * valor/100),
                            }
                        });

                        setCCOenfSemParto({
                            CCOenfSemParto: {
                            '0_a_18_anos' : currencyBrEn(props.CCOenfSemParto["0_a_18_anos"] ) + (currencyBrEn(props.CCOenfSemParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOenfSemParto["19_a_23_anos"]) + (currencyBrEn(props.CCOenfSemParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOenfSemParto["24_a_28_anos"]) + (currencyBrEn(props.CCOenfSemParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOenfSemParto["29_a_33_anos"]) + (currencyBrEn(props.CCOenfSemParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOenfSemParto["34_a_38_anos"]) + (currencyBrEn(props.CCOenfSemParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOenfSemParto["39_a_43_anos"]) + (currencyBrEn(props.CCOenfSemParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOenfSemParto["44_a_48_anos"]) + (currencyBrEn(props.CCOenfSemParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOenfSemParto["49_a_53_anos"]) + (currencyBrEn(props.CCOenfSemParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOenfSemParto["54_a_58_anos"]) + (currencyBrEn(props.CCOenfSemParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOenfSemParto["59_anos_+"]) + (currencyBrEn(props.CCOenfSemParto["59_anos_+"]) * valor/100),
                            }
                        });

                        setCCOenfComDental({
                            CCOenfComDental: {
                            '0_a_18_anos' : currencyBrEn(props.CCOenfComDental["0_a_18_anos"])  + (currencyBrEn(props.CCOenfComDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOenfComDental["19_a_23_anos"]) + (currencyBrEn(props.CCOenfComDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOenfComDental["24_a_28_anos"]) + (currencyBrEn(props.CCOenfComDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOenfComDental["29_a_33_anos"]) + (currencyBrEn(props.CCOenfComDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOenfComDental["34_a_38_anos"]) + (currencyBrEn(props.CCOenfComDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOenfComDental["39_a_43_anos"]) + (currencyBrEn(props.CCOenfComDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOenfComDental["44_a_48_anos"]) + (currencyBrEn(props.CCOenfComDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOenfComDental["49_a_53_anos"]) + (currencyBrEn(props.CCOenfComDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOenfComDental["54_a_58_anos"]) + (currencyBrEn(props.CCOenfComDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOenfComDental["59_anos_+"])    + (currencyBrEn(props.CCOenfComDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setCCOenfSemDental({
                            CCOenfSemDental: {
                            '0_a_18_anos' : currencyBrEn(props.CCOenfSemDental["0_a_18_anos"])  + (currencyBrEn(props.CCOenfSemDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOenfSemDental["19_a_23_anos"]) + (currencyBrEn(props.CCOenfSemDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOenfSemDental["24_a_28_anos"]) + (currencyBrEn(props.CCOenfSemDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOenfSemDental["29_a_33_anos"]) + (currencyBrEn(props.CCOenfSemDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOenfSemDental["34_a_38_anos"]) + (currencyBrEn(props.CCOenfSemDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOenfSemDental["39_a_43_anos"]) + (currencyBrEn(props.CCOenfSemDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOenfSemDental["44_a_48_anos"]) + (currencyBrEn(props.CCOenfSemDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOenfSemDental["49_a_53_anos"]) + (currencyBrEn(props.CCOenfSemDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOenfSemDental["54_a_58_anos"]) + (currencyBrEn(props.CCOenfSemDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOenfSemDental["59_anos_+"])    + (currencyBrEn(props.CCOenfSemDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setCCOapto({
                            CCOapto: {
                            '0_a_18_anos' : currencyBrEn(props.CCOapto["0_a_18_anos"])  + (currencyBrEn(props.CCOapto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOapto["19_a_23_anos"]) + (currencyBrEn(props.CCOapto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOapto["24_a_28_anos"]) + (currencyBrEn(props.CCOapto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOapto["29_a_33_anos"]) + (currencyBrEn(props.CCOapto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOapto["34_a_38_anos"]) + (currencyBrEn(props.CCOapto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOapto["39_a_43_anos"]) + (currencyBrEn(props.CCOapto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOapto["44_a_48_anos"]) + (currencyBrEn(props.CCOapto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOapto["49_a_53_anos"]) + (currencyBrEn(props.CCOapto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOapto["54_a_58_anos"]) + (currencyBrEn(props.CCOapto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOapto["59_anos_+"])  + (currencyBrEn(props.CCOapto["59_anos_+"])  * valor/100),
                            }
                        });

                        setCCOaptoComParto({
                            CCOaptoComParto: {
                            '0_a_18_anos' : currencyBrEn(props.CCOaptoComParto["0_a_18_anos"])  + (currencyBrEn(props.CCOaptoComParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOaptoComParto["19_a_23_anos"]) + (currencyBrEn(props.CCOaptoComParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOaptoComParto["24_a_28_anos"]) + (currencyBrEn(props.CCOaptoComParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOaptoComParto["29_a_33_anos"]) + (currencyBrEn(props.CCOaptoComParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOaptoComParto["34_a_38_anos"]) + (currencyBrEn(props.CCOaptoComParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOaptoComParto["39_a_43_anos"]) + (currencyBrEn(props.CCOaptoComParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOaptoComParto["44_a_48_anos"]) + (currencyBrEn(props.CCOaptoComParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOaptoComParto["49_a_53_anos"]) + (currencyBrEn(props.CCOaptoComParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOaptoComParto["54_a_58_anos"]) + (currencyBrEn(props.CCOaptoComParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOaptoComParto["59_anos_+"])    + (currencyBrEn(props.CCOaptoComParto["59_anos_+"])    * valor/100),
                            }
                        });

                        setCCOaptoSemParto({
                            CCOaptoSemParto: {
                            '0_a_18_anos' : currencyBrEn(props.CCOaptoSemParto["0_a_18_anos"])  + (currencyBrEn(props.CCOaptoSemParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOaptoSemParto["19_a_23_anos"]) + (currencyBrEn(props.CCOaptoSemParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOaptoSemParto["24_a_28_anos"]) + (currencyBrEn(props.CCOaptoSemParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOaptoSemParto["29_a_33_anos"]) + (currencyBrEn(props.CCOaptoSemParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOaptoSemParto["34_a_38_anos"]) + (currencyBrEn(props.CCOaptoSemParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOaptoSemParto["39_a_43_anos"]) + (currencyBrEn(props.CCOaptoSemParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOaptoSemParto["44_a_48_anos"]) + (currencyBrEn(props.CCOaptoSemParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOaptoSemParto["49_a_53_anos"]) + (currencyBrEn(props.CCOaptoSemParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOaptoSemParto["54_a_58_anos"]) + (currencyBrEn(props.CCOaptoSemParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOaptoSemParto["59_anos_+"])    + (currencyBrEn(props.CCOaptoSemParto["59_anos_+"])    * valor/100),
                            }
                        });

                        setCCOaptoComDental({
                            CCOaptoComDental: {
                            '0_a_18_anos' : currencyBrEn(props.CCOaptoComDental["0_a_18_anos"])  + (currencyBrEn(props.CCOaptoComDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOaptoComDental["19_a_23_anos"]) + (currencyBrEn(props.CCOaptoComDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOaptoComDental["24_a_28_anos"]) + (currencyBrEn(props.CCOaptoComDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOaptoComDental["29_a_33_anos"]) + (currencyBrEn(props.CCOaptoComDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOaptoComDental["34_a_38_anos"]) + (currencyBrEn(props.CCOaptoComDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOaptoComDental["39_a_43_anos"]) + (currencyBrEn(props.CCOaptoComDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOaptoComDental["44_a_48_anos"]) + (currencyBrEn(props.CCOaptoComDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOaptoComDental["49_a_53_anos"]) + (currencyBrEn(props.CCOaptoComDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOaptoComDental["54_a_58_anos"]) + (currencyBrEn(props.CCOaptoComDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOaptoComDental["59_anos_+"])    + (currencyBrEn(props.CCOaptoComDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setCCOaptoSemDental({
                            CCOaptoSemDental: {
                            '0_a_18_anos' : currencyBrEn(props.CCOaptoSemDental["0_a_18_anos"])  + (currencyBrEn(props.CCOaptoSemDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.CCOaptoSemDental["19_a_23_anos"]) + (currencyBrEn(props.CCOaptoSemDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.CCOaptoSemDental["24_a_28_anos"]) + (currencyBrEn(props.CCOaptoSemDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.CCOaptoSemDental["29_a_33_anos"]) + (currencyBrEn(props.CCOaptoSemDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.CCOaptoSemDental["34_a_38_anos"]) + (currencyBrEn(props.CCOaptoSemDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.CCOaptoSemDental["39_a_43_anos"]) + (currencyBrEn(props.CCOaptoSemDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.CCOaptoSemDental["44_a_48_anos"]) + (currencyBrEn(props.CCOaptoSemDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.CCOaptoSemDental["49_a_53_anos"]) + (currencyBrEn(props.CCOaptoSemDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.CCOaptoSemDental["54_a_58_anos"]) + (currencyBrEn(props.CCOaptoSemDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.CCOaptoSemDental["59_anos_+"])    + (currencyBrEn(props.CCOaptoSemDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOenf({
                            SCOenf: {
                            '0_a_18_anos' : currencyBrEn(props.SCOenf["0_a_18_anos"])  + (currencyBrEn(props.SCOenf["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOenf["19_a_23_anos"]) + (currencyBrEn(props.SCOenf["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOenf["24_a_28_anos"]) + (currencyBrEn(props.SCOenf["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOenf["29_a_33_anos"]) + (currencyBrEn(props.SCOenf["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOenf["34_a_38_anos"]) + (currencyBrEn(props.SCOenf["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOenf["39_a_43_anos"]) + (currencyBrEn(props.SCOenf["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOenf["44_a_48_anos"]) + (currencyBrEn(props.SCOenf["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOenf["49_a_53_anos"]) + (currencyBrEn(props.SCOenf["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOenf["54_a_58_anos"]) + (currencyBrEn(props.SCOenf["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOenf["59_anos_+"])    + (currencyBrEn(props.SCOenf["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOenfComParto({
                            SCOenfComParto: {
                            '0_a_18_anos' : currencyBrEn(props.SCOenfComParto["0_a_18_anos"])  + (currencyBrEn(props.SCOenfComParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOenfComParto["19_a_23_anos"]) + (currencyBrEn(props.SCOenfComParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOenfComParto["24_a_28_anos"]) + (currencyBrEn(props.SCOenfComParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOenfComParto["29_a_33_anos"]) + (currencyBrEn(props.SCOenfComParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOenfComParto["34_a_38_anos"]) + (currencyBrEn(props.SCOenfComParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOenfComParto["39_a_43_anos"]) + (currencyBrEn(props.SCOenfComParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOenfComParto["44_a_48_anos"]) + (currencyBrEn(props.SCOenfComParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOenfComParto["49_a_53_anos"]) + (currencyBrEn(props.SCOenfComParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOenfComParto["54_a_58_anos"]) + (currencyBrEn(props.SCOenfComParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOenfComParto["59_anos_+"])    + (currencyBrEn(props.SCOenfComParto["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOenfSemParto({
                            SCOenfSemParto: {
                            '0_a_18_anos' : currencyBrEn(props.SCOenfSemParto["0_a_18_anos"])  + (currencyBrEn(props.SCOenfSemParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOenfSemParto["19_a_23_anos"]) + (currencyBrEn(props.SCOenfSemParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOenfSemParto["24_a_28_anos"]) + (currencyBrEn(props.SCOenfSemParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOenfSemParto["29_a_33_anos"]) + (currencyBrEn(props.SCOenfSemParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOenfSemParto["34_a_38_anos"]) + (currencyBrEn(props.SCOenfSemParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOenfSemParto["39_a_43_anos"]) + (currencyBrEn(props.SCOenfSemParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOenfSemParto["44_a_48_anos"]) + (currencyBrEn(props.SCOenfSemParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOenfSemParto["49_a_53_anos"]) + (currencyBrEn(props.SCOenfSemParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOenfSemParto["54_a_58_anos"]) + (currencyBrEn(props.SCOenfSemParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOenfSemParto["59_anos_+"])    + (currencyBrEn(props.SCOenfSemParto["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOenfComDental({
                            SCOenfComDental: {
                            '0_a_18_anos' : currencyBrEn(props.SCOenfComDental["0_a_18_anos"])  + (currencyBrEn(props.SCOenfComDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOenfComDental["19_a_23_anos"]) + (currencyBrEn(props.SCOenfComDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOenfComDental["24_a_28_anos"]) + (currencyBrEn(props.SCOenfComDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOenfComDental["29_a_33_anos"]) + (currencyBrEn(props.SCOenfComDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOenfComDental["34_a_38_anos"]) + (currencyBrEn(props.SCOenfComDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOenfComDental["39_a_43_anos"]) + (currencyBrEn(props.SCOenfComDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOenfComDental["44_a_48_anos"]) + (currencyBrEn(props.SCOenfComDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOenfComDental["49_a_53_anos"]) + (currencyBrEn(props.SCOenfComDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOenfComDental["54_a_58_anos"]) + (currencyBrEn(props.SCOenfComDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOenfComDental["59_anos_+"])    + (currencyBrEn(props.SCOenfComDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOenfSemDental({
                            SCOenfSemDental: {
                            '0_a_18_anos' : currencyBrEn(props.SCOenfSemDental["0_a_18_anos"])  + (currencyBrEn(props.SCOenfSemDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOenfSemDental["19_a_23_anos"]) + (currencyBrEn(props.SCOenfSemDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOenfSemDental["24_a_28_anos"]) + (currencyBrEn(props.SCOenfSemDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOenfSemDental["29_a_33_anos"]) + (currencyBrEn(props.SCOenfSemDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOenfSemDental["34_a_38_anos"]) + (currencyBrEn(props.SCOenfSemDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOenfSemDental["39_a_43_anos"]) + (currencyBrEn(props.SCOenfSemDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOenfSemDental["44_a_48_anos"]) + (currencyBrEn(props.SCOenfSemDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOenfSemDental["49_a_53_anos"]) + (currencyBrEn(props.SCOenfSemDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOenfSemDental["54_a_58_anos"]) + (currencyBrEn(props.SCOenfSemDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOenfSemDental["59_anos_+"])    + (currencyBrEn(props.SCOenfSemDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOapto({
                            SCOapto: {
                            '0_a_18_anos' : currencyBrEn(props.SCOapto["0_a_18_anos"])  + (currencyBrEn(props.SCOapto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOapto["19_a_23_anos"]) + (currencyBrEn(props.SCOapto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOapto["24_a_28_anos"]) + (currencyBrEn(props.SCOapto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOapto["29_a_33_anos"]) + (currencyBrEn(props.SCOapto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOapto["34_a_38_anos"]) + (currencyBrEn(props.SCOapto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOapto["39_a_43_anos"]) + (currencyBrEn(props.SCOapto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOapto["44_a_48_anos"]) + (currencyBrEn(props.SCOapto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOapto["49_a_53_anos"]) + (currencyBrEn(props.SCOapto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOapto["54_a_58_anos"]) + (currencyBrEn(props.SCOapto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOapto["59_anos_+"])    + (currencyBrEn(props.SCOapto["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOaptoComParto({
                            SCOaptoComParto: {
                            '0_a_18_anos' : currencyBrEn(props.SCOaptoComParto["0_a_18_anos"])  + (currencyBrEn(props.SCOaptoComParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOaptoComParto["19_a_23_anos"]) + (currencyBrEn(props.SCOaptoComParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOaptoComParto["24_a_28_anos"]) + (currencyBrEn(props.SCOaptoComParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOaptoComParto["29_a_33_anos"]) + (currencyBrEn(props.SCOaptoComParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOaptoComParto["34_a_38_anos"]) + (currencyBrEn(props.SCOaptoComParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOaptoComParto["39_a_43_anos"]) + (currencyBrEn(props.SCOaptoComParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOaptoComParto["44_a_48_anos"]) + (currencyBrEn(props.SCOaptoComParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOaptoComParto["49_a_53_anos"]) + (currencyBrEn(props.SCOaptoComParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOaptoComParto["54_a_58_anos"]) + (currencyBrEn(props.SCOaptoComParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOaptoComParto["59_anos_+"])    + (currencyBrEn(props.SCOaptoComParto["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOaptoSemParto({
                            SCOaptoSemParto: {
                            '0_a_18_anos' : currencyBrEn(props.SCOaptoSemParto["0_a_18_anos"])  + (currencyBrEn(props.SCOaptoSemParto["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOaptoSemParto["19_a_23_anos"]) + (currencyBrEn(props.SCOaptoSemParto["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOaptoSemParto["24_a_28_anos"]) + (currencyBrEn(props.SCOaptoSemParto["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOaptoSemParto["29_a_33_anos"]) + (currencyBrEn(props.SCOaptoSemParto["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOaptoSemParto["34_a_38_anos"]) + (currencyBrEn(props.SCOaptoSemParto["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOaptoSemParto["39_a_43_anos"]) + (currencyBrEn(props.SCOaptoSemParto["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOaptoSemParto["44_a_48_anos"]) + (currencyBrEn(props.SCOaptoSemParto["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOaptoSemParto["49_a_53_anos"]) + (currencyBrEn(props.SCOaptoSemParto["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOaptoSemParto["54_a_58_anos"]) + (currencyBrEn(props.SCOaptoSemParto["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOaptoSemParto["59_anos_+"])    + (currencyBrEn(props.SCOaptoSemParto["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOaptoComDental({
                            SCOaptoComDental: {
                            '0_a_18_anos' : currencyBrEn(props.SCOaptoComDental["0_a_18_anos"])  + (currencyBrEn(props.SCOaptoComDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOaptoComDental["19_a_23_anos"]) + (currencyBrEn(props.SCOaptoComDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOaptoComDental["24_a_28_anos"]) + (currencyBrEn(props.SCOaptoComDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOaptoComDental["29_a_33_anos"]) + (currencyBrEn(props.SCOaptoComDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOaptoComDental["34_a_38_anos"]) + (currencyBrEn(props.SCOaptoComDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOaptoComDental["39_a_43_anos"]) + (currencyBrEn(props.SCOaptoComDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOaptoComDental["44_a_48_anos"]) + (currencyBrEn(props.SCOaptoComDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOaptoComDental["49_a_53_anos"]) + (currencyBrEn(props.SCOaptoComDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOaptoComDental["54_a_58_anos"]) + (currencyBrEn(props.SCOaptoComDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOaptoComDental["59_anos_+"])    + (currencyBrEn(props.SCOaptoComDental["59_anos_+"])    * valor/100),
                            }
                        });

                        setSCOaptoSemDental({
                            SCOaptoSemDental: {
                            '0_a_18_anos' : currencyBrEn(props.SCOaptoSemDental["0_a_18_anos"])  + (currencyBrEn(props.SCOaptoSemDental["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.SCOaptoSemDental["19_a_23_anos"]) + (currencyBrEn(props.SCOaptoSemDental["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.SCOaptoSemDental["24_a_28_anos"]) + (currencyBrEn(props.SCOaptoSemDental["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.SCOaptoSemDental["29_a_33_anos"]) + (currencyBrEn(props.SCOaptoSemDental["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.SCOaptoSemDental["34_a_38_anos"]) + (currencyBrEn(props.SCOaptoSemDental["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.SCOaptoSemDental["39_a_43_anos"]) + (currencyBrEn(props.SCOaptoSemDental["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.SCOaptoSemDental["44_a_48_anos"]) + (currencyBrEn(props.SCOaptoSemDental["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.SCOaptoSemDental["49_a_53_anos"]) + (currencyBrEn(props.SCOaptoSemDental["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.SCOaptoSemDental["54_a_58_anos"]) + (currencyBrEn(props.SCOaptoSemDental["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.SCOaptoSemDental["59_anos_+"])    + (currencyBrEn(props.SCOaptoSemDental["59_anos_+"])    * valor/100),
                            }
                        }); 

                        setambulatorial({
                            ambulatorial: {
                            '0_a_18_anos' : currencyBrEn(props.ambulatorial["0_a_18_anos"])  + (currencyBrEn(props.ambulatorial["0_a_18_anos"])  * valor/100),
                            '19_a_23_anos': currencyBrEn(props.ambulatorial["19_a_23_anos"]) + (currencyBrEn(props.ambulatorial["19_a_23_anos"]) * valor/100),
                            '24_a_28_anos': currencyBrEn(props.ambulatorial["24_a_28_anos"]) + (currencyBrEn(props.ambulatorial["24_a_28_anos"]) * valor/100),
                            '29_a_33_anos': currencyBrEn(props.ambulatorial["29_a_33_anos"]) + (currencyBrEn(props.ambulatorial["29_a_33_anos"]) * valor/100),
                            '34_a_38_anos': currencyBrEn(props.ambulatorial["34_a_38_anos"]) + (currencyBrEn(props.ambulatorial["34_a_38_anos"]) * valor/100),
                            '39_a_43_anos': currencyBrEn(props.ambulatorial["39_a_43_anos"]) + (currencyBrEn(props.ambulatorial["39_a_43_anos"]) * valor/100),
                            '44_a_48_anos': currencyBrEn(props.ambulatorial["44_a_48_anos"]) + (currencyBrEn(props.ambulatorial["44_a_48_anos"]) * valor/100),
                            '49_a_53_anos': currencyBrEn(props.ambulatorial["49_a_53_anos"]) + (currencyBrEn(props.ambulatorial["49_a_53_anos"]) * valor/100),
                            '54_a_58_anos': currencyBrEn(props.ambulatorial["54_a_58_anos"]) + (currencyBrEn(props.ambulatorial["54_a_58_anos"]) * valor/100),
                            '59_anos_+'   : currencyBrEn(props.ambulatorial["59_anos_+"])    + (currencyBrEn(props.ambulatorial["59_anos_+"])    * valor/100),
                            }
                        });
                        
                        setLoading(false);
                        setPercentual(0);
                    }, 1000);
                }
            });
        }
    }

    const cadastrarInfo = async (props, info) => {
        
        setLoading(true);
        
        let data = JSON.stringify({
            props
        });

        await api.post(`classificacaoValor`, data, {headers: authHeader()})
        .then(response => {

            if(mountedRef.current) {

                if(response.data.error !== undefined)
                {
                    let resultadoTexto = '';
                    if(response.data.error instanceof Object)
                    {
                        const texto = Object.values(response.data.error);
                        texto.map((e)=> ( resultadoTexto += e+'<br />' ));
                    }
                    else
                    {
                        resultadoTexto = response.data.error;
                    }

                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        html: resultadoTexto,
                    });                    
                }
                else
                {
                    
                    setCCOenf({
                        CCOenf: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenf),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenf),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenf),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenf),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenf),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenf),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenf),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenf),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenf),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenf),
                        }
                    });

                    setCCOenfComParto({
                        CCOenfComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfComParto),
                        }
                    });

                    setCCOenfSemParto({
                        CCOenfSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfSemParto),
                        }
                    });

                    setCCOenfComDental({
                        CCOenfComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfComDental),
                        }
                    });

                    setCCOenfSemDental({
                        CCOenfSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfSemDental),
                        }
                    });

                    setCCOapto({
                        CCOapto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOapto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOapto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOapto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOapto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOapto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOapto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOapto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOapto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOapto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOapto),
                        }
                    });

                    setCCOaptoComParto({
                        CCOaptoComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoComParto),
                        }
                    });

                    setCCOaptoSemParto({
                        CCOaptoSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoSemParto),
                        }
                    });

                    setCCOaptoComDental({
                        CCOaptoComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoComDental),
                        }
                    });

                    setCCOaptoSemDental({
                        CCOaptoSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoSemDental),
                        }
                    });

                    setSCOenf({
                        SCOenf: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenf),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenf),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenf),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenf),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenf),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenf),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenf),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenf),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenf),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenf),
                        }
                    });

                    setSCOenfComParto({
                        SCOenfComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfComParto),
                        }
                    });

                    setSCOenfSemParto({
                        SCOenfSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfSemParto),
                        }
                    });

                    setSCOenfComDental({
                        SCOenfComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfComDental),
                        }
                    });

                    setSCOenfSemDental({
                        SCOenfSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfSemDental),
                        }
                    });

                    setSCOapto({
                        SCOapto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOapto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOapto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOapto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOapto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOapto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOapto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOapto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOapto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOapto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOapto),
                        }
                    });

                    setSCOaptoComParto({
                        SCOaptoComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoComParto),
                        }
                    });

                    setSCOaptoSemParto({
                        SCOaptoSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoSemParto),
                        }
                    });

                    setSCOaptoComDental({
                        SCOaptoComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoComDental),
                        }
                    });

                    setSCOaptoSemDental({
                        SCOaptoSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoSemDental),
                        }
                    });

                    setambulatorial({
                        ambulatorial: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].ambulatorial),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].ambulatorial),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].ambulatorial),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].ambulatorial),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].ambulatorial),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].ambulatorial),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].ambulatorial),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].ambulatorial),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].ambulatorial),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].ambulatorial),
                        }
                    });
                    
                    Swal.fire({
                        title: `Tabela Plano de Saúde`,
                        text: `Valores alterados com sucesso!`,
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok',
                        cancelButtonText: 'Não'
                        }).then((result) => {
                        if (result.value) {
                            setTimeout(function(){ 
                                window.scrollTo(0, 0);
                            }, 500);        
                        }
                    });
                }

            }
            
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }

        });
        
    }

    const ChangeClassificacao = (e) => {
                
        setLoading(true);
        
        if(parseInt(e.value) === 0) 
        {
            setHabilitarForm(false);
            setLoading(false);
        }
        
        let classifOnOff = classif.filter(x => x.id === e.value);
        
        setccpe_enfermaria(classifOnOff[0].ccpe_enfermaria);
        setccpe_com_parto(classifOnOff[0].ccpe_com_parto);
        setccpe_sem_parto(classifOnOff[0].ccpe_sem_parto);
        setccpe_com_dental(classifOnOff[0].ccpe_com_dental);
        setccpe_sem_dental(classifOnOff[0].ccpe_sem_dental);
        setccpa_apartamento(classifOnOff[0].ccpa_apartamento);
        setccpa_com_parto(classifOnOff[0].ccpa_com_parto);
        setccpa_sem_parto(classifOnOff[0].ccpa_sem_parto);
        setccpa_com_dental(classifOnOff[0].ccpa_com_dental);
        setccpa_sem_dental(classifOnOff[0].ccpa_sem_dental);
        setscpe_enfermaria(classifOnOff[0].scpe_enfermaria);
        setscpe_com_parto(classifOnOff[0].scpe_com_parto);
        setscpe_sem_parto(classifOnOff[0].scpe_sem_parto);
        setscpe_com_dental(classifOnOff[0].scpe_com_dental);
        setscpe_sem_dental(classifOnOff[0].scpe_sem_dental);
        setscpa_apartamento(classifOnOff[0].scpa_apartamento);
        setscpa_com_parto(classifOnOff[0].scpa_com_parto);
        setscpa_sem_parto(classifOnOff[0].scpa_sem_parto);
        setscpa_com_dental(classifOnOff[0].scpa_com_dental);
        setscpa_sem_dental(classifOnOff[0].scpa_sem_dental);
        setamb(classifOnOff[0].amb);
        setHabilitarForm(true);
        setidClassificacao(e.value); 
        carregarValorClassificacao(e.value);
    }

    const carregarValorClassificacao = async (valor) => {
        
        await api.get(`getClassificacoesValor/${valor}`, { headers: authHeader()
        }).then((response) => {
            
            if(mountedRef.current) {

                if(response.data.classificacaoValor.length > 0)
                {
                    setCCOenf({
                        CCOenf: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenf),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenf),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenf),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenf),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenf),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenf),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenf),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenf),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenf),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenf),
                        }
                    });

                    setCCOenfComParto({
                        CCOenfComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfComParto),
                        }
                    });

                    setCCOenfSemParto({
                        CCOenfSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfSemParto),
                        }
                    });

                    setCCOenfComDental({
                        CCOenfComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfComDental),
                        }
                    });

                    setCCOenfSemDental({
                        CCOenfSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOenfSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOenfSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOenfSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOenfSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOenfSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOenfSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOenfSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOenfSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOenfSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOenfSemDental),
                        }
                    });

                    setCCOapto({
                        CCOapto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOapto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOapto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOapto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOapto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOapto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOapto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOapto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOapto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOapto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOapto),
                        }
                    });

                    setCCOaptoComParto({
                        CCOaptoComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoComParto),
                        }
                    });

                    setCCOaptoSemParto({
                        CCOaptoSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoSemParto),
                        }
                    });

                    setCCOaptoComDental({
                        CCOaptoComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoComDental),
                        }
                    });

                    setCCOaptoSemDental({
                        CCOaptoSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].CCOaptoSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].CCOaptoSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].CCOaptoSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].CCOaptoSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].CCOaptoSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].CCOaptoSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].CCOaptoSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].CCOaptoSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].CCOaptoSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].CCOaptoSemDental),
                        }
                    });

                    setSCOenf({
                        SCOenf: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenf),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenf),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenf),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenf),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenf),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenf),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenf),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenf),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenf),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenf),
                        }
                    });

                    setSCOenfComParto({
                        SCOenfComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfComParto),
                        }
                    });

                    setSCOenfSemParto({
                        SCOenfSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfSemParto),
                        }
                    });

                    setSCOenfComDental({
                        SCOenfComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfComDental),
                        }
                    });

                    setSCOenfSemDental({
                        SCOenfSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOenfSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOenfSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOenfSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOenfSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOenfSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOenfSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOenfSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOenfSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOenfSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOenfSemDental),
                        }
                    });

                    setSCOapto({
                        SCOapto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOapto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOapto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOapto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOapto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOapto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOapto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOapto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOapto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOapto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOapto),
                        }
                    });

                    setSCOaptoComParto({
                        SCOaptoComParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoComParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoComParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoComParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoComParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoComParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoComParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoComParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoComParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoComParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoComParto),
                        }
                    });

                    setSCOaptoSemParto({
                        SCOaptoSemParto: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoSemParto),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoSemParto),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoSemParto),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoSemParto),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoSemParto),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoSemParto),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoSemParto),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoSemParto),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoSemParto),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoSemParto),
                        }
                    });

                    setSCOaptoComDental({
                        SCOaptoComDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoComDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoComDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoComDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoComDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoComDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoComDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoComDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoComDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoComDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoComDental),
                        }
                    });

                    setSCOaptoSemDental({
                        SCOaptoSemDental: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].SCOaptoSemDental),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].SCOaptoSemDental),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].SCOaptoSemDental),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].SCOaptoSemDental),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].SCOaptoSemDental),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].SCOaptoSemDental),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].SCOaptoSemDental),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].SCOaptoSemDental),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].SCOaptoSemDental),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].SCOaptoSemDental),
                        }
                    });

                    setambulatorial({
                        ambulatorial: {
                        '0_a_18_anos' : parseFloat(response.data.classificacaoValor[0].ambulatorial),
                        '19_a_23_anos': parseFloat(response.data.classificacaoValor[1].ambulatorial),
                        '24_a_28_anos': parseFloat(response.data.classificacaoValor[2].ambulatorial),
                        '29_a_33_anos': parseFloat(response.data.classificacaoValor[3].ambulatorial),
                        '34_a_38_anos': parseFloat(response.data.classificacaoValor[4].ambulatorial),
                        '39_a_43_anos': parseFloat(response.data.classificacaoValor[5].ambulatorial),
                        '44_a_48_anos': parseFloat(response.data.classificacaoValor[6].ambulatorial),
                        '49_a_53_anos': parseFloat(response.data.classificacaoValor[7].ambulatorial),
                        '54_a_58_anos': parseFloat(response.data.classificacaoValor[8].ambulatorial),
                        '59_anos_+'   : parseFloat(response.data.classificacaoValor[9].ambulatorial),
                        }
                    });

                }
                else
                {
                    setCCOenf({
                        CCOenf: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });
                    
                    setCCOenfComParto({
                        CCOenfComParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOenfSemParto({
                        CCOenfSemParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOenfComDental({
                        CCOenfComDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOenfSemDental({
                        CCOenfSemDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOapto({
                        CCOapto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOaptoComParto({
                        CCOaptoComParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOaptoSemParto({
                        CCOaptoSemParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOaptoComDental({
                        CCOaptoComDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setCCOaptoSemDental({
                        CCOaptoSemDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOenf({
                        SCOenf: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOenfComParto({
                        SCOenfComParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOenfSemParto({
                        SCOenfSemParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOenfComDental({
                        SCOenfComDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOenfSemDental({
                        SCOenfSemDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOapto({
                        SCOapto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOaptoComParto({
                        SCOaptoComParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOaptoSemParto({
                        SCOaptoSemParto: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOaptoComDental({
                        SCOaptoComDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setSCOaptoSemDental({
                        SCOaptoSemDental: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });

                    setambulatorial({
                        ambulatorial: {
                        '0_a_18_anos' : 0,
                        '19_a_23_anos': 0,
                        '24_a_28_anos': 0,
                        '29_a_33_anos': 0,
                        '34_a_38_anos': 0,
                        '39_a_43_anos': 0,
                        '44_a_48_anos': 0,
                        '49_a_53_anos': 0,
                        '54_a_58_anos': 0,
                        '59_anos_+'   : 0,
                        }
                    });
                }

            }
        
        }).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }

        });

    }

    useEffect(() => {
        
        loadClassificacoes();
        document.addEventListener("keydown", function (event) {
            if(event.key === "Enter") {
                event.preventDefault();
            }
        });
        return () => { mountedRef.current = false }

    }, []);

    return(
        (error) ? <PageError /> :
        <main>
            <section id="valorsaude">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    disabled={true}
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                </DropdownButton>
                            </div>
                        </div>
                        <Card.Body>                        
                        <Formik
                            enableReinitialize={true} 
                            initialValues={{
                                idClassificacao  : idClassificacao,
                                percentual       : percentual,
                                CCOenf           : CCOenf.CCOenf,
                                CCOenfComParto   : CCOenfComParto.CCOenfComParto,
                                CCOenfSemParto   : CCOenfSemParto.CCOenfSemParto,
                                CCOenfComDental  : CCOenfComDental.CCOenfComDental,
                                CCOenfSemDental  : CCOenfSemDental.CCOenfSemDental,
                                CCOapto          : CCOapto.CCOapto,
                                CCOaptoComParto  : CCOaptoComParto.CCOaptoComParto,
                                CCOaptoSemParto  : CCOaptoSemParto.CCOaptoSemParto,
                                CCOaptoComDental : CCOaptoComDental.CCOaptoComDental,
                                CCOaptoSemDental : CCOaptoSemDental.CCOaptoSemDental,
                                SCOenf           : SCOenf.SCOenf,
                                SCOenfComParto   : SCOenfComParto.SCOenfComParto,
                                SCOenfSemParto   : SCOenfSemParto.SCOenfSemParto,
                                SCOenfComDental  : SCOenfComDental.SCOenfComDental,
                                SCOenfSemDental  : SCOenfSemDental.SCOenfSemDental,
                                SCOapto          : SCOapto.SCOapto,
                                SCOaptoComParto  : SCOaptoComParto.SCOaptoComParto,
                                SCOaptoSemParto  : SCOaptoSemParto.SCOaptoSemParto,
                                SCOaptoComDental : SCOaptoComDental.SCOaptoComDental,
                                SCOaptoSemDental : SCOaptoSemDental.SCOaptoSemDental,
                                ambulatorial     : ambulatorial.ambulatorial,
                            }}
                            onSubmit={cadastrarInfo}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Form.Group controlId="select-idClassificacao">
                                            <Form.Label>Classificação*</Form.Label>
                                            <Select 
                                                className={`select-default`}
                                                options={classifList} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ChangeClassificacao}
                                                value={values.idClassificacao > 0 ? classifList.find(obj => obj.value === values.idClassificacao) : ''} 
                                                name="idClassificacao" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                            {
                            (habilitarForm) ? 

                                (parseInt(ccpe_enfermaria.toString()) === 0 &&
                                parseInt(ccpa_apartamento.toString()) === 0 && 
                                parseInt(scpe_enfermaria.toString()) === 0 && 
                                parseInt(scpa_apartamento.toString()) === 0 && 
                                parseInt(amb.toString()) === 0) ? '' :

                                <Fragment>
                                    <Form.Group controlId="field-percentual">
                                        <Form.Label>Taxa percentual</Form.Label>
                                        <CurrencyInput 
                                            autoComplete="off"
                                            className="form-control" 
                                            name="percentual" 
                                            type="text" 
                                            onBlur={handleChange}
                                            placeholder="" 
                                            value={values.percentual.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                        />
                                        <small><b>Após aplicar a taxa de percentual, conferir os valores e clicar no botão salvar, caso tenha errado poderá voltar os valores clicando em cancelar!</b></small>
                                    </Form.Group>

                                    <Button variant="secondary" className="btn-aplicar" size="sm" type="button" onClick={() => aplicarPercentual(values)}> 
                                    <FiCheckCircle /> Aplicar 
                                    </Button>
                                    <Button 
                                        size="sm" 
                                        className="padrao-cancelar"
                                        onClick={cancelarPercentual} 
                                        type="button">
                                    <FiDelete /> Cancelar 
                                    </Button>
                                    <br/><br/>
                                   
                                    <Table responsive size="md">
                                        <thead>
                                            <tr>
                                                <th>Faixa Etária</th>
                                                {(parseInt(ccpe_enfermaria.toString()) === 1) ? <th>Com Co-Participação<br></br>Enfermaria</th> : <></>}
                                                {(parseInt(ccpa_apartamento.toString()) === 1) ? <th>Com Co-Participação<br></br>Apartamento</th> : <></>}
                                                {(parseInt(scpe_enfermaria.toString()) === 1) ? <th>Sem Co-Participação<br></br>Enfermaria</th> : <></>}
                                                {(parseInt(scpa_apartamento.toString()) === 1) ? <th>Sem Co-Participação<br></br>Apartamento</th> : <></>}
                                                {(parseInt(amb.toString()) === 1) ? <th>Ambulatorial</th> : <></>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                faixa_etaria.map((props, index) => {
                                                    return(
                                                    <tr key={props}> 
                                                        <td>{props.split('_').join(' ')}</td> 
                                                        {
                                                            (parseInt(ccpe_enfermaria.toString()) === 1) ? 
                                                            <td>
                                                                <Form.Group controlId={`field-CCOenf_${props}`}>
                                                                    <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`CCOenf.${props}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onBlur={handleChange}
                                                                        placeholder="" 
                                                                        value={values.CCOenf[`${props}`].toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {
                                                            (parseInt(ccpa_apartamento.toString()) === 1) ? 
                                                            <td>
                                                                <Form.Group controlId={`field-CCOapto_${props}`}>
                                                                    <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`CCOapto.${props}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onBlur={handleChange}
                                                                        placeholder="" 
                                                                        value={values.CCOapto[`${props}`].toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {
                                                            (parseInt(scpe_enfermaria.toString()) === 1) ? 
                                                            <td>
                                                                <Form.Group controlId={`field-SCOenf_${props}`}>
                                                                    <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`SCOenf.${props}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onBlur={handleChange}
                                                                        placeholder="" 
                                                                        value={values.SCOenf[`${props}`].toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            : <></>
                                                        }
                                                        {
                                                            (parseInt(scpa_apartamento.toString()) === 1) ?
                                                            <td>
                                                                <Form.Group controlId={`field-SCOapto_${props}`}>
                                                                    <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`SCOapto.${props}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onBlur={handleChange}
                                                                        placeholder="" 
                                                                        value={values.SCOapto[`${props}`].toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                            : <></>
                                                        }
                                                        { (parseInt(amb.toString()) === 0) ? null :
                                                            <td>
                                                                <Form.Group controlId={`field-ambulatorial_${props}`}>
                                                                    <CurrencyInput 
                                                                        autoComplete="off" 
                                                                        className="form-control"
                                                                        name={`ambulatorial.${props}`}
                                                                        type="text" 
                                                                        guide={false}
                                                                        onBlur={handleChange}
                                                                        placeholder="" 
                                                                        value={values.ambulatorial[`${props}`].toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                                                                    />
                                                                </Form.Group>
                                                            </td>
                                                        }
                                                    </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                        </Table>
                                   

                                    <Button variant="secondary" size="sm" type="submit" className="btn-salvar">
                                        <FiSave /> Salvar
                                    </Button>
                                </Fragment>
                                
                            : '' }
                             </Form>
                            )}
                        </Formik>  
                        </Card.Body>
                        </Card>
                        {
                             (habilitarForm && (parseInt(scpa_apartamento.toString()) === 0 &&
                             parseInt(scpa_com_parto.toString()) === 0 && 
                             parseInt(scpa_sem_parto.toString()) === 0 && 
                             parseInt(scpa_com_dental.toString()) === 0 && 
                             parseInt(scpa_sem_dental.toString()) === 0 &&
                             parseInt(scpe_enfermaria.toString()) === 0 &&
                             parseInt(scpe_com_parto.toString()) === 0 && 
                             parseInt(scpe_sem_parto.toString()) === 0 && 
                             parseInt(scpe_com_dental.toString()) === 0 && 
                             parseInt(scpe_sem_dental.toString()) === 0 &&
                             parseInt(ccpa_apartamento.toString()) === 0 &&
                             parseInt(ccpa_com_parto.toString()) === 0 && 
                             parseInt(ccpa_sem_parto.toString()) === 0 && 
                             parseInt(ccpa_com_dental.toString()) === 0 && 
                             parseInt(ccpa_sem_dental.toString()) === 0 &&
                             parseInt(ccpe_enfermaria.toString()) === 0 &&
                             parseInt(ccpe_com_parto.toString()) === 0 && 
                             parseInt(ccpe_sem_parto.toString()) === 0 && 
                             parseInt(ccpe_com_dental.toString()) === 0 && 
                             parseInt(ccpe_sem_dental.toString()) === 0 &&
                             parseInt(amb.toString()) === 0)) ? 
                        
                        <Card style={{marginTop: '20px', padding: '25px'}}>
                            <Card.Body>
                                <NoResult />
                            </Card.Body>
                        </Card>
                        : 
                            ''
                        }
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    );
}

export default ValorSaude;