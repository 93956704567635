import React from 'react';
// import Spinner from 'react-bootstrap/Spinner';
import './styles.css';

const Carregando = (props) =>
{
    if(!props.Loading)
    {
        return (<></>)
    }
    return(
        <div className="loading">
            <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
            </div>
            {/* <Spinner animation="border" variant="dark" />  
            <div className="text">{(props.title !== undefined) ? props.title : 'Carregando' }</div> */}
        </div>
    )
}

export default Carregando;