import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useHistory, useParams, Link } from "react-router-dom";
import { Container, Row, Col, Card, Dropdown, DropdownButton, Tabs, Tab, Spinner } from 'react-bootstrap';
import { FiTrash, FiMoreHorizontal, FiEdit, FiUser, FiUsers, FiDollarSign, FiLayers, FiFile, FiPlusCircle, FiList, FiCheck, FiX } from 'react-icons/fi';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText, AiOutlineFile, AiOutlineFileWord } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import {useDropzone} from 'react-dropzone';
import Arvore from '../../../_components/Arvore';
import Carregando from '../../../_components/Carregando';
import axios from 'axios';
import { api } from '../../../_services';
import { saveAs } from "file-saver";
import { authHeader } from '../../../_helpers/auth-header';
import Swal from "sweetalert2";
import './styles.css';
import empty from '../../../assets/no-data.svg';
import PageError from '../../Errors/pageError';
import PropostaNotFound from '../../Errors/propostaNotFound';

const Detalhe = (props) => {

    const { id } = useParams();
    let history = useHistory();

    const [proposta, setProposta] = useState([]);
    const [dependentes, setDependentes] = useState([]);
    const [comissao, setComissao] = useState([]);
    const [arquivos, setArquivos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [propostaNotFound, setPropostaNotFound] = useState(false);
    const refDownloadingFile = useRef([]);
    const mountedRef = useRef(true);

    // Breadcrumb 
    const lista = ['Home', 'Proposta', `Pessoa ${parseInt(proposta.tipo) === 1 ? 'Física' : 'Jurídica'} ${!proposta.nome ? '' : proposta.nome}`];

    const loadInfo = async (id) => {
    
        setLoading(true);

        let proposta = `/proposta/${id}`;
        let arquivos = `/getFiles/`;

        const requestProposta = await api.get(proposta, { params: { page: 1, t: '' }, headers: authHeader() });
        const requestArquivos = await api.get(arquivos, { headers: Object.assign(authHeader(), { 'IDENTIFIER': id })});
        
        axios.all([requestProposta, requestArquivos]).then(axios.spread((...responses) => {
            
            if(mountedRef.current) {

                if((parseInt(requestProposta.data.proposta[0].pf_pj) === 1 && window.location.pathname.split('/')[2] === "detalhe-proposta-pessoa-fisica") || (parseInt(requestProposta.data.proposta[0].pf_pj) === 2 && window.location.pathname.split('/')[2] === "detalhe-proposta-pessoa-juridica")) {
                    
                    const requestProposta = responses[0];
                    const requestArquivos = responses[1];

                    setArquivos(requestArquivos.data);
                    setProposta(requestProposta.data.proposta[0]);
                    setComissao(requestProposta.data.comissao);
                    setDependentes(requestProposta.data.dependente);

                } else {
                    setPropostaNotFound(true);
                }

            }

        })).catch(errors => {

            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);
            }

        });
         
    }

    const {getRootProps, getInputProps} = useDropzone({
        
        accept: 'image/jpg, image/jpeg, image/gif, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/plain',
        onDrop: async function(acceptedFiles){
            setLoadingFiles(true);
            if(acceptedFiles.length) {
                
                const formData = new FormData();
                formData.append('id', id);
                acceptedFiles.forEach((file) => {
                    formData.append('file[]', file);
                });
                await api.post(`/uploadFiles/`, formData, {
                    headers: Object.assign(authHeader(), { 'IDENTIFIER': id }, {'content-type': 'multipart/form-data'})
                    
                }).then(resp => {
                    
                    if(resp.data.error) {
                        Swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                html: resp.data.error,
                        });                    
                    }else {
                        setArquivos([...arquivos, ...resp.data]);
                    }
                    
                }).catch(e => {
                    Swal.fire({
                            icon: 'warning',
                            title: 'Oops...',
                            html: 'Erro ao enviar arquivo, entre em contato com o administrador do sistema!',
                    });  
                }).finally(e => {
                    setLoadingFiles(false);
                });

            }else{
                Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    html: 'Formato do arquivo inválido',
                });    
            }
        }
        
    });
    
    const downloadFile = async (props, index = -1) => {
        
        if(refDownloadingFile.current[index] !== null) {
            refDownloadingFile.current[index].style.display = "flex";
        }
        
        await api.get(`downloadFile/${props.uuid}/${props.hash}.${props.ext_arquivo}`, {responseType: 'blob'}).then(file => {
            
            if(mountedRef.current) {

                saveAs(file.data, `${props.full_nome_arquivo}`);

            }
            
        }).catch(erros => {
            
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                if(refDownloadingFile.current[index] !== null) {
                    refDownloadingFile.current[index].style.display = "none";
                }     
                setLoading(false);

            }

        });

        // try {
        //     if(refDownloadingFile.current[index] !== null) {
        //         refDownloadingFile.current[index].style.display = "flex";
        //     }
        //     const file = await api.get(`downloadFile/${props.hash}.${props.ext_arquivo}`, {responseType: 'blob'});
        //     saveAs(file.data, `${props.full_nome_arquivo}`);
        //     setLoading(false);
        // }
        // catch(e) {
        //     // Colocar mensagem personalizada para erro de imagem
        //     setError(true);
        // } finally {
        //     if(refDownloadingFile.current[index] !== null) {
        //         refDownloadingFile.current[index].style.display = "none";
        //     } 
        // }
    }

    const deleteFile = (props) => {
        
        Swal.fire({
            title: `Deseja excluir o arquivo ${props.full_nome_arquivo}?`,
            text: `Está operação não poderá ser desfeita!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {
                
                removerArquivo(props);

            }
        });
    }

    const removerArquivo = async (props) => {
        
        setLoading(true);
        
        await api.delete(`/deleteFile/${props.uuid}/${props.id}`, {headers: authHeader()})
        .then(response => {
        
            if(mountedRef.current) {

                if(response) {
                    const deleteFile = arquivos.filter((item) => item.id !== props.id);
                    setArquivos(deleteFile);
                    Swal.fire(
                            'Sucesso!',
                            'Arquivo removido com sucesso!',
                            'success'
                        );
                } else {
                    if(response.data.error !== undefined) {
                        Swal.fire(
                            'Atenção!',
                            `${response.data.error}`,
                            'warning'
                        );
                        return false; 
                    }                    
                }                
            
            }
        
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);

            }

        });        

    }

    const files = arquivos.sort((a,b) =>  b.id - a.id).map((file, index) => (
        
        <div className="box-file tool-tip" key={file.id}>
            {
                (file.ext_arquivo.match(/(jpg|jpeg|png|gif)$/i)) 
                ?
                    <div>
                        <div className="box-file-item">
                            <p className="tip">{file.full_nome_arquivo}</p>
                            <img src={file.img_base64} alt={file.nome_arquivo}/>
                            <div className="config-file" key={file.id}>
                                <span>{file.nome_arquivo}</span>
                                <small>{file.data_criacao}</small>
                                <small>{file.size_arquivo}</small>
                            </div>
                        </div>
                        <div className="box-file-options">
                            <IoMdDownload onClick={() => downloadFile(file, index)} color="#62c384" size={17} />
                            <AiFillDelete onClick={() => deleteFile(file, index)} color="#e64141" size={17} />
                        </div>
                        <div className={`loadingFile`} ref={(e) => refDownloadingFile.current[index] = e}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Baixando arquivo...
                        </div>
                    </div>
                :
                    <div>   
                        <div className="box-file-item">
                            <p className="tip">{file.full_nome_arquivo}</p>
                            {
                                (file.ext_arquivo === 'pdf') ?
                                <AiOutlineFilePdf size={90} color="red" />
                                : (file.ext_arquivo === 'xls' || file.ext_arquivo === 'xlsx') ?
                                <AiOutlineFileExcel size={90} color="green" />
                                : (file.ext_arquivo === 'doc' || file.ext_arquivo === 'docx') ?
                                <AiOutlineFileWord size={90} color="blue" />
                                : (file.ext_arquivo === 'txt') ?
                                <AiOutlineFileText size={90} />
                                :
                                <AiOutlineFile size={90} />
                            }
                            <div className="config-file">
                                <span>{file.nome_arquivo}</span>
                                <small>{file.data_criacao}</small>
                                <small>{file.size_arquivo}</small>
                            </div>
                        </div>
                        <div className="box-file-options">
                            <IoMdDownload onClick={() => downloadFile(file, index)} color="#62c384" size={17} />
                            <AiFillDelete onClick={() => deleteFile(file, index)} color="#e64141" size={17} />
                        </div>
                        <div className={`loadingFile`} ref={(e) => refDownloadingFile.current[index] = e}>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            Baixando arquivo...
                        </div>
                    </div>
            }
            
        </div>

    ));

    const darBaixa = async (props) => {

        setLoading(true);
        
        let data = JSON.stringify({
            pk_proposta_comissao : props.pk_proposta_comissao,
            status : props.status === '1' ? '0' : '1' 
        });

        await api.put(`/darBaixaComissao/`, data, {headers: authHeader()})
        .then(response => {
            
            const updateComissao = comissao.filter((item) => item.pk_proposta_comissao !== props.pk_proposta_comissao);
            setComissao([...updateComissao, {pk_proposta_comissao: response.data[0].pk_proposta_comissao, 
                                            fk_proposta: response.data[0].fk_proposta, 
                                            comissao: response.data[0].comissao, 
                                            valor: response.data[0].valor, 
                                            status: response.data[0].status} ]);
        
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            setLoading(false);

        });   

    }

    const deleteProposta = (props) => {
        
        Swal.fire({
            title: `Deseja excluir a Proposta de ${props.nome}?`,
            text: `Está operação não poderá ser desfeita!`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then((result) => {
            if (result.value) {
                
                removerProposta(props);

            }
        });
    }

    const removerProposta = async (props) => {
        
        setLoading(true);
        
        await api.put(`/deleteProposta/`, {uuid: props.uuidProposta}, {headers: authHeader()})
        .then(response => {
        
            if(mountedRef.current) {

                if(response) {
                    
                    Swal.fire( 'Sucesso!', 'Arquivo removido com sucesso!', 'success' );
                    history.push(`/propostas/`);
                } else {
                    
                    Swal.fire( 'Atenção!', `Erro ao remover proposta, tente novamente, caso o erro persista, entre em contato com o administrador! `, 'warning' );
                    return false;                                       
                }                
            
            }
        
        }).catch(error => {
            
            Swal.fire(
                'Atenção!',
                `Não foi possível completar a sua operação, tente novamente, caso o erro persista, entre em contato com o administrador!`,
                'warning'
            );
            setError(true);

        }).finally(e => {

            if(mountedRef.current) {

                setLoading(false);

            }

        });        

    }

    useEffect(() => {

        loadInfo(id);
        return () => { mountedRef.current = false } 

    }, [id]);

    return (
        (error) ? <PageError /> :
        (propostaNotFound) ? <PropostaNotFound /> :
        <main>
            <section id="proposta-pessoa-pfpj" className={`detalhe-proposta-pessoa-pfpj`}>
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                                <DropdownButton
                                    variant="link"
                                    title={<FiMoreHorizontal color="596d98" />}
                                    id="dropdown-menu-align-right"
                                    >
                                    <Dropdown.Header><small>Proposta</small></Dropdown.Header>
                                    <Dropdown.Item as={Link} to={(parseInt(proposta.pf_pj) === 1) ? `/proposta-pessoa-fisica` : `/proposta-pessoa-juridica`} eventKey="2">
                                        <FiPlusCircle />&nbsp;&nbsp;Novo
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={`/propostas`} eventKey="3">
                                        <FiList />&nbsp;&nbsp;Consultar
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={(parseInt(proposta.pf_pj) === 1) ? `/editar-proposta-pessoa-fisica/${id}` : `/editar-proposta-pessoa-juridica/${id}`} eventKey="4">
                                        <FiEdit />&nbsp;&nbsp;Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="4" onClick={() => deleteProposta(proposta)}><FiTrash  style={{color:'red'}} />&nbsp;&nbsp;Excluir</Dropdown.Item>
                                </DropdownButton>
                            </div>
                        </div>
                        <hr />
                        <Card.Body>
                            {(id > 0) ? 
                                <h2 style={{textAlign: 'center', marginTop: '11px'}}>
                                    {proposta.nome}
                                </h2>
                            : 
                            '' 
                            }
                            <Tabs onSelect={(key, e) => (e) ? e.currentTarget.blur() : null } transition={false} defaultActiveKey="proposta" id="dados-proposta">
                                
                                <Tab eventKey="proposta" title={<Fragment><FiEdit />Proposta</Fragment>}>
                                    <div className="tab-body profileDetail">
                                            <Row>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Nº da proposta</label><br></br>
                                                        {proposta.numero_proposta}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Vendedor</label><br></br>
                                                        {proposta.vendedor}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Quantidade de Vidas* </label><br></br>
                                                        {proposta.quantidade_vidas}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Operadora</label><br></br>
                                                        {proposta.operadora}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Administradora</label><br></br>
                                                        {proposta.administradora}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Classificação</label><br></br>
                                                        {proposta.classificacao}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Acomodação</label><br></br>
                                                        {proposta.acomodacao}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Data da Venda</label><br></br>
                                                        {proposta.data_venda}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Vigência do Plano</label><br></br>
                                                        {proposta.vigencia}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Data de Vencimento </label><br></br>
                                                        {proposta.data_vencimento}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Forma de Pagamento </label><br></br>
                                                        {proposta.forma_pagamento}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Nº do cartão </label><br></br>
                                                        {proposta.numero_cartao}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Cobertura </label><br></br>
                                                        {proposta.cobertura}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Valor da proposta </label><br></br>
                                                        {proposta.valor_proposta}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Desconto (%) </label><br></br>
                                                        {proposta.desconto_perc}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Desconto ($) </label><br></br>
                                                        {proposta.desconto_real}
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={3}>
                                                    <div className="profileDetail-box">
                                                        <label>Valor Final</label><br></br>
                                                        {proposta.valor_final}
                                                    </div>
                                                </Col>
                                            </Row>
                                    </div>
                                </Tab>
                                
                                <Tab eventKey="informacoes" title={<Fragment><FiUser />Informações</Fragment>}>
                                    <div className="tab-body profileDetail">
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>{(parseInt(proposta.pf_pj) === 1) ? `CPF` : `CNPJ`}</label><br></br>
                                                    {proposta.cpf_cnpj}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>{(parseInt(proposta.pf_pj) === 1) ? `Nome` : `Nome Fantasia`}</label><br></br>
                                                    {proposta.nome}
                                                </div>
                                            </Col>
                                            {
                                            (parseInt(proposta.pf_pj) === 1) ? 
                                            <>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Data de Nascimento</label><br></br>
                                                    {proposta.data_nascimento}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Gênero</label><br></br>
                                                    {proposta.genero}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Estado Civil</label><br></br>
                                                    {proposta.estado_civil}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>RG</label><br></br>
                                                    {proposta.rg}
                                                </div>
                                            </Col>
                                            </>
                                            : 
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Razão Social</label><br></br>
                                                    {proposta.razao_social}
                                                </div>
                                            </Col>
                                            }
                                            
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>CEP</label><br></br>
                                                    {proposta.cep}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Endereço</label><br></br>
                                                    {proposta.endereco}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Complemento</label><br></br>
                                                    {proposta.complemento}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Ponto de Referência</label><br></br>
                                                    {proposta.referencia}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Bairro</label><br></br>
                                                    {proposta.bairro}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Cidade</label><br></br>
                                                    {proposta.cidade}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Estado</label><br></br>
                                                    {proposta.uf}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Telefone</label><br></br>
                                                    {proposta.ddd1} {proposta.telefone1}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Celular</label><br></br>
                                                    {proposta.ddd2} {proposta.telefone2}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>E-mail</label><br></br>
                                                    {proposta.email}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Valor (R$)</label><br></br>
                                                    {proposta.valor_titular}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Observação</label><br></br>
                                                    {proposta.obs_responsavel}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                                
                                <Tab eventKey="dependentes" title={<Fragment><FiUsers />{(parseInt(proposta.pf_pj) === 1) ? `Dependentes` : `Funcionários`}</Fragment>}>
                                    <div className="tab-body profileDetail">
                                        {(dependentes.length > 0) ?
                                        <Fragment>
                                        <style> 
                                            {"\
                                                #proposta-pessoa-pfpj .dependentes:before {\
                                                    background-color: #596d98;\
                                                }\
                                            "}
                                        </style>
                                        </Fragment>
                                        : '' }
                                        <div className="dependentes">
                                        
                                            {(dependentes.length > 0) ? dependentes.map((e, index) => {
                                                return(
                                                    <div className={`dependente`} key={e.pk_proposta_dependente_funcionario}>
                                                        <h4>{(parseInt(proposta.pf_pj) === 1) ? `Dependente` : `Funcionário`}{(e.nome === '') ? '' : ` - ${e.nome}`}</h4>
                                                        <Row style={{padding: '10px 0'}}>
                                                             <Col xs={12} md={3}>
                                                                 <label>CPF</label><br></br>
                                                                 {e.cpf}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Nome</label><br></br>
                                                                 {e.nome}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Data de Nascimento</label><br></br>
                                                                 {e.data_nascimento}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Gênero</label><br></br>
                                                                 {e.genero}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Estado Civil</label><br></br>
                                                                 {e.estado_civil}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>RG</label><br></br>
                                                                 {e.rg}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Parentesco</label><br></br>
                                                                 {e.parentesco}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Valor (R$)</label><br></br>
                                                                 {e.valor_responsavel}
                                                             </Col>
                                                             <Col xs={12} md={3}>
                                                                 <label>Observação</label><br></br>
                                                                 {e.obs_responsavel}
                                                             </Col>
                                                         </Row>        
                                                    </div>
                                                )
                                            }) : 
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1'}}>
                                                <h2>Sem {(parseInt(proposta.pf_pj) === 1) ? `dependentes` : `funcionarios`}</h2>
                                                <br></br>
                                                <img src={empty} alt="Empty data" style={{width: '25%'}} />
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </Tab>
                                
                                <Tab eventKey="comissao" title={<Fragment><FiDollarSign />Comissão</Fragment>}>
                                    <div className="tab-body profileDetail">
                                        <Row>
                                            {comissao.sort((a, b) => a.pk_proposta_comissao - b.pk_proposta_comissao).map((e, index) => { 
                                                return(
                                                    <Col xs={12} md={3} key={e.pk_proposta_comissao}>
                                                        <div className="profileDetail-box">
                                                            <label>{e.comissao}{e.status === '0' ? <FiX style={{color:'red'}} onClick={() => darBaixa(e)} /> : <FiCheck style={{color:'green'}} onClick={() => darBaixa(e)} />}</label><br></br>
                                                            {e.valor}
                                                        </div>
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </div>
                                </Tab>
                                
                                <Tab eventKey="pendencias" title={<Fragment><FiLayers />Pendências</Fragment>}>
                                    <div className="tab-body profileDetail">
                                        <Row>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Pendências</label><br></br>
                                                    {proposta.pendencias}
                                                </div>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <div className="profileDetail-box">
                                                    <label>Observação</label><br></br>
                                                    {proposta.obs}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                            
                                <Tab eventKey="arquivos" title={<Fragment><FiFile />Arquivos</Fragment>}>
                                    <div className="tab-body profileDetail">
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div>
                                                    <Carregando Loading={loadingFiles} title="Carregando Arquivos" />
                                                    <div>
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            <p style={{marginBottom: '0'}}>Arraste os arquivos ou clique para seleciona-los</p>
                                                        </div>
                                                        <hr></hr>
                                                        <div className="files">
                                                            {files}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Tab>
                            
                            </Tabs> 
                        </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </section>
        </main>    
    );
}

export default Detalhe;