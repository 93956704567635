import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table, DropdownButton } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FiSearch, FiMoreHorizontal, FiDelete, FiUser } from 'react-icons/fi';
import { Formik } from 'formik';
import * as Yup from 'yup';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import pt from 'date-fns/locale/pt-BR';
import { api } from '../../_services';
import MaskedInput from 'react-text-mask';
import { authHeader } from '../../_helpers/auth-header';
import axios from 'axios';
import Arvore from '../../_components/Arvore';
import Carregando from '../../_components/Carregando';
import { Fragment } from 'react';
import './styles.css';
import NoResult from '../Errors/noResult';
import PageError from '../Errors/pageError';

const Metas = () => {

    const [loading, setLoading]       = useState(true); 
    const [error, setError]           = useState(false);
    const [enableValidation, setEnableValidation] = useState(false);
    const [vendedores, setVendedores] = useState([]);
    const [operadoras, setOperadoras] = useState([]);
    const [metas, setMetas] = useState([]);
    const [fk_vendedor, setFkVendedor] = useState(0);
    const [fk_operadora, setFkOperadora] = useState(0);
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim]       = useState();
    const [isFiltered, setIsFiltered] = useState(false);
    const mountedRef = useRef(true); 

    const lista = ['Home', 'Metas'];

    const FiltrarMetas = async (props) => {
        setLoading(true);
        setIsFiltered(true);

        let dados = {
            fk_operadora: props.fk_operadora,
            fk_vendedor: props.fk_vendedor,
            data_inicio: props.dataInicio,
            data_fim: props.dataFim
        };

        await api.get('consultarMetas/', {
            params: dados,
            headers: authHeader()
        }).then((response) => {
            var metasGroup = response.data.metas.reduce((memo, value) => {
                (memo[value.lbvendedor] = memo[value.lbvendedor] || []).push(value);
                return memo;
            }, []);
            setMetas(metasGroup);   
        }).catch(erro => {
            setError(true);
        }).finally(e => {
            setLoading(false);
        });

    }

    const limparInfo = () => {
        setMetas([]);
        setFkVendedor(0);
        setFkOperadora(0);
        setDataInicio('');
        setDataFim('');
        setIsFiltered(false);
    }

    const loadInfo = async () => {
        let vendedores = "getAllVendedores/";
        let operadoras = "getAllOperadoras/";

        const requestVendedores = await api.get(vendedores,{ params: { page: 1, t: '' }, headers: authHeader() });
        const requestOperadoras = await api.get(operadoras,{ params: { page: 1, t: '', s: '' }, headers: authHeader() });
        
        axios.all([requestVendedores, requestOperadoras]).then(axios.spread((...responses) => {
            const requestVendedores = responses[0];
            const requestOperadoras = responses[1];
            if(mountedRef.current) {
                setVendedores(requestVendedores.data.vendedores);
                setOperadoras(requestOperadoras.data.operadoras);
            }
        })).catch(errors => {
            console.log('Erro');
        }).finally(e => {
            setLoading(false);
        });
    }

    useEffect(() => {
        loadInfo();     
        return () => { mountedRef.current = false }     
    }, []);

    return (
        (error) ? <PageError /> :
        <main>
            <section id="consultar-metas">
            <Container fluid>
                <Carregando Loading={loading} />
                <Row className="justify-content-md-center">
                    <Col lg="11">
                        <Card>
                        <div style={{marginBottom: '10px', display:'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div>
                                <Arvore lista={lista} />
                            </div>
                            <div>
                            <DropdownButton
                                disabled={true}
                                variant="link"
                                title={<FiMoreHorizontal color="596d98" />}
                                id="dropdown-menu-align-right"
                                >
                            </DropdownButton>
                        </div>
                        </div>
                        <Card.Body>    
                        
                        <Formik
                            validateOnChange={enableValidation}
                            validateOnBlur={enableValidation}
                            enableReinitialize={true} 
                            initialValues={{
                                fk_vendedor: fk_vendedor,
                                fk_operadora: fk_operadora,
                                dataInicio: dataInicio,
                                dataFim: dataFim,
                            }}
                            validationSchema={ () => {
                            setEnableValidation(true);    
                            return Yup.object({
                                dataInicio: Yup.date()
                                                .required('Campo deve ser preenchido!')            
                                                .min(new Date('2000-01-01'), 'A data mínima permitida é 01/01/2000')
                                                .max(Yup.ref('dataFim'), "A Data Início não pode ser maior do que a Data Fim"),
                                dataFim: Yup.date()
                                            .required('Campo deve ser preenchido!')            
                                            .min(Yup.ref('dataInicio'), "Data Fim não pode ser menor do que a Data Início")
                                            .max(new Date('2200-01-01'), 'A data máxima permitida é 01/01/2200'),
                            })}}
                            onSubmit={FiltrarMetas}
                        >
                            {({
                                handleSubmit,
                                handleChange,
                                resetForm,
                                setFieldValue,
                                validateForm,
                                values,
                                touched,
                                isValid,
                                errors,
                            }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row>
                                    <Col xs={12} md={3}>
                                        <Form.Group controlId="select-vendedor">
                                            <Form.Label>
                                                Vendedor 
                                            </Form.Label>                        
                                            <Select 
                                                className={`select-default`}
                                                options={vendedores.map(e => ({ value: e.id, label: e.nome}))} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("fk_vendedor", e.value) }
                                                value={values.fk_vendedor > 0 ? vendedores.find(obj => obj.value === values.fk_vendedor) : ''} 
                                                name="fk_vendedor" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Group controlId="select-operadora">
                                            <Form.Label>Operadora</Form.Label>    
                                            <Select 
                                                className={`select-default`}
                                                options={operadoras.filter(e => e.tipo === "1").map(e => ({ value: e.id, label: e.descricao}))} 
                                                isClearable={false}
                                                isSearchable={true}
                                                onChange={ e => setFieldValue("fk_operadora", e.value) }
                                                value={values.fk_operadora > 0 ? operadoras.find(obj => obj.value === values.fk_operadora) : ''} 
                                                name="fk_operadora" 
                                                placeholder=""
                                                noOptionsMessage={() => 'Sem resultados!'}
                                                styles={{
                                                    singleValue: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    input: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    placeholder: (provided, state) => {
                                                        const paddingLeft = 7;
                                                        return { ...provided, paddingLeft };
                                                    },
                                                    menu: (provided, state) => {
                                                        const borderRadius = 0;
                                                        const fontSize = 12;
                                                        return { ...provided, borderRadius, fontSize };
                                                    },
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Label>
                                            Data da Inicio*
                                        </Form.Label>    
                                        <Form.Group controlId="data-inicio">
                                            
                                            <DatePicker
                                                customInput={
                                                    <MaskedInput
                                                    type="text"
                                                    className={`form-control ${!!errors.dataInicio ? 'is-invalid' : ''}`}
                                                    autoComplete="off" 
                                                    placeholder="00/00/0000"
                                                    guide={false}
                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                    />
                                                }
                                                autoComplete="off" 
                                                selected={values.dataInicio}
                                                name="dataInicio"
                                                onChange={date => setFieldValue("dataInicio", date)}
                                                locale={pt}
                                                dateFormat="P"
                                                />
                                            <Form.Control.Feedback type="invalid" style={{ 'display': !!errors.dataInicio ? 'block' : 'none' }} >{errors.dataInicio}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} md={3}>
                                        <Form.Label>
                                            Data Fim*
                                        </Form.Label>    
                                        <Form.Group controlId="data-fim">
                                            
                                            <DatePicker
                                                customInput={
                                                    <MaskedInput
                                                    type="text"
                                                    className={`form-control ${!!errors.dataFim ? 'is-invalid' : ''}`}
                                                    autoComplete="off" 
                                                    placeholder="00/00/0000"
                                                    guide={false}
                                                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                                                    />
                                                }
                                                autoComplete="off" 
                                                selected={values.dataFim}
                                                name="dataInicio"
                                                onChange={date => setFieldValue("dataFim", date)}
                                                locale={pt}
                                                dateFormat="P"
                                                />
                                            <Form.Control.Feedback type="invalid" style={{ 'display': !!errors.dataFim ? 'block' : 'none' }} >{errors.dataFim}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div>
                                    <Button variant="secondary" size="sm" type="submit">
                                        <FiSearch /> Filtrar
                                    </Button>&nbsp;
                                    <Button variant="primary" 
                                            size="sm" 
                                            onClick={() => {
                                                resetForm();
                                                limparInfo();
                                            }} 
                                            type="reset">
                                         <FiDelete /> Limpar
                                    </Button>
                                </div>
                            </Form>
                            )}
                        </Formik>        
                        </Card.Body>
                        </Card>
                        {(isFiltered) ? 
                            <Card style={{marginTop: '20px', padding: '25px'}}>
                                <Card.Body>
                                    {
                                    (Object.entries(metas).length === 0) ? <NoResult /> : 
                                    <>
                                        <Table responsive hover size="md">
                                            <thead>
                                                <tr className="headerTable">
                                                    <th>Planos</th>
                                                    <th style={{width:"200px"}}>Período</th>
                                                    <th style={{width:"15px"}}>Metas</th>
                                                    <th style={{width:"125px"}}>Vendas</th>
                                                    <th style={{width:"155px"}}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Object.entries(metas).map((e, index) => {
                                                        return (
                                                            <Fragment key={index}>
                                                            <tr className="headerTableMetas" key={index}>
                                                                <td colSpan={5}><FiUser />{e[0]}</td>
                                                            </tr>
                                                            {
                                                            e[1].sort((a,b) => b.id - a.id).map((f, i) => {
                                                                return (
                                                                    <tr key={i+''+f.fk_operadora}>
                                                                        <td>{f.lboperadora}</td>
                                                                        <td>{f.data_inicio} até {f.data_fim}</td>
                                                                        <td>{f.meta}</td>
                                                                        <td>{f.quantidade} ({`${(parseInt(f.quantidade)/parseInt(f.meta)*100).toFixed(2).toString().replace(".", ",")}%`})</td>
                                                                        <td><ProgressBar now={(parseInt(f.quantidade)/parseInt(f.meta)*100).toFixed(2)} /></td>
                                                                    </tr>
                                                                )
                                                            })
                                                            }
                                                            </Fragment>
                                                        )
                                                    })                                
                                                }
                                            </tbody>
                                        </Table>                    
                                    </>
                                    }   
                                </Card.Body>
                            </Card>
                        : ''}
                    </Col>
                </Row>
            </Container>
            </section>
        </main>
    )
}

export default Metas;